import * as React from 'react';
import {useCallback} from 'react';
import {TotalsumEditorAction} from './TotalsumEditor';
import {GPCContainmentVariant, GPCEditorAction} from './GPCEditor';
import {PeriodEditorAction, Weekdays} from './PeriodEditor';
import {SelectField, Spacer} from '@edekadigital/backoffice-ui';
import {renderEditor} from './renderEditor';
import {GtinEditorAction} from './GtinEditor';
import {makeStyles} from '@material-ui/core';
import {EdapiProduct, ProductCategory} from '../../../model/model';

export enum TriggerType {
  NONE = 'NONE',
  GPC = 'GPC',
  GTIN = 'GTIN',
  TOTALSUM = 'TOTALSUM',
  PERIOD = 'PERIOD',
}

export interface Period {
  day: Weekdays;
  startTime: string; // HH:MM
  endTime: string; // HH:MM
}

export interface Condition {
  triggerType: TriggerType;
  gpcContainment?: GPCContainmentVariant;
  productCategories?: ProductCategory[];
  products?: EdapiProduct[];
  maxAmount?: number;
  minAmount?: number;
  period?: Period;
}

export interface ConditionEditorProps {
  choosableTriggerTypes: {value: TriggerType; label: string}[];
  condition: Condition;
  index: number;
  onChange: (newCondition: Condition, index: number) => void;
}

const TRIGGER_TYPE_DEFAULTVALUES_MAP: {[key in TriggerType]: Condition} = {
  [TriggerType.NONE]: {
    triggerType: TriggerType.NONE,
  },
  [TriggerType.GPC]: {
    triggerType: TriggerType.GPC,
    gpcContainment: GPCContainmentVariant.contains,
    productCategories: [],
    minAmount: 0.01,
  },
  [TriggerType.GTIN]: {
    triggerType: TriggerType.GTIN,
    products: [],
    minAmount: 0.01,
  },
  [TriggerType.TOTALSUM]: {
    triggerType: TriggerType.TOTALSUM,
    minAmount: 0.01,
  },
  [TriggerType.PERIOD]: {
    triggerType: TriggerType.PERIOD,
    period: {
      day: Weekdays.MO,
      startTime: '00:00',
      endTime: '23:59',
    },
  },
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
  },
  typeSelect: {
    flexBasis: '220px',
    flexShrink: 0,
  },
}));

export const ConditionEditor = React.memo(function ConditionEditor({
  choosableTriggerTypes,
  condition,
  index,
  onChange,
}: ConditionEditorProps) {
  const dispatch = useCallback(
    (
      action:
        | TotalsumEditorAction
        | GPCEditorAction
        | PeriodEditorAction
        | GtinEditorAction
    ) => {
      switch (action.type) {
        case 'period': {
          onChange(
            {
              triggerType: TriggerType.PERIOD,
              period: {
                day: action.day,
                startTime: action.startTime,
                endTime: action.endTime,
              },
            },
            index
          );
          break;
        }
        case 'gpc': {
          onChange(
            {
              triggerType: TriggerType.GPC,
              gpcContainment: action.gpcContainment,
              productCategories: action.productCategories,
              minAmount: action.minAmount,
            },
            index
          );
          break;
        }
        case 'gtin': {
          onChange(
            {
              triggerType: TriggerType.GTIN,
              products: action.products,
              minAmount: action.minAmount,
            },
            index
          );
          break;
        }
        case 'sum': {
          onChange(
            {
              triggerType: TriggerType.TOTALSUM,
              maxAmount: action.maxAmount,
              minAmount: action.minAmount,
            },
            index
          );
        }
      }
    },
    [index, onChange]
  );

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {choosableTriggerTypes.length > 1 && (
        <>
          <SelectField
            value={condition.triggerType}
            menuItems={choosableTriggerTypes}
            inputTestId="selectField-input-condition-editor-type-select"
            onChange={evt =>
              onChange(
                TRIGGER_TYPE_DEFAULTVALUES_MAP[evt.target.value as TriggerType],
                index
              )
            }
            className={classes.typeSelect}
          />
          <Spacer horizontal={1} vertical={2} />
        </>
      )}
      {renderEditor(condition, dispatch)}
    </div>
  );
});
