import * as React from 'react';
import {SelectField, SelectFieldProps} from '@edekadigital/backoffice-ui';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import {MainCouponCreate, Region} from '../../model/model';
import {SelectRegionalDef} from '../../forms/models';
import {WrapFormRow} from '../form/formRenderUtils';

export interface FormSelectRegionalFieldProps
  extends Partial<SelectFieldProps> {
  definition: SelectRegionalDef;
  doNotWrap?: boolean;
}

export const FormSelectRegionalField = ({
  definition,
  doNotWrap = false,
  ...otherProps
}: FormSelectRegionalFieldProps) => {
  const methods = useFormContext<MainCouponCreate>();
  const region = methods.watch('region') as Region;
  const show = region === Region.EZ;
  const values = definition.values.filter(
    el => !region || region === Region.EZ || el.region === region
  );

  if (region && region !== Region.EZ) {
    methods.setValue(definition.key, values[0].value);
    return null;
  }

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        control={methods.control}
        name={definition.key as keyof MainCouponCreate}
        render={({field: {onChange, value, name, ref}, formState}) => (
          <SelectField
            inputTestId={`selectField-input-${definition.key}`}
            label={definition.label}
            helperText={
              (formState.errors[definition.key] as FieldError | undefined)
                ?.message
            }
            error={!!formState.errors[name]}
            menuItems={values}
            onChange={onChange}
            value={value as string}
            name={name}
            inputRef={ref}
            {...otherProps}
          />
        )}
      />
    </WrapFormRow>
  );
};
