import * as React from 'react';
import {SelectField, Spacer} from '@edekadigital/backoffice-ui';
import {parseInt} from 'lodash';
import {makeStyles} from '@material-ui/core';
import {CategorySelector} from '../formCategorySelector/CategorySelector';
import {ProductCategory} from '../../../model/model';
import {IntegerEuroField} from './IntegerEuroField';

export enum GPCContainmentVariant {
  contains,
  doesNotContain,
}

const CONTAINS_NOT_LABEL_MAP = [
  {value: GPCContainmentVariant.contains, label: 'enthält'},
  {value: GPCContainmentVariant.doesNotContain, label: 'enthält nicht'},
];

export type GPCEditorAction = {
  type: 'gpc';
  gpcContainment: GPCContainmentVariant;
  productCategories: ProductCategory[];
  minAmount: number;
};

const useStyles = makeStyles(theme => ({
  containmentSelect: {
    flexBasis: '140px',
    flexShrink: 0,
  },
  categorySelect: {
    marginTop: theme.spacing(1),
  },
  minAmountInput: {
    width: 160,
    marginTop: theme.spacing(1),
  },
  textWithMargin: {
    marginTop: theme.spacing(1),
  },
  minAmountHelp: {
    marginTop: theme.spacing(1),
  },
}));

interface GPCEditorProps {
  gpcContainment: GPCContainmentVariant;
  productCategories: ProductCategory[];
  minAmount: number;
  dispatch: (action: GPCEditorAction) => void;
}

export const GPCEditor = React.memo(function GPCEditor({
  gpcContainment,
  productCategories,
  minAmount,
  dispatch,
}: GPCEditorProps) {
  const classes = useStyles();

  return (
    <>
      <SelectField
        value={gpcContainment}
        inputTestId="selectField-input-gpc-editor-containment-select"
        menuItems={CONTAINS_NOT_LABEL_MAP}
        onChange={evt =>
          dispatch({
            type: 'gpc',
            productCategories,
            gpcContainment: parseInt(evt.target.value),
            minAmount,
          })
        }
        className={classes.containmentSelect}
      />
      <Spacer horizontal={1} vertical={2} />
      <CategorySelector
        testIdKey="gpc-editor-category"
        items={productCategories}
        onChange={productCategories =>
          dispatch({
            type: 'gpc',
            productCategories,
            gpcContainment: gpcContainment,
            minAmount,
          })
        }
        className={classes.categorySelect}
      />
      <div className={classes.textWithMargin}>mit Mindestumsatz</div>
      <Spacer horizontal={1} vertical={2} />
      <IntegerEuroField
        onChange={value => {
          dispatch({
            type: 'gpc',
            productCategories,
            gpcContainment: gpcContainment,
            minAmount: value,
          });
        }}
        value={minAmount}
        className={classes.minAmountInput}
        testId="gpc-editor-amount-input"
      />
      <Spacer horizontal={1} vertical={2} />
      <div className={classes.minAmountHelp}>(0,01 € ist Pflichtminimum)</div>
    </>
  );
});
