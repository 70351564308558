import React, {useMemo} from 'react';
import {useEdekaBackofficeApi} from '@edekadigital/backoffice-commons';
import {
  CouponTemplate,
  DiscountUnit,
  EdapiProduct,
  MainCouponCreate,
  ProviderSnapshot,
  Region,
} from '../model/model';
import {
  ActiveFilter,
  Order,
} from '@edekadigital/backoffice-ui/lib/types/components/EnhancedDataTable/EnhancedDataTable';
import {Span} from '@opentelemetry/api';
import {useEpim} from './useEpim';
import {
  CouponListResponse,
  CouponOverviewItem,
} from '../model/couponOverviewModel';
import {useStageConfig} from './useStageConfig';

type CatAppProps = {
  span?: Span;
};

export interface UseCatAppReturn {
  createMainCoupon: (
    data: MainCouponCreate
  ) => Promise<CreateMainCouponResponse>;

  listCoupons: (
    page: number | undefined,
    size: number | undefined,
    region: string,
    order?: Order,
    orderBy?: keyof CouponOverviewItem,
    filters?: Array<ActiveFilter<CouponOverviewItem>>
  ) => Promise<CouponListResponse>;

  getCouponById: (couponId: string) => Promise<MainCouponCreate>;

  getProviderSnapshot: (couponId: string) => Promise<ProviderSnapshots>;
}

export const useCatApp = (props: CatAppProps): UseCatAppReturn => {
  const request = useEdekaBackofficeApi();
  const cougarApiUrl = useStageConfig().config.cougarApiUrl;

  const additionalHeaders = useMemo(
    () => ({
      accept: 'application/json',
      ...(props.span
        ? {
            'X-B3-SpanId': props.span.context().spanId,
            'X-B3-TraceId': props.span.context().traceId,
          }
        : {}),
    }),
    [props.span]
  );

  const {getProductByGtin} = useEpim();

  const defaultEdapiProduct = (edapiProduct: EdapiProduct) => {
    return {
      ...edapiProduct,
      names: {
        name: 'name',
        description: `Artikel EAN ${edapiProduct.gtin}`,
      },
      volume: {
        volume: 1,
        volumePerUnit: 'unit',
      },
    } as unknown as EdapiProduct;
  };

  const getCouponById = React.useCallback(
    async (couponId: string): Promise<MainCouponCreate> => {
      const lookupSingleProduct = async (p: EdapiProduct) =>
        (await getProductByGtin(p.gtin.toString())) ?? defaultEdapiProduct(p);

      const lookupProducts = async (products: EdapiProduct[]) => {
        return Promise.all(
          products.filter(p => p?.gtin).map(lookupSingleProduct)
        );
      };

      return await request<MainCouponCreate & {storeVisualItems: string[]}>(
        `${cougarApiUrl}/main-coupon/${couponId}`,
        'GET',
        {
          authorizationHeader: 'Authorization',
        }
      ).then(async data => {
        if (data.couponTemplate === CouponTemplate.ARTIKEL) {
          data.validity = 'custom';

          data.includedProducts = await lookupProducts(
            data.includedProducts || []
          );
          data.hiddenIncludedProducts = await lookupProducts(
            data.hiddenIncludedProducts || []
          );
        }
        return data;
      });
    },
    [cougarApiUrl, getProductByGtin, request]
  );

  const filterValue: (filter: ActiveFilter<CouponOverviewItem>) => string = (
    filter: ActiveFilter<CouponOverviewItem>
  ) => {
    return filter.accessor === 'discountTypeFilter'
      ? Object.keys(DiscountUnit).filter(
          x =>
            DiscountUnit[x as keyof typeof DiscountUnit] ===
            filter.value.toString()
        )[0]
      : filter.value;
  };

  const listCoupons = React.useCallback(
    async (
      page = 0,
      size = 25,
      region: string,
      order?: Order,
      orderBy?: keyof CouponOverviewItem,
      filters?: Array<ActiveFilter<CouponOverviewItem>>
    ) => {
      const queryStringFilters =
        filters && filters.length > 0
          ? '&' +
            filters
              .map(
                filter =>
                  `${encodeURIComponent(
                    (filter.accessor as keyof CouponOverviewItem).toString()
                  )}=${encodeURIComponent(filterValue(filter))}`
              )
              .join('&')
          : '';

      const queryStringOrder = order ? `&order=${order}` : '';
      const queryStringOrderBy = orderBy ? `&orderBy=${orderBy}` : '';
      const endpoint = `${cougarApiUrl}/main-coupon?page=${page}&size=${size}${
        region && region !== Region.EZ ? `&region=${region}` : ''
      }${queryStringFilters}${queryStringOrder}${queryStringOrderBy}`;

      return await request<CouponListResponse>(endpoint, 'GET', {
        additionalHeaders: additionalHeaders,
        authorizationHeader: 'Authorization',
      });
    },
    [additionalHeaders, cougarApiUrl, request]
  );

  const createMainCoupon = React.useCallback(
    async (data: MainCouponCreate) => {
      const response = await request<CreateMainCouponResponse>(
        `${cougarApiUrl}/main-coupon`,
        'POST',
        {
          additionalHeaders: additionalHeaders,
          authorizationHeader: 'Authorization',
          body: data as unknown as Record<string, unknown>,
        }
      );

      if (props.span) {
        props.span.end();
      }

      return response;
    },
    [additionalHeaders, cougarApiUrl, props.span, request]
  );

  const getProviderSnapshot = React.useCallback(
    async (couponId: string) => {
      return await request(
        `${cougarApiUrl}/provider-snapshot?couponId=${couponId}`,
        'GET',
        {
          additionalHeaders: additionalHeaders,
          authorizationHeader: 'Authorization',
        }
      )
        .then(resp => {
          return {
            couponId: couponId,
            error: false,
            snapshots: resp,
          } as ProviderSnapshots;
        })
        .catch(err => {
          console.error(err);
          return {
            couponId: couponId,
            error: true,
            snapshots: [],
          } as ProviderSnapshots;
        });
    },
    [additionalHeaders, cougarApiUrl, request]
  );

  return {
    createMainCoupon,
    listCoupons,
    getCouponById,
    getProviderSnapshot,
  };
};

export interface CreateMainCouponResponse {
  couponId: string;
  status: string;
}

export interface ProviderSnapshots {
  couponId: string;
  error: boolean;
  snapshots: ProviderSnapshot[];
}
