import {Page, useSnackbar} from '@edekadigital/backoffice-ui';
import {
  CouponProvider,
  CouponTemplate,
  MainCouponCreate,
} from '../../model/model';
import * as React from 'react';
import {useLayoutEffect} from 'react';
import {RouteComponentProps, useLocation} from '@reach/router';
import {useAuth} from '@edekadigital/backoffice-commons';
import {FormRenderer} from '../form/FormRenderer';
import {StandardCouponForm} from '../../forms/StandardCoupon';
import {AcardoCouponForm} from '../../forms/AcardoCoupon';
import {FormFieldArea} from '../../forms/models';
import {initialValueBuilder} from '../../forms/initialValueBuilder';

type CreateCouponProps = RouteComponentProps<{
  couponId?: string;
  couponProvider: CouponProvider;
}> & {coupon?: MainCouponCreate};

export const ProviderToFormDefinitionMap: Map<
  CouponProvider,
  FormFieldArea[]
> = new Map([
  [CouponProvider.DC, StandardCouponForm],
  [CouponProvider.MCA, StandardCouponForm],
  [CouponProvider.ACARDO, AcardoCouponForm],
]);

export const CreateCoupon: React.FC<CreateCouponProps> = props => {
  const snackbar = useSnackbar();
  const location = useLocation();

  const formDefinition =
    ProviderToFormDefinitionMap.get(
      props.couponProvider ?? CouponProvider.MCA
    ) ?? StandardCouponForm;
  const initialCouponValues =
    props.coupon ?? initialValueBuilder(formDefinition);

  if (initialCouponValues.includedItemGroups === null) {
    initialCouponValues.includedItemGroups = [];
  }

  if (initialCouponValues.includedProducts === null) {
    initialCouponValues.includedProducts = [];
  }

  if (initialCouponValues.hiddenIncludedProducts === null) {
    initialCouponValues.hiddenIncludedProducts = [];
  }

  if (props.couponProvider) {
    initialCouponValues.providers.push(props.couponProvider);
  }

  const params = new URLSearchParams(location.search);
  const couponTemplate = params.get('couponTemplate');
  if (couponTemplate) {
    if (couponTemplate === CouponTemplate.ARTIKEL) {
      initialCouponValues.couponTemplate = CouponTemplate.ARTIKEL;
    } else if (couponTemplate === CouponTemplate.WARENGRUPPE) {
      initialCouponValues.couponTemplate = CouponTemplate.WARENGRUPPE;
    }

    if (props.couponProvider === CouponProvider.MCA) {
      initialCouponValues.maxCouponCount = 1;
      if (couponTemplate === CouponTemplate.ARTIKEL) {
        initialCouponValues.maxReceiptUsagesCount = 1;
        initialCouponValues.appActivationRequired = false;
      } else if (couponTemplate === CouponTemplate.WARENGRUPPE) {
        initialCouponValues.maxReceiptUsagesCount = 300;
        initialCouponValues.appActivationRequired = true;
      }
    }
  }
  const couponTitle = params.get('couponTitle');
  if (couponTitle) {
    initialCouponValues.shortTitle = couponTitle;
  }
  const region = params.get('region');
  if (region) {
    initialCouponValues.region = region;
  }

  const {edekaRegions} = useAuth();

  useLayoutEffect(() => {
    //TODO: fix useAuth...it seems that single region assignment cannot be resolved within edekaRegions
    if (edekaRegions.length > 1) {
      snackbar.push(
        {
          title: 'Regionale Zuordnung',
          message: `Sie sind keiner oder mehreren Regionen zugeordnet: ${edekaRegions}.
        Ihre regionale Zuordnung muss jedoch eindeutig sein. Bitte wenden Sie sich bei Unklarheiten über diesen Zustand
        an Ihre Führungskraft`,
        },
        {variant: 'warning', autoHideDuration: null}
      );
    }
  }, [edekaRegions, snackbar]);

  return (
    <Page variant={'default'} paddingBottom={true}>
      <FormRenderer
        defaultValues={initialCouponValues}
        definition={formDefinition}
      />
    </Page>
  );
};
