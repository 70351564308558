import * as React from 'react';
import {Checkbox, CheckboxProps} from '@edekadigital/backoffice-ui';
import {WrapFormRow} from '../form/formRenderUtils';
import {useFormContext, Controller} from 'react-hook-form';
import {MainCouponCreate} from '../../model/model';
import {CheckBoxDef} from '../../forms/models';

export interface FormCheckboxProps extends Partial<CheckboxProps> {
  definition: CheckBoxDef;
  doNotWrap?: boolean;
}

export const FormCheckbox = ({
  definition,
  doNotWrap,
  ...otherProps
}: FormCheckboxProps) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const show = definition.useShow(watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <label>{definition.label}</label>
      <Controller
        control={control}
        name={definition.key as keyof MainCouponCreate}
        render={({field: {onChange, value, name, ref}}) => (
          <Checkbox
            onChange={onChange}
            checked={value as boolean}
            name={name}
            inputRef={ref}
            {...otherProps}
          />
        )}
      />
    </WrapFormRow>
  );
};
