import {StoreVisualItem} from '../model/model';
import {ActiveFilter} from '@edekadigital/backoffice-ui/lib/types/components/EnhancedDataTable/EnhancedDataTable';

interface OrderFilters {
  [key: string]: string[];
}

const orderFilters = (
  filters: ActiveFilter<StoreVisualItem>[] | undefined
): OrderFilters => {
  if (!filters) {
    return {};
  }

  const orderedFilters: OrderFilters = {};
  for (const filter of filters) {
    const values = orderedFilters[filter.accessor] ?? [];
    values.push(filter.value);
    orderedFilters[filter.accessor] = values;
  }

  return orderedFilters;
};

const filterByValue = (
  value: string | string[] | boolean,
  filterValues: string[]
): boolean => {
  if (typeof value === 'string') {
    value = value.toLocaleLowerCase();
    for (const filterValue of filterValues.map(s => s.toLocaleLowerCase())) {
      if (value.includes(filterValue)) {
        return true;
      }
    }
  } else if (Array.isArray(value)) {
    value = value.map(s => s.toLocaleLowerCase());
    for (const filterValue of filterValues.map(s => s.toLocaleLowerCase())) {
      if (value.includes(filterValue)) {
        return true;
      }
    }
  }

  return false;
};

export const filterStores = (
  stores: StoreVisualItem[],
  filters: ActiveFilter<StoreVisualItem>[] | undefined,
  selectedStoreIds: string[]
): StoreVisualItem[] => {
  const sortedFilters = orderFilters(filters);
  return stores.filter(store => {
    for (const [filterAccessor, filterValues] of Object.entries(
      sortedFilters
    )) {
      if (filterAccessor === 'selectedStores') {
        const filterValue = filterValues[0];
        if (
          (filterValue === 'Selektierte' &&
            !selectedStoreIds.includes(store.id)) ||
          (filterValue === 'Nicht Selektierte' &&
            selectedStoreIds.includes(store.id))
        ) {
          return false;
        }
      } else if (
        !filterByValue(
          store[filterAccessor as keyof StoreVisualItem],
          filterValues
        )
      ) {
        return false;
      }
    }

    return true;
  });
};
