import moment from 'moment';

type ValidityResult = {
  showFrom: moment.Moment;
  showTill: moment.Moment;
  validFrom: moment.Moment;
  validTill: moment.Moment;
};

export const calculateValidity = (startWeek: number): ValidityResult => {
  const validFrom = moment().day('Monday').isoWeek(startWeek);
  const validTill = moment(validFrom).add(6, 'days');
  const showFrom = moment(validFrom).subtract(1, 'day');
  const showTill = validTill;
  return {showFrom, showTill, validFrom, validTill};
};
