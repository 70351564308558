import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {Image} from '@edekadigital/backoffice-ui';
import {EdapiImage, EdapiProduct, MainCouponCreate} from '../../model/model';
import {FormTextField} from './FormTextField';
import {FormFieldTypes, FormImageDef} from '../../forms/models';
import {WrapFormRow} from '../form/formRenderUtils';

export interface FormImageProps {
  definition: FormImageDef;
  doNotWrap?: boolean;
}

export function FormImage({
  definition: {key, label, useShow, initialValue},
  doNotWrap = false,
}: FormImageProps): JSX.Element {
  const {watch, setValue} = useFormContext<MainCouponCreate>();
  const show = useShow(watch);
  const value = watch(key, '');

  const firstProductImage =
    (watch('includedProducts') || [])
      .flatMap((p: EdapiProduct) => p.images || ([] as EdapiImage[]))
      .map((i: EdapiImage) => i.url)
      .filter((s: string) => !!s)[0] || '';

  useEffect(() => {
    setValue(key, firstProductImage);
  }, [firstProductImage, key, setValue]);

  return (
    <div hidden={!show}>
      {!!firstProductImage && (
        <WrapFormRow doNotWrap={doNotWrap}>
          <Image mode="height" src={value as string} alt="Produktbild" />
        </WrapFormRow>
      )}
      <WrapFormRow doNotWrap={doNotWrap}>
        <FormTextField
          definition={{
            type: FormFieldTypes.TextField,
            key: key,
            label: label,
            inputType: 'string',
            useShow: () => true,
            initialValue: initialValue,
          }}
          doNotWrap={doNotWrap}
        />
      </WrapFormRow>
    </div>
  );
}
