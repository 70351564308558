import * as React from 'react';
import {EdapiProduct, MainCouponCreate} from '../../../model/model';
import {Controller, useFormContext} from 'react-hook-form';
import {ProductSelectorDef} from '../../../forms/models';
import {WrapFormRow} from '../../form/formRenderUtils';
import {ProductSelector} from './ProductSelector';

export interface FormProductSelectorProps {
  definition: ProductSelectorDef;
  doNotWrap?: boolean;
}

export const FormProductSelector: React.FC<FormProductSelectorProps> = ({
  definition: {key, label, useShow},
  doNotWrap = false,
}) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const show = useShow(watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        render={({field: {value, onChange}}) => (
          <ProductSelector
            testIdKey={key}
            label={label}
            items={value as EdapiProduct[]}
            onChange={onChange}
          />
        )}
        name={key}
        control={control}
      />
    </WrapFormRow>
  );
};
