import * as React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  Dialog,
  FormWrapper,
  FormRow,
  RadioButtonTiles,
  SelectField,
  TextField,
} from '@edekadigital/backoffice-ui';
// eslint-disable-next-line node/no-extraneous-import
import BookmarkIcon from '@material-ui/icons/Bookmark';
// eslint-disable-next-line node/no-extraneous-import
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import {CouponProvider, CouponTemplate, Region} from '../../model/model';
import {joiResolver} from '@hookform/resolvers/joi';
import Joi from 'joi';
import {useEffect} from 'react';

export interface NewCouponDialogForm {
  couponTitle: string;
  region: Region;
  couponProvider: CouponProvider;
  couponTemplate: CouponTemplate;
}

export const NewCouponDialogFormSchema = Joi.object({
  couponTitle: Joi.string().trim().required().messages({
    'string.empty': 'Überschrift kann kein leeres Feld sein.',
  }),
  region: Joi.string().valid(...Object.values(Region)),
  couponProvider: Joi.string().valid(...Object.values(CouponProvider)),
  couponTemplate: Joi.string().valid(...Object.values(CouponTemplate)),
});

export interface NewCouponDialogProps {
  onClose: () => void;
  onCreate: (data: NewCouponDialogForm) => void;
  open: boolean;
}

export const NewCouponDialog: React.FC<NewCouponDialogProps> = props => {
  const menuItemsRegion = [
    {value: Region.EZ, label: 'EZ'},
    {value: Region.MH, label: 'MH'},
    {value: Region.NB, label: 'NB'},
    {value: Region.NO, label: 'NO'},
    {value: Region.RR, label: 'RR'},
    {value: Region.SB, label: 'SB'},
    {value: Region.SW, label: 'SW'},
  ];
  const menuItemsCouponProvider = [
    {value: CouponProvider.MCA, label: 'MCA'},
    {value: CouponProvider.ACARDO, label: 'Acardo'},
    {value: CouponProvider.DC, label: 'DC'},
  ];
  const radioButtonItemsCouponTemplateDefault = [
    {label: 'Artikel', value: CouponTemplate.ARTIKEL, icon: BookmarkIcon},
    {
      label: 'Warengruppe',
      value: CouponTemplate.WARENGRUPPE,
      icon: BookmarksIcon,
    },
  ];
  const radioButtonItemsCouponTemplateAcardo = [
    {
      label: 'CoC',
      value: CouponTemplate.WARENGRUPPE,
      icon: BookmarksIcon,
    },
  ];
  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<NewCouponDialogForm>({
    resolver: joiResolver(NewCouponDialogFormSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      couponTitle: '',
      region: menuItemsRegion[0].value,
      couponProvider: menuItemsCouponProvider[0].value,
      couponTemplate: radioButtonItemsCouponTemplateDefault[0].value,
    },
  });

  const isAcardoSelected = watch('couponProvider') === CouponProvider.ACARDO;
  const radioButtonItemsCouponTemplate = isAcardoSelected
    ? radioButtonItemsCouponTemplateAcardo
    : radioButtonItemsCouponTemplateDefault;

  useEffect(() => {
    if (isAcardoSelected) {
      setValue('couponTemplate', CouponTemplate.WARENGRUPPE);
    }
  }, [isAcardoSelected, setValue]);

  const onClose = () => {
    reset();
    props.onClose();
  };
  const onSubmit = (data: NewCouponDialogForm) => {
    props.onCreate(data);
    reset();
  };

  return (
    <Dialog open={props.open} onClose={onClose} title={'Coupon anlegen'}>
      <FormWrapper
        alignButtons="right"
        submitLabel="Anlegen"
        cancelLabel="Abbrechen"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onClose}
      >
        <FormRow gutterBottom>
          <Controller
            name="couponTitle"
            rules={{required: true}}
            control={control}
            render={({field: {onChange, value}}) => (
              <TextField
                label="Überschrift"
                placeholder="Überschrift"
                inputTestId="textField-input-couponTitle"
                autoFocus={true}
                error={!!errors.couponTitle}
                helperText={errors.couponTitle?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormRow>
        <FormRow gutterBottom>
          <Controller
            name="region"
            control={control}
            render={({field: {onChange, value}}) => (
              <SelectField
                label="Region"
                menuItems={menuItemsRegion}
                inputTestId="selectField-input-region"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormRow>
        <FormRow gutterBottom>
          <Controller
            name="couponProvider"
            control={control}
            render={({field: {onChange, value}}) => (
              <SelectField
                label="Couponprovider"
                menuItems={menuItemsCouponProvider}
                inputTestId="selectField-input-couponProvider"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormRow>
        <FormRow gutterBottom>
          <Controller
            name="couponTemplate"
            control={control}
            render={({field: {onChange, value}}) => (
              <RadioButtonTiles
                tilesPerLine={2}
                items={radioButtonItemsCouponTemplate}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormRow>
      </FormWrapper>
    </Dialog>
  );
};
