import * as React from 'react';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import {DatePicker, DatePickerProps} from '@edekadigital/backoffice-ui';
import moment from 'moment';
import {MainCouponCreate} from '../../model/model';
import {dateFormat} from '../../utils/utils';
import {DatePickerDef} from '../../forms/models';
import {WrapFormRow} from '../form/formRenderUtils';

export interface FormDatePickerProps extends Partial<DatePickerProps> {
  definition: DatePickerDef;
  doNotWrap?: boolean;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  definition,
  doNotWrap = false,
}) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const show = definition.useShow(watch);
  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        render={({field: {onChange, value}, formState}) => {
          return (
            <DatePicker
              error={!!formState.errors[definition.key]}
              label={definition.label}
              value={moment(value as string, dateFormat).toDate()}
              id={`datepicker-input-${definition.key}`}
              onChange={value => {
                const date = moment(value).format(dateFormat);
                onChange(date);
              }}
              disablePast={definition.disablePast}
              minDate={definition.minDate}
              helperText={
                (formState.errors[definition.key] as FieldError | undefined)
                  ?.message
              }
            />
          );
        }}
        defaultValue={definition.defaultDate?.format(dateFormat)}
        name={definition.key}
        control={control}
      />
    </WrapFormRow>
  );
};
