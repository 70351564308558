import {
  Condition,
  TriggerType,
} from '../react-hook-form/formConditionsEditor/ConditionEditor';
import {Weekdays} from '../react-hook-form/formConditionsEditor/PeriodEditor';
import {GPCContainmentVariant} from '../react-hook-form/formConditionsEditor/GPCEditor';
import {parseInt} from 'lodash';
import {EdapiProduct, ProductCategory} from '../../model/model';

export type AcardoConditionPeriodType = {
  start: string;
  end: string;
};

export type AcardoConditionType = {
  description: string;
  trigger_type: TriggerType;
  min_amount?: string;
  max_amount?: string;
  gpcs?: string[];
  gtins?: string[];
  set_number?: number;
  periods?: {[key: string]: AcardoConditionPeriodType};
};

export type AcardoTriggerType = {
  conditions: {[index: number]: AcardoConditionType};
  logic: string;
};

const parseJsonIgnoringErrors = (input: string) => {
  if (!input) {
    return null;
  }
  try {
    return JSON.parse(input);
  } catch (e) {
    console.error(
      'Error parsing conditions from backend from input:',
      input,
      e
    );
    return null;
  }
};

export async function translateConditionsFromBackend(
  input: string,
  getProductByGtin: (gtin: string) => Promise<EdapiProduct>,
  getCategories: (
    searchText?: string,
    limit?: number
  ) => Promise<ProductCategory[]>
): Promise<Condition[]> {
  const obj = parseJsonIgnoringErrors(input);
  if (!obj) {
    return [];
  }
  const trigger = obj.trigger as AcardoTriggerType;
  const conditions: Condition[] = [];

  for (const key in trigger.conditions) {
    const c = trigger.conditions[key];
    const categories = c.gpcs
      ? await Promise.all(
          c.gpcs.map(async gpc => {
            const categories = await getCategories(gpc);
            if (categories.length === 0) {
              return {identifier: gpc, name: gpc};
            }
            return categories[0];
          })
        )
      : undefined;
    const products = c.gtins
      ? await Promise.all(
          c.gtins.map(async gtin => {
            return getProductByGtin(gtin);
          })
        )
      : undefined;

    const condition: Condition = {
      triggerType: c.trigger_type,
      minAmount: c.min_amount ? parseInt(c.min_amount) : undefined,
      maxAmount: c.max_amount ? parseInt(c.max_amount) : undefined,
      productCategories: categories,
      products: products,
    };

    if (c.trigger_type === TriggerType.TOTALSUM) {
      if (condition.minAmount) condition.minAmount /= 100;
      if (condition.maxAmount) condition.maxAmount /= 100;
    } else if (c.trigger_type === TriggerType.PERIOD) {
      for (const day in c.periods) {
        const newCondition = {
          ...condition,
          period: {
            day: day as Weekdays,
            startTime: c.periods[day].start,
            endTime: c.periods[day].end,
          },
        };
        conditions.push(newCondition);
      }
      continue; // continue here to prevent the push below
    } else if (c.trigger_type === TriggerType.GPC) {
      condition.gpcContainment =
        trigger.logic.indexOf(`-${key}`) !== -1
          ? GPCContainmentVariant.doesNotContain
          : GPCContainmentVariant.contains;
    }

    conditions.push(condition);
  }

  return conditions;
}
