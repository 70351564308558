import {MainCouponCreate} from '../../model/model';
import {CreateMainCouponResponse} from '../../hooks/useCatApp';
import * as _ from 'lodash';
import moment from 'moment';
import {dateFormat} from '../../utils/utils';
import {datadogRum} from '@datadog/browser-rum';
import {translateConditionsForBackend} from './translateConditionsForBackend';
import {Condition} from '../react-hook-form/formConditionsEditor/ConditionEditor';

export const submitCoupon = async (
  data: MainCouponCreate,
  createMainCoupon: (
    data: MainCouponCreate
  ) => Promise<CreateMainCouponResponse>,
  traceId: string
): Promise<{success: boolean; messages: string[]}> => {
  const formData = _.cloneDeep(data);

  if (formData.outputConditions !== undefined) {
    formData.outputConditions = translateConditionsForBackend(
      formData.outputConditions
    ) as unknown as Condition[];
  }

  if (formData.redeemConditions !== undefined) {
    formData.redeemConditions = translateConditionsForBackend(
      formData.redeemConditions
    ) as unknown as Condition[];
  }

  //TODO: Change when backend is ready to support itemgroup objects
  if (formData.includedItemGroups && formData.includedItemGroups.length > 0) {
    delete formData.includedProducts;
    delete formData.hiddenIncludedProducts;
  }

  //TODO: should all be handled by joi and schema def
  if (formData.validFrom && formData.validFrom !== '') {
    formData.validFrom = moment(formData.validFrom).format(dateFormat);
    formData.validTill = moment(formData.validTill).format(dateFormat);
    formData.showFrom = moment(formData.showFrom).format(dateFormat);
    formData.showTill = moment(formData.showTill).format(dateFormat);
  }

  datadogRum.addAction('submit coupon form data', {
    formData: formData,
  });

  let result: CreateMainCouponResponse;
  try {
    result = await createMainCoupon(formData);
    return {success: true, messages: [`CouponId: ${result.couponId}`]};
  } catch (error) {
    console.log(error);
    if (error.errors) {
      datadogRum.addError(new Error(JSON.stringify(error.errors)), {
        traceId: traceId,
        couponId: formData.couponId,
      });
      const messages: string[] = [];
      Object.keys(error.errors).map((key: string) => {
        messages.push(`${key}: ${error.errors[key]} Support id: ${traceId}`);
      });
      return {success: false, messages};
    } else {
      const errorMessage = `${error.message} Support id: ${traceId}`;
      datadogRum.addError(new Error(error.message), {
        traceId: traceId,
        couponId: formData.couponId,
      });
      return {success: false, messages: [errorMessage]};
    }
  }
};
