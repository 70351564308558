import {fromEdapiVolume} from './volume';
import {CouponProvider, EdapiProduct, ProductCategory} from '../model/model';
import {
  commonStartingWords,
  prettyCurrency,
  prettyFloats,
  trimWords,
} from './utils';
import {getTextForProductCategories} from './productCategoryTexts';
import {productDescription} from './products';
import {
  Condition,
  TriggerType,
} from '../components/react-hook-form/formConditionsEditor/ConditionEditor';
import {acardoImageUrls} from '../model/CouponDefaultValues';

export type CouponText = {
  // Bezeichnung in UI
  shortTitle: string;
  // Kurzbeschreibung in UI, Kassenbontext in MCA
  briefInfo: string;
  // Beschreibung in UI, longInfo in MCA
  description: string;
  // Aktionsüberschrift in UI
  specialOfferHeadline: string;
  // Bild
  image: string | null;
};

export const isValidProduct = (p?: EdapiProduct) =>
  p &&
  productDescription(p) &&
  p.additionalGtin?.length > 0 &&
  p.volume?.volume &&
  p.volume?.volumePerUnit;

export const getTexts = (
  couponProvider: CouponProvider,
  productsOrProductCategories: Array<EdapiProduct> | Array<ProductCategory>,
  discountType: string,
  value: number,
  maxReceiptUsagesCount: number,
  maxUserInstancesCount: number,
  appActivationRequired: boolean,
  redeemConditions?: Condition[]
) => {
  if (couponProvider === CouponProvider.ACARDO) {
    if (redeemConditions === undefined || redeemConditions.length <= 0) {
      throw new Error(
        'For Acardo coupons there needs to be at least one redeemCondition.'
      );
    }

    // eslint-disable-next-line prefer-const
    let {productCategories, minTransaction} = redeemConditions
      .filter(condition => condition.triggerType === TriggerType.GPC)
      .reduce(
        (result, condition) => {
          result.productCategories = [
            ...result.productCategories,
            ...(condition.productCategories ?? []),
          ];
          result.minTransaction = Math.max(
            result.minTransaction,
            condition.minAmount ?? 0
          );
          return result;
        },
        {productCategories: [], minTransaction: 0} as {
          productCategories: ProductCategory[];
          minTransaction: number;
        }
      );

    /*
    A redeemCondition on productCategories brings its own mininmal transaction
    with it. So ONLY if we did not have any such condition on product categories,
    then we want to look for a general minimal transaction condition in
    the context of text generation.
     */
    if (productCategories.length <= 0) {
      minTransaction = redeemConditions
        .filter(condition => condition.triggerType === TriggerType.TOTALSUM)
        .reduce((highestMinAmount, condition) => {
          return Math.max(highestMinAmount, condition.minAmount ?? 0);
        }, 0);
    }

    let text: CouponText | undefined;
    if (productCategories.length > 0) {
      text = getCouponTextForProductCategories(
        couponProvider,
        productCategories,
        discountType,
        value,
        maxReceiptUsagesCount,
        maxUserInstancesCount,
        appActivationRequired,
        /*
        Currently the Acardo API is not allowing a minAmount value
        of 0. The minimum value has to be 0.01. But for text generation
        we assume 0.01 to actually be equivalent to 0.
         */
        minTransaction > 0.01 ? minTransaction : 0
      );
    } else if (minTransaction > 0.01) {
      text = {
        image: getDiscountImageForAcardo(discountType, value),
        briefInfo: '',
        specialOfferHeadline: '',
        shortTitle: trimWords(
          `${getDiscountTextWithBlank(
            discountType,
            value
          )}Rabatt auf den gesamten Einkauf ab ${prettyCurrency(
            minTransaction
          )}`,
          50
        ),
        description: `Beim nächsten Einkauf an der Kasse im Markt vorlegen und ${getDiscountTextWithBlank(
          discountType,
          value
        )}sparen ab einem Einkaufswert von ${prettyCurrency(minTransaction)}.`,
      };
    } else {
      text = {
        image: getDiscountImageForAcardo(discountType, value),
        briefInfo: '',
        specialOfferHeadline: '',
        shortTitle: trimWords(
          `${getDiscountTextWithBlank(
            discountType,
            value
          )}Rabatt auf den gesamten Einkauf`,
          50
        ),
        description: `Beim nächsten Einkauf an der Kasse im Markt vorlegen und ${getDiscountTextWithBlank(
          discountType,
          value
        )}sparen.`,
      };
    }

    return text;
  }

  if (
    !Array.isArray(productsOrProductCategories) ||
    productsOrProductCategories.length === 0
  ) {
    throw new Error('products must not be empty');
  }

  if ('additionalGtin' in productsOrProductCategories[0]) {
    const products = productsOrProductCategories as Array<EdapiProduct>;
    return {
      description: getDescription(
        products,
        discountType,
        value,
        maxReceiptUsagesCount,
        maxUserInstancesCount
      ),
      shortTitle: getShortTitle(products),
      specialOfferHeadline: getSpecialOfferHeadline(products),
      briefInfo: getBriefInfo(products),
    } as CouponText;
  } else if ('identifier' in productsOrProductCategories[0]) {
    const productCategories =
      productsOrProductCategories as Array<ProductCategory>;

    return getCouponTextForProductCategories(
      couponProvider,
      productCategories,
      discountType,
      value,
      maxReceiptUsagesCount,
      maxUserInstancesCount,
      appActivationRequired
    );
  }

  throw new Error('parameter is of unknown type');
};

// ------ internal stuff comes next.

const cleanDescription = (text: string) =>
  text.replace(/ +[0-9,]+ *[a-zA-Z]+$/, '');

const cleanProductDescription = (p: EdapiProduct) =>
  cleanDescription(productDescription(p));

const cleanProductDescriptions = (products: EdapiProduct[]) =>
  products.map(cleanProductDescription);

const prettyProductUnits = (product: EdapiProduct) =>
  fromEdapiVolume(product.volume).print();
const prettyUnitRange = (products: Array<EdapiProduct>) => {
  const quantities = Array.from(
    new Set(products.map(p => fromEdapiVolume(p.volume)))
  ).sort((q1, q2) => q1.toBaseUnit().volume - q2.toBaseUnit().volume);

  // all products are measured with the same base unit
  if (new Set(quantities.map(q => q.toBaseUnit().volumePerUnit)).size === 1) {
    const first = quantities[0];
    const last = quantities[quantities.length - 1];

    if (first.volumePerUnit === last.volumePerUnit) {
      if (first.volume === last.volume) {
        return first.print();
      }
      return first.printWoUnit() + ' - ' + last.print();
    } else {
      return first.print() + ' - ' + last.print();
    }
  }

  return '';
};

// visible for testing
export const getShortTitle = (products: Array<EdapiProduct>) =>
  trimWords(
    commonStartingWords(cleanProductDescriptions(products)),
    50
  ).trimEnd() || 'Verschiedene Artikel';

// visible for testing
export const getSpecialOfferHeadline = (products: Array<EdapiProduct>) => {
  const range = prettyUnitRange(products);
  return range ? range + ' Packung' : '';
};

// visible for testing
export const getBriefInfo = (products: Array<EdapiProduct>) =>
  trimWords(
    'Coupon ' + commonStartingWords(cleanProductDescriptions(products)),
    50
  ).trimEnd();

const getDescription = (
  products: Array<EdapiProduct>,
  discountType: string,
  value: number,
  maxReceiptUsagesCount: number,
  maxUserInstancesCount: number
) =>
  getLongTextPre(discountType, value) +
  ': ' +
  getLongText(products) +
  '\n\n' +
  eanList(products) +
  '\n\n' +
  getLegalFooter(maxReceiptUsagesCount, maxUserInstancesCount) +
  '\n\nDamit Sie in den Genuss des Rabatts kommen, bitte die Nummer beim Strichcode auf dem Artikel vergleichen.';

// visible for testing
export const getLongTextPre = (discountType: string, value: number) => {
  if (discountType === 'CURRENCY') {
    return (
      'Sie erhalten mit diesem Coupon ' +
      prettyCurrency(value) +
      ' Rabatt auf folgende Artikel'
    );
  } else if (discountType === 'PERCENT') {
    return (
      'Sie erhalten mit diesem Coupon ' +
      prettyFloats(value) +
      '% Rabatt auf folgende Artikel'
    );
  } else if (discountType === 'NEW_PRICE') {
    return (
      'Sie erhalten mit diesem Coupon die folgenden Artikel zum Preis von ' +
      prettyCurrency(value)
    );
  }

  throw new Error('Unknown discountType: ' + discountType);
};

// visible for testing
export const getLongText = (products: Array<EdapiProduct>) => {
  const descriptions = cleanProductDescriptions(products);
  const fullDescriptions = products.map(
    p => cleanProductDescription(p) + ' ' + prettyProductUnits(p)
  );

  if (descriptions.length === 1) {
    return fullDescriptions[0];
  }

  const common = commonStartingWords(descriptions);
  if (common === '') {
    return (
      fullDescriptions.slice(0, fullDescriptions.length - 1).join(', ') +
      ' oder ' +
      fullDescriptions[fullDescriptions.length - 1]
    ).trim();
  } else if (new Set(descriptions).size === 1) {
    return (
      common +
      ' ' +
      prettyUnitRange(products) +
      `

Erhältliche Sorten: ` +
      fullDescriptions.join(', ')
    ).trim();
  } else {
    return (
      common +
      ' verschiedene Sorten ' +
      prettyUnitRange(products) +
      `

Erhältliche Sorten: ` +
      fullDescriptions.join(', ')
    ).trim();
  }
};

// visible for testing
export const eanList = (products: Array<{additionalGtin: string[]}>) =>
  'Folgende EANs sind gültig:\n' +
  products.flatMap(p => p.additionalGtin).join('\n');

// visible for testing
export const getLegalFooter = (
  maxReceiptUsagesCount: number | string,
  maxUserInstancesCount: number | string
) => {
  const mruc = Number(maxReceiptUsagesCount);
  const muic = Number(maxUserInstancesCount);
  if (mruc === 1 && muic === 1) {
    return 'Der Coupon ist einmalig einlösbar und nicht mit anderen Rabattaktionen kombinierbar. Rabatt gilt nur für einen Artikel.';
  } else if (mruc === 1) {
    return 'Der Coupon ist mehrmalig einlösbar. Rabatt wird einmalig je Kassiervorgang gewährt und ist nicht mit anderen Rabattaktionen kombinierbar.';
  } else if (muic === 1) {
    return 'Der Coupon ist einmalig einlösbar und nicht mit anderen Rabattaktionen kombinierbar. Der Rabatt wird innerhalb eines Einkaufs mehrfach gewährt. Abgabe nur in haushaltsüblichen Mengen.';
  } else {
    return 'Der Coupon ist mehrfach einlösbar und nicht mit anderen Rabattaktionen kombinierbar. Abgabe nur in haushaltsüblichen Mengen.';
  }
};

const getDiscountImageForAcardo = (
  discountType: string,
  value: number
): string | null => {
  if (discountType === 'CURRENCY') {
    switch (value) {
      case 1:
        return acardoImageUrls.oneEuro;
      case 1.5:
        return acardoImageUrls.oneEuroFifty;
      case 2:
        return acardoImageUrls.twoEuro;
      default:
        return null;
    }
  } else if (discountType === 'PERCENT') {
    switch (value) {
      case 5:
        return acardoImageUrls.fivePercent;
      case 10:
        return acardoImageUrls.tenPercent;
      case 15:
        return acardoImageUrls.fifteenPercent;
      default:
        return null;
    }
  } else return null;
};

const getDiscountTextWithBlank = (discountType: string, value: number) => {
  if (discountType === 'CURRENCY') {
    return `${prettyCurrency(value)} `;
  } else if (discountType === 'PERCENT') {
    return `${prettyFloats(value)}% `;
  }
  return '';
};

export const getCouponTextForProductCategories = (
  couponProvider: CouponProvider,
  productCategories: Array<ProductCategory>,
  discountType: string,
  value: number,
  maxReceiptUsagesCount: number,
  maxUserInstancesCount: number,
  appActivationRequired: boolean,
  minTransactionSum?: number
): CouponText => {
  const text = getTextForProductCategories(productCategories);
  const discountTextWithBlank = getDiscountTextWithBlank(discountType, value);
  const activationTextWithBlank = appActivationRequired
    ? `Aktivierbar: Bitte aktivieren Sie den Coupon vor der Einlösung.
`
    : '';
  let description = '',
    shortTitle = '',
    specialOfferHeadline = '',
    briefInfo = '',
    image = null;

  if (text) {
    if (couponProvider === CouponProvider.ACARDO) {
      description = `Beim nächsten Einkauf von ${text.mca.title} ${
        minTransactionSum
          ? `ab einem Wert von ${prettyCurrency(minTransactionSum)} `
          : ''
      }an der Kasse im Markt vorlegen und ${discountTextWithBlank}sparen.`;
      shortTitle = trimWords(
        `${discountTextWithBlank}Rabatt auf ${text.mca.title}`,
        50
      );
      specialOfferHeadline = '';
      briefInfo = '';
      image = text.acardo.image;
    } else if (couponProvider === CouponProvider.MCA) {
      description = `Erhalten Sie ${discountTextWithBlank}${
        text.mca.description
      }.
${activationTextWithBlank}${getLegalFooter(
        maxReceiptUsagesCount,
        maxUserInstancesCount
      )}`;
      shortTitle = trimWords(discountTextWithBlank + text.mca.description, 50);
      specialOfferHeadline = '';
      briefInfo = trimWords(text.couponTitle, 50);
      image = text.mca.image;
    }
  } else if (productCategories.length > 0) {
    const name = productCategories[0].name.substr(
      productCategories[0].name.indexOf('- ') + 2
    );
    if (couponProvider === CouponProvider.ACARDO) {
      description = `Beim nächsten Einkauf von ${name} ${
        minTransactionSum
          ? `ab einem Wert von ${prettyCurrency(minTransactionSum)} `
          : ''
      }an der Kasse im Markt vorlegen und ${discountTextWithBlank}sparen.`;
      shortTitle = trimWords(`${discountTextWithBlank}Rabatt auf ${name}`, 50);
      specialOfferHeadline = '';
      briefInfo = '';
    } else {
      const desc = `${discountTextWithBlank}Rabatt auf ${name}`;

      description = `Erhalten Sie ${desc}.
${activationTextWithBlank}${getLegalFooter(
        maxReceiptUsagesCount,
        maxUserInstancesCount
      )}`;
      shortTitle = trimWords(desc, 50);
      specialOfferHeadline = '';
      briefInfo = trimWords(`Coupon ${name}`, 50);
    }
  }

  return {
    description,
    shortTitle,
    specialOfferHeadline,
    briefInfo,
    image,
  };
};
