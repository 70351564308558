import * as api from '@opentelemetry/api';
import {WebTracerProvider} from '@opentelemetry/web';
import {B3Propagator} from '@opentelemetry/propagator-b3';

api.propagation.setGlobalPropagator(new B3Propagator());

const provider = new WebTracerProvider();
provider.register({
  propagator: new B3Propagator(),
});

const tracer = provider.getTracer('cat-app');

export default tracer;
