import * as React from 'react';
import {TextFieldProps} from '@edekadigital/backoffice-ui/lib/types/components/TextField';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {TextField} from '@edekadigital/backoffice-ui';
import {MainCouponCreate} from '../../model/model';
import {ControllerRenderProps} from 'react-hook-form/dist/types/controller';
import {NumberFieldDef} from '../../forms/models';
import {WrapFormRow} from '../form/formRenderUtils';

const MuiNumberFormat = ({
  onChange,
  value,
  ...rest
}: Partial<ControllerRenderProps>) => {
  return (
    <NumberFormat
      customInput={TextField}
      value={value}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale
      onValueChange={target => {
        onChange?.(target.floatValue ?? '');
      }}
      {...rest}
    />
  );
};

export interface FormNumberFieldProps extends Partial<TextFieldProps> {
  definition: NumberFieldDef;
  doNotWrap?: boolean;
}

export const FormNumberField: React.FC<FormNumberFieldProps> = ({
  definition,
  doNotWrap = false,
  ...otherProps
}) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const show = definition.useShow(watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        name={definition.key}
        control={control}
        render={({field, formState}) => (
          <MuiNumberFormat
            name={definition.key}
            onChange={field.onChange}
            value={field.value}
            inputTestId={`textField-input-${definition.key}`}
            error={!!formState.errors[definition.key]}
            label={definition.label}
            helperText={
              (formState.errors[definition.key] as FieldError | undefined)
                ?.message
            }
            {...otherProps}
          />
        )}
      />
    </WrapFormRow>
  );
};
