import * as React from 'react';
import {ContentGroup, Heading} from '@edekadigital/backoffice-ui';
import {WrapFormRow} from '../form/formRenderUtils';
import {useFormContext} from 'react-hook-form';
import {MainCouponCreate} from '../../model/model';
import {ContentGeneratorDef} from '../../forms/models';
import {CouponTextGenerator} from '../coupon/CouponTextGenerator';

export interface FormContentGeneratorProps {
  definition: ContentGeneratorDef;
}

export const FormContentGenerator = ({
  definition,
}: FormContentGeneratorProps) => {
  const {watch} = useFormContext<MainCouponCreate>();
  const show = definition.useShow(watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={true}>
      <ContentGroup>
        <Heading
          variant="h6"
          component="h6"
          gutterBottom
          data-testid="contentGroup-title"
          color="textSecondary"
        >
          {definition.title}
        </Heading>
        <WrapFormRow>
          <CouponTextGenerator label={definition.buttonLabel} />
        </WrapFormRow>
      </ContentGroup>
    </WrapFormRow>
  );
};
