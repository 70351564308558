import {ProductCategory} from '../model/model';
import {acardoImageUrls} from '../model/CouponDefaultValues';

import ImgBeer from '../assets/warengruppen/mca/bier_neu.png';
import ImgBread from '../assets/warengruppen/mca/brot_und_backwaren.png';
import ImgButter from '../assets/warengruppen/mca/butter.png';
import ImgFats from '../assets/warengruppen/mca/fette_oele_butter.jpg';
import ImgMeat from '../assets/warengruppen/mca/fleisch.jpg';
import ImgBeverages from '../assets/warengruppen/mca/getraenke_allgemein_neu.png';
import ImgCheese from '../assets/warengruppen/mca/kaese.png';
import ImgCoffee from '../assets/warengruppen/mca/kaffee_neu.png';
import ImgSnacks from '../assets/warengruppen/mca/knabberartikel_neu.png';
import ImgCannedGoods from '../assets/warengruppen/mca/konserven_neu.png';
import ImgCosmetics from '../assets/warengruppen/mca/kosmetik.png';
import ImgPotatoes from '../assets/warengruppen/mca/reis_teigwaren_kartoffelprodukte.jpg';
import ImgChampaign from '../assets/warengruppen/mca/schaumwein_perlwein_stg_weinhaltige_getraenke.jpg';
import ImgSpirits from '../assets/warengruppen/mca/spirituosen_neu.jpg';
import ImgSweets from '../assets/warengruppen/mca/suesswaren_neu.jpg';
import ImgDogFood from '../assets/warengruppen/mca/tiernahrung_neu.png';
import ImgFrozenFood from '../assets/warengruppen/mca/vor_warengruppencoupons_tk.png';
import ImgAlcFreeBeverages from '../assets/warengruppen/mca/vor_warengruppencoupon_alkoholfreie_getraenke.png';
import ImgSweetSpreads from '../assets/warengruppen/mca/vor_warengruppencoupon_aufstriche_suess.png';
import ImgFlowers from '../assets/warengruppen/mca/vor_warengruppencoupon_blumen.jpg';
import ImgEggs from '../assets/warengruppen/mca/vor_warengruppencoupon_eier.jpg';
import ImgFish from '../assets/warengruppen/mca/vor_warengruppencoupon_fisch.png';
import ImgKetchup from '../assets/warengruppen/mca/vor_warengruppencoupon_mayon_remouladen_ketchup_speiseoele.jpg';
import ImgMilk from '../assets/warengruppen/mca/vor_warengruppencoupon_milch_joghurt_quark.png';
import ImgFruitsAndVegetables from '../assets/warengruppen/mca/vor_warengruppencoupon_obst_gemuese.png';
import ImgCleaningProducts from '../assets/warengruppen/mca/vor_warengruppencoupon_putzmittel.png';
import ImgWine from '../assets/warengruppen/mca/wein_neu.png';
import ImgSausage from '../assets/warengruppen/mca/wurst_neu.png';

export type ProductCategoryTextTypeAcardo = {
  image: string;
};

export type ProductCategoryTextTypeMca = {
  image: string | null;
  title: string;
  description: string;
};

export type ProductCategoryTextType = {
  categories: string[];
  couponTitle: string;
  acardo: ProductCategoryTextTypeAcardo;
  mca: ProductCategoryTextTypeMca;
};

// source: https://werkbank.edeka/pages/viewpage.action?spaceKey=LEUCHT&title=Warengruppen
export const productCategoryTexts: Array<ProductCategoryTextType> = [
  {
    categories: ['01'],
    couponTitle: 'Coupon Bedientheken Frischfleisch & Geflügel',
    acardo: {image: acardoImageUrls.fleisch},
    mca: {
      image: ImgMeat,
      title: 'Frischfleisch und Geflügel von der Bedientheke',
      description: 'Rabatt auf Frischfleisch und Geflügel von der Bedientheke',
    },
  },
  {
    categories: ['01', '02', '03', '04', '05', '17', '18'],
    couponTitle: 'Coupon Bedientheken',
    acardo: {image: acardoImageUrls.kaese},
    mca: {
      image: null,
      title: 'Bedientheken',
      description: 'Rabatt an den Bedientheken',
    },
  },
  {
    categories: ['01', '04'],
    couponTitle: 'Coupon Bedientheken Fleisch & Wurst',
    acardo: {image: acardoImageUrls.fleisch},
    mca: {
      image: ImgSausage,
      title: 'Fleisch und Wurst an der Bedientheke',
      description: 'Rabatt auf Fleisch und Wurst an der Bedientheke',
    },
  },
  {
    categories: ['02', '03', '05'],
    couponTitle: 'Coupon Selbstbedienung Fleisch & Wurst',
    acardo: {image: acardoImageUrls.fleisch},
    mca: {
      image: ImgSausage,
      title: 'Fleisch und Wurst aus der Selbstbedienung',
      description: 'Rabatt auf Fleisch und Wurst aus der Selbstbedienung',
    },
  },
  {
    categories: ['05'],
    couponTitle: 'Coupon Selbstbedienung Wurst & Schinken',
    acardo: {image: acardoImageUrls.wurst},
    mca: {
      image: ImgSausage,
      title: 'Wurst und Schinken aus der Selbstbedienung',
      description: 'Rabatt auf Wurst und Schinken aus der Selbstbedienung',
    },
  },
  {
    categories: ['06'],
    couponTitle: 'Coupon Bedientheken Fisch',
    acardo: {image: acardoImageUrls.fisch},
    mca: {
      image: ImgFish,
      title: 'Fischbedientheke',
      description: 'Rabatt an der Fischbedientheke',
    },
  },
  {
    categories: ['08'],
    couponTitle: 'Coupon Obst & Gemüse',
    acardo: {image: acardoImageUrls.obst},
    mca: {
      image: ImgFruitsAndVegetables,
      title: 'Obst und Gemüse',
      description: 'Rabatt auf Obst und Gemüse',
    },
  },
  {
    categories: ['08', '09', '10'],
    couponTitle: 'Coupon Obst & Gemüse',
    acardo: {image: acardoImageUrls.obst},
    mca: {
      image: ImgFruitsAndVegetables,
      title: 'alle Artikel der Obst und Gemüse Abteilung',
      description: 'Rabatt auf alle Artikel der Obst und Gemüse Abteilung',
    },
  },
  {
    categories: ['11'],
    couponTitle: 'Coupon Milchprodukte',
    acardo: {image: acardoImageUrls.milch},
    mca: {
      image: ImgMilk,
      title: 'Milch, Quark, Sahne, Schmand und Joghurt',
      description: 'Rabatt auf Milch, Quark, Sahne, Schmand und Joghurt',
    },
  },
  {
    categories: ['12'],
    couponTitle: 'Coupon Bedientheken Käse',
    acardo: {image: acardoImageUrls.kaese},
    mca: {
      image: ImgCheese,
      title: 'Käsebedientheke',
      description: 'Rabatt an der Käsebedientheke',
    },
  },
  {
    categories: ['12', '13'],
    couponTitle: 'Coupon Käse',
    acardo: {image: acardoImageUrls.kaese},
    mca: {image: ImgCheese, title: 'Käse', description: 'Rabatt auf Käse'},
  },
  {
    categories: ['13'],
    couponTitle: 'Coupon Selbstbedienung Käse',
    acardo: {image: acardoImageUrls.kaese},
    mca: {
      image: ImgCheese,
      title: 'Käse-Selbstbedienung',
      description: 'Rabatt an der Käse-Selbstbedienung',
    },
  },
  {
    categories: ['14', '15'],
    couponTitle: 'Coupon Fette, Öle & Butter',
    acardo: {image: acardoImageUrls.butter},
    mca: {
      image: ImgFats,
      title: 'Fette, Öle und Butter',
      description: 'Rabatt auf Fette, Öle und Butter',
    },
  },
  {
    categories: ['15'],
    couponTitle: 'Coupon Butter & Margarine',
    acardo: {image: acardoImageUrls.butter},
    mca: {
      image: ImgButter,
      title: 'Butter und Margarine',
      description: 'Rabatt auf Butter und Margarine',
    },
  },
  {
    categories: ['19'],
    couponTitle: 'Coupon Eier',
    acardo: {image: acardoImageUrls.eier},
    mca: {
      image: ImgEggs,
      title: 'Eier',
      description: 'Rabatt auf Eier',
    },
  },
  {
    categories: ['20'],
    couponTitle: 'Coupon Tiefgekühltes',
    acardo: {image: acardoImageUrls.tk},
    mca: {
      image: ImgFrozenFood,
      title: 'Tiefgekühltes',
      description: 'Rabatt auf Tiefgekühltes',
    },
  },
  {
    categories: ['21'],
    couponTitle: 'Coupon Zucker & Mehl',
    acardo: {image: acardoImageUrls.mehl},
    mca: {
      image: null,
      title: 'Zucker & Mehl',
      description: 'Rabatt auf Zucker & Mehl',
    },
  },
  {
    categories: ['21', '22'],
    couponTitle: 'Coupon Nudeln, Reis & Fertiggerichte',
    acardo: {image: acardoImageUrls.mehl},
    mca: {
      image: ImgPotatoes,
      title: 'Nudeln, Reis und Fertiggerichte',
      description: 'Rabatt auf Nudeln, Reis und Fertiggerichte',
    },
  },
  {
    categories: ['23'],
    couponTitle: 'Coupon Senf, Soßen & Mayonnaisen',
    acardo: {image: acardoImageUrls.sossen},
    mca: {
      image: ImgKetchup,
      title: 'Senf, Soßen und Mayonnaisen',
      description: 'Rabatt auf Senf, Soßen und Mayonnaisen',
    },
  },
  {
    categories: ['24'],
    couponTitle: 'Coupon Süße Brotaufstriche',
    acardo: {image: acardoImageUrls.aufstrich},
    mca: {
      image: ImgSweetSpreads,
      title:
        'süße Brotaufstriche - Marmelade, Honig, Nuss- und Schokoladen-Cremes',
      description:
        'Rabatt auf süße Brotaufstriche - Marmelade, Honig, Nuss- und Schokoladen-Cremes',
    },
  },
  {
    categories: ['26'],
    couponTitle: 'Coupon Obst-, Gemüse- & Sauerkonserven',
    acardo: {image: acardoImageUrls.dose},
    mca: {
      image: ImgCannedGoods,
      title: 'Obst-, Gemüse- oder Sauerkonserven',
      description: 'Rabatt auf Obst-, Gemüse- oder Sauerkonserven',
    },
  },
  {
    categories: ['27', '28', '29', '30', '31'],
    couponTitle: 'Coupon Süßigkeiten & Knabbereien',
    acardo: {image: acardoImageUrls.suesses},
    mca: {
      image: ImgSweets,
      title: 'Süßigkeiten und Knabbereien',
      description: 'Rabatt auf Süßigkeiten und Knabbereien',
    },
  },
  {
    categories: ['30'],
    couponTitle: 'Coupon Knabbereien',
    acardo: {image: acardoImageUrls.suesses},
    mca: {
      image: ImgSnacks,
      title: 'Knabberartikel',
      description: 'Rabatt auf Knabberartikel',
    },
  },
  {
    categories: ['32', '34', '35'],
    couponTitle: 'Coupon Selbstbedienung Backwaren',
    acardo: {image: acardoImageUrls.brot},
    mca: {
      image: ImgBread,
      title: 'Backwaren aus Selbstbedienung',
      description: 'Rabatt auf Backwaren aus Selbstbedienung',
    },
  },
  {
    categories: ['34', '35'],
    couponTitle: 'Coupon Backstation Backwaren',
    acardo: {image: acardoImageUrls.brot},
    mca: {
      image: ImgBread,
      title: 'Backstation',
      description: 'Rabatt an der Backstation',
    },
  },
  {
    categories: ['36'],
    couponTitle: 'Coupon Wein',
    acardo: {image: acardoImageUrls.wein},
    mca: {image: ImgWine, title: 'Weine', description: 'Rabatt auf Weine'},
  },
  {
    categories: ['36', '37', '39'],
    couponTitle: 'Coupon Wein & Sekt',
    acardo: {image: acardoImageUrls.wein},
    mca: {
      image: ImgWine,
      title: 'Wein und Sekt',
      description: 'Rabatt auf Wein und Sekt',
    },
  },
  {
    categories: ['36', '37', '39', '40', '41'],
    couponTitle: 'Coupon Alkoholische Getränke',
    acardo: {image: acardoImageUrls.getraenke},
    mca: {
      image: ImgBeverages,
      title: 'alkoholische Getränke',
      description: 'Rabatt auf alkoholische Getränke',
    },
  },
  {
    categories: ['37', '39'],
    couponTitle: 'Coupon Sekt',
    acardo: {image: acardoImageUrls.sekt},
    mca: {image: ImgChampaign, title: 'Sekt', description: 'Rabatt auf Sekt'},
  },
  {
    categories: ['40'],
    couponTitle: 'Coupon Spirituosen',
    acardo: {image: acardoImageUrls.getraenke},
    mca: {
      image: ImgSpirits,
      title: 'Spirituosen',
      description: 'Rabatt auf Spirituosen',
    },
  },
  {
    categories: ['41'],
    couponTitle: 'Coupon Bier',
    acardo: {image: acardoImageUrls.bier},
    mca: {image: ImgBeer, title: 'Bier', description: 'Rabatt auf Bier'},
  },
  {
    categories: ['41', '42'],
    couponTitle: 'Coupon Alkoholfreie Getränke & Bier',
    acardo: {image: acardoImageUrls.getraenke},
    mca: {
      image: ImgAlcFreeBeverages,
      title: 'alkoholfreie Getränke und Bier',
      description: 'Rabatt auf alkoholfreie Getränke und Bier',
    },
  },
  {
    categories: ['42'],
    couponTitle: 'Coupon Alkoholfreie Getränke',
    acardo: {image: acardoImageUrls.getraenke},
    mca: {
      image: ImgAlcFreeBeverages,
      title: 'alkoholfreie Getränke',
      description: 'Rabatt auf alkoholfreie Getränke',
    },
  },
  {
    categories: ['43'],
    couponTitle: 'Coupon Kaffee, Tee & Kakao',
    acardo: {image: acardoImageUrls.kaffee},
    mca: {
      image: ImgCoffee,
      title: 'Kaffee, Tee und Kakao',
      description: 'Rabatt auf Kaffee, Tee und Kakao',
    },
  },
  {
    categories: ['45', '46', '47', '48', '49'],
    couponTitle: 'Coupon Drogerie',
    acardo: {image: acardoImageUrls.putzen},
    mca: {
      image: null,
      title: 'Drogerie Artikel',
      description: 'Rabatt auf Drogerie Artikel',
    },
  },
  {
    categories: ['46'],
    couponTitle: 'Coupon Putz- & Reinigungsmittel',
    acardo: {image: acardoImageUrls.putzen},
    mca: {
      image: ImgCleaningProducts,
      title: 'Putz- und Reinigungsmittel',
      description: 'Rabatt auf Putz- und Reinigungsmittel',
    },
  },
  {
    categories: ['48'],
    couponTitle: 'Coupon Kosmetik',
    acardo: {image: acardoImageUrls.kosmetik},
    mca: {
      image: ImgCosmetics,
      title: 'Kosmetikprodukte',
      description: 'Rabatt auf Kosmetikprodukte',
    },
  },
  {
    categories: ['50'],
    couponTitle: 'Coupon Tiernahrung',
    acardo: {image: acardoImageUrls.tier},
    mca: {
      image: ImgDogFood,
      title: 'Tiernahrung',
      description: 'Rabatt auf Tiernahrung',
    },
  },
  {
    categories: ['50', '51'],
    couponTitle: 'Coupon Tiernahrung & Tierbedarf',
    acardo: {image: acardoImageUrls.tier},
    mca: {
      image: ImgDogFood,
      title: 'Tiernahrung und Tierbedarf',
      description: 'Rabatt auf Tiernahrung und Tierbedarf',
    },
  },
  {
    categories: ['52'],
    couponTitle: 'Coupon Blumen',
    acardo: {image: acardoImageUrls.pflanze},
    mca: {image: ImgFlowers, title: 'Blumen', description: 'Rabatt auf Blumen'},
  },
  {
    categories: ['52', '76'],
    couponTitle: 'Coupon Pflanzen- & Gartenprodukte',
    acardo: {image: acardoImageUrls.pflanze},
    mca: {
      image: ImgFlowers,
      title: 'Pflanzen- und Gartenprodukte',
      description: 'Rabatt auf Pflanzen- und Gartenprodukte',
    },
  },
];

export const excludedProductCategories = [
  '44', // Tabakwaren, Raucherbedarf
  '45', // Säuglings- und Kleinkindernahrung
  '68', // Zeitungen/Zeitschriften/Bücher
  '77', // Investitions- und Verbrauchsgüter
  '80', // Restaurant / Partyservice
  '81', // Tankstelle
  '82', // Foto-/Druckarbeiten
  '83', // Lotto / Toto
  '84', // Kostenartikel Umbuch/Verbrauch/Entnahme
  '85', // Werbemittel
  '86', // Kartenverkauf
  '87', // Leergut / Transporthilfsmittel
  '88', // Leergut Entlastung
  '89', // Dienstleistung
  '90', // Service / Information
  '91', // Ladenbau
  '92', // Versicherung / Finanzierung
  '93', // Entsorgung
  '94', // Reparatur/Wartung/Handwerks-Dienstleistg
  '95', // Betreuungsleistung Vertr./AD/Persleasing
  '96', // Streckenabr/Filialabr/Weiterbelast/RePrü
  '97', // Preisgruppen-Artikel
  '98', // Dummy / Indiv.GH / EH fix
  '99', // Dummy/Platzhalter
];

export const isValidProductCategory = (productCategory?: ProductCategory) => {
  return (
    productCategory &&
    excludedProductCategories.indexOf(
      productCategory.identifier.substr(0, 2)
    ) === -1
  );
};

export const getTextForProductCategories = (
  productCategories: Array<ProductCategory>
): ProductCategoryTextType | undefined => {
  const ids = productCategories
    .filter(it => it.identifier.length >= 2)
    .filter(isValidProductCategory)
    .map(it => it.identifier.substr(0, 2));

  return productCategoryTexts.find(
    it =>
      it.categories.length === ids.length &&
      it.categories.every(id => ids.includes(id))
  );
};
