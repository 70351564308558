import * as React from 'react';
import {SelectField, Spacer} from '@edekadigital/backoffice-ui';
import {makeStyles} from '@material-ui/core';
import {IntegerEuroField} from './IntegerEuroField';

const GREATER_SMALLER_LABEL_MAP = [
  {value: 'gt', label: 'ab'},
  {value: 'st', label: 'bis'},
];

export type TotalsumEditorAction = {
  type: 'sum';
  maxAmount?: number;
  minAmount?: number;
};

const useStyles = makeStyles(() => ({
  conditionSelect: {
    flexBasis: '80px',
    flexShrink: 0,
  },
  minAmountInput: {
    width: 160,
  },
}));

interface TotalsumEditorProps {
  maxAmount?: number;
  minAmount?: number;
  dispatch: (action: TotalsumEditorAction) => void;
}

export const TotalsumEditor = React.memo(function TotalsumEditor({
  maxAmount,
  minAmount,
  dispatch,
}: TotalsumEditorProps) {
  const classes = useStyles();

  return (
    <>
      <SelectField
        value={
          minAmount !== undefined
            ? 'gt'
            : maxAmount !== undefined
            ? 'st'
            : undefined
        }
        inputTestId="selectField-input-totalsum-editor-condition-select"
        menuItems={GREATER_SMALLER_LABEL_MAP}
        onChange={evt =>
          dispatch({
            type: 'sum',
            minAmount: evt.target.value === 'gt' ? 0.01 : undefined,
            maxAmount: evt.target.value === 'st' ? 0.01 : undefined,
          })
        }
        className={classes.conditionSelect}
      />
      <Spacer horizontal={1} vertical={2} />
      <IntegerEuroField
        onChange={value => {
          dispatch({
            type: 'sum',
            minAmount: minAmount !== undefined ? value : undefined,
            maxAmount: maxAmount !== undefined ? value : undefined,
          });
        }}
        value={minAmount ?? maxAmount ?? 0.01}
        className={classes.minAmountInput}
        testId="totalsum-editor-amount-input"
      />
      <Spacer horizontal={1} vertical={2} />
      <div>(0,01 € ist Pflichtminimum)</div>
    </>
  );
});
