import React from 'react';
import {useEdekaBackofficeApi} from '@edekadigital/backoffice-commons';
import {ProductCategory, ProductsResourceResponse} from '../model/model';
import {useStageConfig} from './useStageConfig';

export const useEpim = () => {
  const request = useEdekaBackofficeApi();
  const apiUrl = useStageConfig().config.epimApiUrl;

  const getCategories = React.useCallback(
    async (searchText?: string, limit?: number) => {
      const params = [
        searchText ? `search=${encodeURIComponent(searchText)}` : '',
        `size=${limit || 10}`,
      ]
        .filter(e => e)
        .join('&');
      return await request<ProductCategory[]>(
        `${apiUrl}/edapi/v1/product-categories?${params}`,
        'GET',
        {
          additionalHeaders: {
            accept: 'application/json',
          },
          authorizationHeader: 'Authorization',
        }
      );
    },
    [apiUrl, request]
  );

  const getProducts = React.useCallback(
    async (searchText?: string, limit?: number) => {
      const wildCardSerchText = `%${searchText}%`;
      const params = [
        searchText ? `name=${encodeURIComponent(wildCardSerchText)}` : '',
        `size=${limit || 10}`,
      ]
        .filter(e => e)
        .join('&');
      const additionalHeaders = {
        accept: 'application/json',
      };

      const response = await request<ProductsResourceResponse>(
        `${apiUrl}/edapi/v2/products?${params}`,
        'GET',
        {
          additionalHeaders: additionalHeaders,
          authorizationHeader: 'Authorization',
        }
      );

      return response.products || [];
    },
    [apiUrl, request]
  );

  const getProductByGtin = React.useCallback(
    async (gtin: string) => {
      const additionalHeaders = {
        accept: 'application/json',
      };

      const response = await request<ProductsResourceResponse>(
        `${apiUrl}/edapi/v2/products?gtin=${encodeURIComponent(gtin)}`,
        'GET',
        {
          additionalHeaders: additionalHeaders,
          authorizationHeader: 'Authorization',
        }
      );

      return response?.products?.[0] || null;
    },
    [apiUrl, request]
  );

  return {
    getProducts,
    getProductByGtin,
    getCategories,
  };
};
