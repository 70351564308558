import * as React from 'react';
import {useEpim} from '../../../hooks/useEpim';
import {ProductCategory} from '../../../model/model';
import {Autocomplete} from '@edekadigital/backoffice-ui';
import {throttle} from 'lodash';
import {makeStyles} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  autocompleteOverrides: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: 'white',
    },
  },
}));

export interface CategorySelectorProps {
  testIdKey: string;
  items: ProductCategory[];
  onChange: (items: ProductCategory[]) => void;
  className?: string;
  label?: string;
}

export const CategorySelector: React.FC<CategorySelectorProps> = ({
  testIdKey,
  items,
  onChange,
  className,
  label,
}) => {
  const {getCategories} = useEpim();
  const throttledGetCategories = throttle(getCategories, 100);
  const classes = useStyles();

  const fetchOptions = async (
    inputValue: string
  ): Promise<ProductCategory[]> => {
    if (inputValue.length > 0) {
      return ((await throttledGetCategories(inputValue)) || [])
        .filter(c => c.identifier)
        .map(c => ({
          identifier: c.identifier,
          name: c.name || c.identifier,
        }));
    } else {
      return [];
    }
  };

  const findItems = async (...inputValues: string[]) => {
    return new Promise<ProductCategory[]>(resolve =>
      resolve(
        inputValues
          .filter(identifier => identifier && identifier.length > 0)
          .map(identifier => ({
            identifier: identifier,
            name: `Warengruppe ${identifier}`,
          }))
      )
    );
  };

  const getOptionLabel = (category: ProductCategory) => category.name;

  return (
    <Autocomplete
      label={label}
      inputPlaceholder="Warengruppen eingeben"
      value={items}
      onChange={onChange}
      fetchOptions={fetchOptions}
      getOptionLabel={getOptionLabel}
      findItems={findItems}
      className={classNames(classes.autocompleteOverrides, className)}
      data-testid={`selectField-input-${testIdKey}`}
      inputTestId={`selectField-input-${testIdKey}-input`}
    />
  );
};
