import {useTheme} from '@edekadigital/backoffice-ui';
import {FieldError, useFormContext} from 'react-hook-form';
import * as React from 'react';
import {Theme} from '@material-ui/core';
import {MainCouponCreate} from '../../model/model';
import {WrapFormRow} from '../form/formRenderUtils';
import {StoreSelector} from '../StoreSelector';

export interface FormMarketSelectorProps {
  doNotWrap?: boolean;
}

export const FormMarketSelector = ({
  doNotWrap = false,
}: FormMarketSelectorProps): JSX.Element => {
  const {
    formState: {errors},
    register,
    watch,
    setValue,
  } = useFormContext<MainCouponCreate>();
  const theme = useTheme<Theme>();

  return (
    <>
      <WrapFormRow doNotWrap={doNotWrap}>
        {errors.stores && (
          <div
            data-testid="errors-stores"
            style={{color: theme.palette.error.main}}
          >
            {(errors.stores &&
              errors.stores.pop &&
              errors.stores?.pop()?.message) ||
              (errors.stores as unknown as FieldError).message}
          </div>
        )}
        <input type="hidden" {...register('stores')} />
      </WrapFormRow>
      <WrapFormRow doNotWrap={doNotWrap}>
        <StoreSelector
          storeIds={watch('stores')}
          onSetStores={storeIds => setValue('stores', storeIds)}
        />
      </WrapFormRow>
    </>
  );
};
