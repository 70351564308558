import {useCallback, useState} from 'react';
import {useEdekaBackofficeApi} from '@edekadigital/backoffice-commons';
import {Region, StoreListResponse, StoreVisualItem} from '../model/model';
import {
  cachedStoresEmpty,
  cacheStores,
  getAllCachedStores,
} from '../utils/stores';
import {useStageConfig} from './useStageConfig';

export const useMarketService = () => {
  const [currentCacheKey, setCurrentCacheKey] = useState<string>('');
  const request = useEdekaBackofficeApi();
  const apiUrl = useStageConfig().config.marketApiUrl;

  const retrieveAllStores = useCallback(
    async (region: string, service: string): Promise<StoreVisualItem[]> => {
      const cacheKey = `${region}|${service}`;
      if (currentCacheKey !== cacheKey || cachedStoresEmpty()) {
        let page = -1;
        let size = 1000; // TODO increase to max
        let totalElements: number;
        const regionFilter =
          region !== Region.EZ ? '&region=' + encodeURIComponent(region) : '';

        do {
          page += 1;
          const response = await request<StoreListResponse>(
            `${apiUrl}/v3/markets/rwws?page=${page}&size=${size}&service=${encodeURIComponent(
              service
            )}${regionFilter}`,
            'GET',
            {
              additionalHeaders: {
                accept: 'application/json',
              },
              authorizationHeader: 'Authorization',
            }
          );

          const storeVisualItems = response.content.map(store => ({
            id: store.id,
            gln: store.gln,
            name: store.name,
            region: store.region,
            distributionChannel: store.distributionChannel,
            zipCode: store.address.zipCode,
            merchant: store.merchant,
            services: store.services,
          })) as StoreVisualItem[];
          cacheStores(storeVisualItems);

          totalElements = response.totalElements;
          size = response.size;
        } while (totalElements > page * size);

        setCurrentCacheKey(cacheKey);
      }

      return getAllCachedStores();
    },
    [apiUrl, request, currentCacheKey]
  );

  return {
    retrieveAllStores,
  };
};
