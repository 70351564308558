import {FormFieldArea, FormFieldDefinition, FormFieldTypes} from './models';
import {CouponProvider, MainCouponCreate} from '../model/model';

const updateInitialDataForArea = (
  initialData: MainCouponCreate,
  fields: FormFieldDefinition[]
) => {
  for (const field of fields) {
    if (field.type === FormFieldTypes.ContentGroup) {
      initialData = updateInitialDataForArea(initialData, field.fields);
    } else if (field.type === FormFieldTypes.Validity) {
      initialData.validity = field.initialValue;
    } else if (field.type === FormFieldTypes.MarketSelector) {
      initialData.stores = [];
    } else if ('initialValue' in field && 'key' in field) {
      initialData[field.key] = field.initialValue as never;
    } else if (field.type === FormFieldTypes.ContentGenerator) {
      // Nothing to do
    } else {
      console.log(
        `Todo: Do not know how to handle initialValue for field type ${field.type}`
      );
    }
  }

  return initialData;
};

export const initialValueBuilder = (
  definition: FormFieldArea[]
): MainCouponCreate => {
  let initialData = {
    providers: [] as CouponProvider[],
  } as MainCouponCreate;

  for (const area of definition) {
    initialData = updateInitialDataForArea(initialData, area.fields);
  }

  return initialData;
};
