import {StoreVisualItem} from '../model/model';

const storeData = new Map<string, StoreVisualItem>();

const storeCompare = (s0: StoreVisualItem, s1: StoreVisualItem) => {
  return `${s0.region}${s0.name}${s0.id}`.localeCompare(
    `${s1.region}${s1.name}${s1.id}`,
    'de'
  );
};

export const getCachedStore = (storeId: string): StoreVisualItem => {
  return storeData.has(storeId)
    ? (storeData.get(storeId) as unknown as StoreVisualItem)
    : ({
        id: storeId,
        name: 'Default',
        region: '??',
      } as StoreVisualItem);
};

export const getCachedStores = (
  storeIds: string[] | undefined
): StoreVisualItem[] => {
  const stores = storeIds
    ? storeIds.map(storeId => getCachedStore(storeId))
    : new Map<string, StoreVisualItem>();
  return [...stores.values()].sort(storeCompare);
};

export const cachedStoresEmpty = (): boolean => {
  return storeData.size === 0;
};

export const getAllCachedStores = (): StoreVisualItem[] => {
  return [...storeData.values()];
};

export const cacheStores = (stores: StoreVisualItem[]) => {
  stores.forEach(store => storeData.set(store.id, store));
};
