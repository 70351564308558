import {EdapiVolume} from '../model/model';

type Conversion = {
  unit: string;
  factor: number;
  threshold: number;
};

export class Unit {
  unit: string;
  prettyUnit: string;
  baseUnit: string;
  baseFactor: number;
  lowConversion?: Conversion;
  highConversion?: Conversion;

  constructor(
    unit: string,
    prettyUnit: string,
    baseUnit?: string,
    baseFactor?: number,
    lowConversion?: Conversion,
    highConversion?: Conversion
  ) {
    this.unit = unit;
    this.prettyUnit = prettyUnit;
    this.baseUnit = baseUnit || unit;
    this.baseFactor = baseFactor || 1;
    this.lowConversion = lowConversion;
    this.highConversion = highConversion;
  }

  print() {
    return this.prettyUnit;
  }
}

class ConvertibleVolume {
  volume: number;
  volumePerUnit: Unit;

  constructor(volume: number, volumePerUnit: string) {
    this.volume = volume;
    this.volumePerUnit = lookupUnit(volumePerUnit);
  }

  toBaseUnit() {
    return new ConvertibleVolume(
      this.volume * this.volumePerUnit.baseFactor,
      this.volumePerUnit.baseUnit
    );
  }

  print() {
    return this.printWoUnit() + this.volumePerUnit.print();
  }

  printWoUnit() {
    return this.volume.toLocaleString('de-DE');
  }
}

export const newVolume = (
  volume: number,
  volumePerUnit: string
): ConvertibleVolume => {
  const u = lookupUnit(volumePerUnit);
  if (u.lowConversion && volume < u.lowConversion.threshold) {
    return newVolume(volume * u.lowConversion.factor, u.lowConversion.unit);
  }
  if (u.highConversion && volume > u.highConversion.threshold) {
    return newVolume(volume * u.highConversion.factor, u.highConversion.unit);
  }
  return new ConvertibleVolume(volume, volumePerUnit);
};
export const fromEdapiVolume = (v: EdapiVolume) =>
  newVolume(v.volume, v.volumePerUnit);

const units = [
  new Unit('STK', ' Stück'),
  new Unit('KGM', 'kg', 'G', 1000, {unit: 'G', factor: 1000, threshold: 1}),
  new Unit(
    'G',
    'g',
    'G',
    1,
    {unit: 'MG', factor: 1000, threshold: 1},
    {unit: 'KGM', factor: 0.001, threshold: 1000}
  ),
  new Unit('MG', 'mg', 'G', 0.001, undefined, {
    unit: 'G',
    factor: 0.001,
    threshold: 1000,
  }),
  new Unit('LTR', 'l', 'LTR', 1, {
    unit: 'ML',
    factor: 1000,
    threshold: 0.5,
  }),
  new Unit('ML', 'ml', 'LTR', 0.001, undefined, {
    unit: 'LTR',
    factor: 0.001,
    threshold: 1000,
  }),
].reduce((map: Record<string, Unit>, u: Unit) => {
  map[u.unit] = u;
  return map;
}, {});

const lookupUnit = (unit: string) => {
  const u = units[unit];
  if (u) {
    return u;
  }
  throw new Error('Unknown unit: ' + unit);
};
