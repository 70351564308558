import {FormFieldArea, FormFieldDefinition, FormFieldTypes} from './models';
import Joi, {SchemaMap} from 'joi';

const updateJoiSchemaDefinition = (
  schemaDefinition: SchemaMap,
  fields: FormFieldDefinition[]
) => {
  for (const field of fields) {
    if (field.type === FormFieldTypes.ContentGroup) {
      schemaDefinition = updateJoiSchemaDefinition(
        schemaDefinition,
        field.fields
      );
    } else if (field.type === FormFieldTypes.Validity) {
      schemaDefinition.validity = field.validators.validityValidator;
      schemaDefinition.validFrom = field.validators.validFromValidator;
      schemaDefinition.validTill = field.validators.validTillValidator;
      schemaDefinition.showFrom = field.validators.showFromValidator;
      schemaDefinition.showTill = field.validators.showTillValidator;
    } else if (field.type === FormFieldTypes.MarketSelector) {
      schemaDefinition.stores = Joi.array()
        .unique()
        .min(1)
        .required()
        .messages({
          'array.min': 'Bitte wählen Sie mind. einen Markt aus',
          'any.required': 'Bitte wählen Sie mind. einen Markt aus',
        });
    } else if (field.type === FormFieldTypes.HiddenField) {
      schemaDefinition[field.key] = Joi.any();
    } else if (field.type === FormFieldTypes.ContentGenerator) {
      // Don't do anything
    } else if (field.type === FormFieldTypes.ConditionsEditor) {
      schemaDefinition[field.key] = Joi.array()
        .required()
        .min(1)
        .message('Bitte setzen Sie mind. eine Bedingung');
    } else if ('validator' in field && 'key' in field) {
      schemaDefinition[field.key] = field.validator;
    } else {
      console.log(
        `Todo: Do not know how to handle validation for field type ${field.type}`
      );
    }
  }

  return schemaDefinition;
};

export const schemaBuilder = (
  definition: FormFieldArea[]
): Joi.ObjectSchema => {
  let schemaDefinition = {
    providers: Joi.array(),
  } as SchemaMap;

  for (const area of definition) {
    schemaDefinition = updateJoiSchemaDefinition(schemaDefinition, area.fields);
  }

  return Joi.object(schemaDefinition);
};
