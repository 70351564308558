import * as React from 'react';
import {MainCouponCreate, ProductCategory} from '../../../model/model';
import {Controller, useFormContext} from 'react-hook-form';
import {CategorySelectorDef} from '../../../forms/models';
import {WrapFormRow} from '../../form/formRenderUtils';
import {CategorySelector} from './CategorySelector';

export interface FormCategorySelectorProps {
  definition: CategorySelectorDef;
  doNotWrap?: boolean;
}

export const FormCategorySelector: React.FC<FormCategorySelectorProps> = ({
  definition: {key, useShow},
  doNotWrap = false,
}) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const show = useShow(watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        render={({field: {value, onChange}}) => (
          <CategorySelector
            testIdKey={key}
            items={value as ProductCategory[]}
            onChange={onChange}
            label="Warengruppen"
          />
        )}
        name={key}
        control={control}
      />
    </WrapFormRow>
  );
};
