import {renderFormField, WrapFormRow} from '../form/formRenderUtils';
import {ContentGroup, Heading} from '@edekadigital/backoffice-ui';
import React from 'react';
import {ContentGroupDef} from '../../forms/models';

export interface FormContentGroupProps {
  definition: ContentGroupDef;
}

export const FormContentGroup = ({definition}: FormContentGroupProps) => {
  const doNotWrapChildren =
    definition.wrapInRows === undefined ? true : !definition.wrapInRows;
  const inner = (
    <WrapFormRow doNotWrap={doNotWrapChildren}>
      {definition.fields.map((e, i) => renderFormField(e, i, false))}
    </WrapFormRow>
  );

  return definition.title === undefined || definition.title === '' ? (
    inner
  ) : (
    <ContentGroup>
      <Heading
        variant="h6"
        component="h6"
        gutterBottom
        data-testid="contentGroup-title"
        color="textSecondary"
      >
        {definition.title}
      </Heading>
      {inner}
    </ContentGroup>
  );
};
