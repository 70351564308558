import {
  Condition,
  TriggerType,
} from '../react-hook-form/formConditionsEditor/ConditionEditor';
import {GPCContainmentVariant} from '../react-hook-form/formConditionsEditor/GPCEditor';

export function translateConditionsForBackend(conditions: Condition[]): string {
  if (conditions.length === 0) {
    return '';
  }

  const result = {
    trigger: {
      conditions: {} as {[index: number]: unknown},
      logic: '(',
    },
  };

  const nonPeriodConditions = conditions.filter(
    condition =>
      condition.triggerType !== TriggerType.NONE &&
      condition.triggerType !== TriggerType.PERIOD
  );

  let setNumber = 0;

  for (let i = 0; i < nonPeriodConditions.length; i++) {
    const condition = nonPeriodConditions[i];
    const conditionResult = (result.trigger.conditions[i + 1] = {} as Record<
      string,
      unknown
    >);
    conditionResult.trigger_type = condition.triggerType;
    setNumber = conditionResult.set_number = i + 1;

    switch (condition.triggerType) {
      case TriggerType.GPC: {
        conditionResult.description = 'Gruppen';
        conditionResult.gpcs = Array.from(
          new Set(
            condition.productCategories?.map(productCategory =>
              productCategory.identifier.substr(0, 2)
            ) ?? []
          )
        ).map(pCString => `${pCString}0000`);
        conditionResult.min_amount = `${(condition.minAmount ?? 0) * 100}`;
        result.trigger.logic +=
          condition.gpcContainment === GPCContainmentVariant.contains
            ? `${i > 0 ? '+' : ''}${i + 1}`
            : `-${i + 1}`;
        break;
      }
      case TriggerType.TOTALSUM: {
        conditionResult.description = 'Einkaufswert';
        if (condition.minAmount !== undefined) {
          conditionResult.min_amount = `${condition.minAmount * 100}`;
        } else if (condition.maxAmount !== undefined) {
          conditionResult.max_amount = `${condition.maxAmount * 100}`;
        }
        result.trigger.logic += `${i > 0 ? '+' : ''}${i + 1}`;
        break;
      }
      case TriggerType.GTIN: {
        conditionResult.description = 'Artikel';
        conditionResult.gtins =
          condition.products?.map(product => product.gtin) ?? [];
        conditionResult.min_amount = '1';
        result.trigger.logic += `${i > 0 ? '+' : ''}${i + 1}`;
      }
    }
  }

  const periodConditions = conditions.filter(
    condition => condition.triggerType === TriggerType.PERIOD
  );

  if (periodConditions.length > 0) {
    const conditionResult = (result.trigger.conditions[setNumber + 1] =
      {} as Record<string, unknown>);
    conditionResult.trigger_type = TriggerType.PERIOD;
    conditionResult.set_number = setNumber + 1;
    conditionResult.description = 'Wochentage';
    conditionResult.min_amount = '1';
    conditionResult.periods = {};
    for (let i = 0; i < periodConditions.length; i++) {
      const condition = periodConditions[i];
      if (condition.period !== undefined) {
        (conditionResult.periods as Record<string, unknown>)[
          condition.period.day
        ] = {
          start: condition.period.startTime,
          end: condition.period.endTime,
        };
      }
    }
    result.trigger.logic += `${setNumber > 0 ? '+' : ''}${setNumber + 1}`;
  }

  result.trigger.logic += ')';

  return JSON.stringify(result);
}
