import * as React from 'react';
import {MainCouponCreate} from '../../../model/model';
import {FieldError, useController, useFormContext} from 'react-hook-form';
import {ConditionsEditorDef} from '../../../forms/models';
import {WrapFormRow} from '../../form/formRenderUtils';
import {Condition, ConditionEditor, TriggerType} from './ConditionEditor';
import {Weekdays} from './PeriodEditor';
import {
  Body,
  Divider,
  Spacer,
  Subtitle,
  Switch,
  ConnectedBoxesList,
} from '@edekadigital/backoffice-ui';
import {useCallback, useMemo, useState} from 'react';

export interface FormConditionsEditorProps {
  definition: ConditionsEditorDef;
}

const TRIGGER_TYPE_LABEL_MAP: {value: TriggerType; label: string}[] = [
  {value: TriggerType.NONE, label: 'Keine Bedingung nötig'},
  {value: TriggerType.GTIN, label: 'Artikel'},
  {value: TriggerType.GPC, label: 'Warengruppe'},
  {value: TriggerType.TOTALSUM, label: 'Einkaufswert'},
];

const TIME_LABEL_MAP: {value: TriggerType; label: string}[] = [
  {value: TriggerType.PERIOD, label: 'Wochentag'},
];

export const noPeriodConditionsFilter = (condition: Condition) =>
  condition.period === undefined;

export const onlyPeriodConditionsFilter = (condition: Condition) =>
  condition.period !== undefined;

export const FormConditionsEditor: React.FC<FormConditionsEditorProps> = ({
  definition: {useShow, key, title, showPeriodConditions},
}) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const {
    field: {onChange, value},
    formState,
  } = useController({control, name: key});
  const show = useShow(watch);
  const [showTime, setShowTime] = useState(false);
  const error = formState.errors[key] as FieldError;

  const nonPeriodConditions = (value as Condition[]).filter(
    noPeriodConditionsFilter
  );
  const periodConditions = (value as Condition[]).filter(
    onlyPeriodConditionsFilter
  );

  const onNonPeriodConditionChanged = useCallback(
    (newCondition, index) => {
      const newNonPeriodConditions = [...nonPeriodConditions];
      newNonPeriodConditions[index] = {
        ...newNonPeriodConditions[index],
        ...newCondition,
      };
      onChange([...newNonPeriodConditions, ...periodConditions]);
    },
    [periodConditions, nonPeriodConditions, onChange]
  );

  const onPeriodConditionChanged = useCallback(
    (newCondition, index) => {
      const newPeriodConditions = [...periodConditions];
      newPeriodConditions[index] = {
        ...newPeriodConditions[index],
        ...newCondition,
      };
      onChange([...nonPeriodConditions, ...newPeriodConditions]);
    },
    [nonPeriodConditions, periodConditions, onChange]
  );

  const nonPeriodBoxes = useMemo(
    () =>
      nonPeriodConditions
        .map((condition, index) =>
          noPeriodConditionsFilter(condition) ? (
            <ConditionEditor
              key={`${key}-${index}`}
              choosableTriggerTypes={TRIGGER_TYPE_LABEL_MAP}
              condition={condition}
              index={index}
              onChange={onNonPeriodConditionChanged}
            />
          ) : null
        )
        .filter(elmt => elmt !== null),
    [key, nonPeriodConditions, onNonPeriodConditionChanged]
  );

  const periodBoxes = useMemo(
    () =>
      periodConditions
        .map((condition, index) =>
          onlyPeriodConditionsFilter(condition) ? (
            <ConditionEditor
              key={`${key}-${index}`}
              choosableTriggerTypes={TIME_LABEL_MAP}
              condition={condition}
              index={index}
              onChange={onPeriodConditionChanged}
            />
          ) : null
        )
        .filter(elmt => elmt !== null),
    [key, onPeriodConditionChanged, periodConditions]
  );

  return (
    <>
      <WrapFormRow hide={!show} doNotWrap={true}>
        {error && (
          <Body variant="body2" color="error">
            {error.message}
          </Body>
        )}
        <Subtitle color="textSecondary">{title}</Subtitle>
        <Spacer vertical={2} />
        <ConnectedBoxesList
          boxesContents={nonPeriodBoxes}
          addButtonLabel="Bedingung hinzufügen"
          connectionLabel="und"
          onAdd={() => {
            const newNonPeriodConditions = [...nonPeriodConditions];
            newNonPeriodConditions.push({triggerType: TriggerType.NONE});
            onChange([...newNonPeriodConditions, ...periodConditions]);
          }}
          onRemove={index => {
            const newNonPeriodConditions = [...nonPeriodConditions];
            newNonPeriodConditions.splice(index, 1);
            onChange([...newNonPeriodConditions, ...periodConditions]);
          }}
          testId={`formconditions-nonperiod-${key}`}
        />
      </WrapFormRow>
      {showPeriodConditions && (
        <>
          <Spacer vertical={4} />
          <Divider />
          <Spacer vertical={4} />
          <WrapFormRow hide={!show} doNotWrap={true}>
            <Switch
              label="Ausgabe nur an bestimmten Wochentagen"
              value={showTime}
              checked={showTime}
              onChange={(_evt, checked) => {
                if (!checked) {
                  onChange(nonPeriodConditions);
                }
                setShowTime(checked);
              }}
              inputTestId="formconditions-switch-periods"
            />
            <Spacer vertical={2} />
            <div
              hidden={!showTime}
              data-testid="formconditions-period-container"
            >
              <ConnectedBoxesList
                boxesContents={periodBoxes}
                addButtonLabel="Wochentag hinzufügen"
                connectionLabel="und"
                onAdd={() => {
                  const lastPeriod =
                    periodConditions.length > 0
                      ? periodConditions[periodConditions.length - 1]
                      : null;
                  const newPeriodConditions = [...periodConditions];
                  newPeriodConditions.push({
                    triggerType: TriggerType.PERIOD,
                    period: {
                      day: Weekdays.MO,
                      startTime: lastPeriod?.period?.startTime ?? '00:00',
                      endTime: lastPeriod?.period?.endTime ?? '23:59',
                    },
                  });
                  onChange([...nonPeriodConditions, ...newPeriodConditions]);
                }}
                onRemove={index => {
                  const newPeriodConditions = [...periodConditions];
                  newPeriodConditions.splice(index, 1);
                  onChange([...nonPeriodConditions, ...newPeriodConditions]);
                }}
                testId="formconditions-period"
              />
            </div>
          </WrapFormRow>
        </>
      )}
    </>
  );
};
