import {CouponProvider, MainCouponCreate} from '../../model/model';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {RouteComponentProps} from '@reach/router';
import {CreateCoupon} from './CreateCoupon';
import {Loader} from '@edekadigital/backoffice-ui';
import {useCatApp} from '../../hooks/useCatApp';
import {translateConditionsFromBackend} from '../form/translateConditionsFromBackend';
import {useEpim} from '../../hooks/useEpim';
import {AcardoCouponForm} from '../../forms/AcardoCoupon';
import {StandardCouponForm} from '../../forms/StandardCoupon';
import {initialValueBuilder} from '../../forms/initialValueBuilder';

type UpdateCouponProps = RouteComponentProps<{
  couponId: string;
}> & {isCopyMode: boolean};

export const UpdateCoupon: React.FC<UpdateCouponProps> = props => {
  const [hasError, setHasError] = useState<boolean>(false);

  const [mainCoupon, setMainCoupon] = useState<MainCouponCreate | null>(null);

  const {getProductByGtin, getCategories} = useEpim();
  const {getCouponById} = useCatApp({});

  useEffect(() => {
    getCouponById(props.couponId as string)
      .then(async mainCoupon => {
        if (props.isCopyMode) {
          // remove all fields from data that are not present in the initialValues
          const definition =
            mainCoupon.providers[0] === CouponProvider.ACARDO
              ? AcardoCouponForm
              : StandardCouponForm;
          const initialValues = initialValueBuilder(definition);
          for (const key in mainCoupon) {
            if (initialValues[key as keyof MainCouponCreate] === undefined) {
              delete mainCoupon[key as keyof MainCouponCreate];
            }
          }
        }

        if (typeof mainCoupon.outputConditions === 'string') {
          mainCoupon.outputConditions = await translateConditionsFromBackend(
            mainCoupon.outputConditions as unknown as string,
            getProductByGtin,
            getCategories
          );
        }
        if (typeof mainCoupon.redeemConditions === 'string') {
          mainCoupon.redeemConditions = await translateConditionsFromBackend(
            mainCoupon.redeemConditions as unknown as string,
            getProductByGtin,
            getCategories
          );
        }
        setMainCoupon(mainCoupon);
      })
      .catch(error => {
        console.log(error);
        setHasError(true);
      });
  }, [
    getCategories,
    getCouponById,
    getProductByGtin,
    props.couponId,
    props.isCopyMode,
  ]);

  if (hasError) {
    return <div>Fehler beim Laden der Coupondaten</div>;
  }

  if (!mainCoupon) {
    return <Loader message={'wird geladen'} />;
  }

  const couponProvider = mainCoupon.providers[0] || CouponProvider.MCA;

  return <CreateCoupon couponProvider={couponProvider} coupon={mainCoupon} />;
};
