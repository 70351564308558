import {FormFieldArea, FormFieldTypes} from './models';
import * as Joi from 'joi';
import {
  administrativeInstanceItems,
  costCenterItems,
  CouponProvider,
  CouponTemplate,
  couponTemplateItems,
  processUsageItems,
  targetGroupItems,
  userAssignmentItems,
} from '../model/model';
import {discountTypeItems} from '../model/CouponDefaultValues';
import {
  validatePositiveNumber,
  validateStringAllowEmpty,
} from '../validation/ValidationDefinitions';
import moment from 'moment';

const defaultShow = () => true;

export const StandardCouponForm: FormFieldArea[] = [
  {
    display: 'always',
    title: 'Artikel',
    fields: [
      {
        type: FormFieldTypes.Select,
        key: 'couponTemplate',
        label: 'Coupontemplate',
        onChange: (newValue, {setValue}): void => {
          if (newValue === CouponTemplate.WARENGRUPPE) {
            setValue('maxCouponCount', 1);
            setValue('maxReceiptUsagesCount', 300);
            setValue('appActivationRequired', true);
            setValue('includedProducts', []);
            setValue('hiddenIncludedProducts', []);
          }

          if (newValue === CouponTemplate.ARTIKEL) {
            setValue('maxCouponCount', 1);
            setValue('maxReceiptUsagesCount', 1);
            setValue('appActivationRequired', false);
            setValue('includedItemGroups', []);
          }
        },
        validator: Joi.string()
          .valid(...couponTemplateItems.map(v => v.value))
          .required()
          .messages({
            'string.empty': 'Bitte wählen Sie die Art des Coupons',
          }),
        values: couponTemplateItems,
        useShow: defaultShow,
        initialValue: CouponTemplate.ARTIKEL,
      },
      {
        type: FormFieldTypes.ProductSelector,
        key: 'includedProducts',
        label: 'Artikel',
        validator: Joi.array().unique().optional(),
        useShow: watch => {
          const couponTemplate = watch('couponTemplate');
          return couponTemplate === CouponTemplate.ARTIKEL;
        },
        initialValue: [],
      },
      {
        type: FormFieldTypes.ProductSelector,
        key: 'hiddenIncludedProducts',
        label: 'Nicht ausgelobte Artikel',
        validator: Joi.array().unique().optional(),
        useShow: watch => {
          const couponTemplate = watch('couponTemplate');
          return couponTemplate === CouponTemplate.ARTIKEL;
        },
        initialValue: [],
      },
      {
        type: FormFieldTypes.CategorySelector,
        key: 'includedItemGroups',
        validator: Joi.array().unique().optional(),
        useShow: watch => {
          const couponTemplate = watch('couponTemplate');
          return couponTemplate === CouponTemplate.WARENGRUPPE;
        },
        initialValue: [],
      },
      {
        type: FormFieldTypes.ContentGroup,
        title: 'Rabattierung',
        wrapInRows: true,
        fields: [
          {
            type: FormFieldTypes.NumberField,
            key: 'value',
            label: 'Wert',
            validator: Joi.number().positive().required().messages({
              'number.positive': 'nur Zahlen > 0',
              'number.base': 'Bitte geben Sie einen Wert ein',
            }),
            useShow: defaultShow,
            initialValue: 10,
          },
          {
            type: FormFieldTypes.Select,
            key: 'discountType',
            label: 'Einheit',
            validator: Joi.string()
              .valid(...discountTypeItems.map(v => v.value))
              .required(),
            values: discountTypeItems,
            useShow: defaultShow,
            initialValue: discountTypeItems[1].value,
          },
        ],
      },
      {
        type: FormFieldTypes.Select,
        key: 'targetGroup',
        label: 'Zielgruppe',
        validator: Joi.string()
          .valid(...targetGroupItems.map(v => v.value))
          .required()
          .messages({
            'any.invalid': 'Bitte wählen Sie eine Zielgruppe aus',
            'string.empty': 'Bitte wählen Sie eine Zielgruppe aus',
          }),
        values: targetGroupItems,
        useShow: watch => {
          const providers = watch('providers') ?? [CouponProvider.MCA];
          return providers[0] === CouponProvider.MCA;
        },
        initialValue: targetGroupItems[0].value,
      },
      {
        type: FormFieldTypes.Validity,
        useShow: watch => {
          const providers = watch('providers') ?? [CouponProvider.MCA];
          return providers[0] === CouponProvider.MCA;
        },
        validityLabel: 'Gültigkeit',
        showFromLabel: 'Sichtbar ab',
        showTillLabel: 'Sichtbar bis',
        validFromLabel: 'Gültig ab',
        validTillLabel: 'Gültig bis',
        initialValue: `${moment().isoWeek() + 1}`,
        validators: {
          validityValidator: validateStringAllowEmpty,
          showFromValidator: Joi.date()
            .required()
            .max(Joi.ref('validFrom'))
            .messages({
              'date.max':
                'Die Sichtbarkeit startet spätestens zum Start der Gültigkeit',
              'any.required':
                'Bitte geben Sie das Startdatum der Sichtbarkeit ein',
            }),
          showTillValidator: Joi.date()
            .required()
            .max(Joi.ref('validTill'))
            .greater(Joi.ref('showFrom'))
            .messages({
              'date.max':
                'Die Sichtbarkeit endet spätestens zum Ende der Gültigkeit',
              'date.greater': 'Enddatum muss nach dem Startdatum liegen',
              'any.required':
                'Bitte geben Sie das Enddatum der Sichtbarkeit ein',
            }),
          validFromValidator: Joi.date()
            .min(moment().startOf('day').toDate())
            .messages({
              'date.min':
                'Bitte wählen sie ein Startdatum der Gültigkeit aus, welches nicht in der Vergangenheit liegt',
              'any.required':
                'Bitte geben Sie das Startdatum der Gültigkeit ein',
            })
            .required(),
          validTillValidator: Joi.date()
            .required()
            .greater(Joi.ref('validFrom'))
            .messages({
              'date.greater': 'Enddatum muss nach dem Startdatum liegen',
              'any.required': 'Bitte geben Sie das Enddatum der Gültigkeit ein',
            }),
        },
      },
      {
        type: FormFieldTypes.HiddenField,
        key: 'region',
        initialValue: '',
      },
    ],
  },
  {
    display: 'always',
    title: 'Erscheinungsbild',
    fields: [
      {
        type: FormFieldTypes.ContentGenerator,
        title: 'Coupontexte automatisch generieren?',
        buttonLabel: 'Generieren',
        useShow: defaultShow,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'shortTitle',
        label: 'Überschrift',
        validator: Joi.string().trim().min(1).trim().required().messages({
          'string.empty': 'Bitte geben Sie eine Überschrift ein',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.TextField,
        key: 'specialOfferHeadline',
        label: 'Untertitel',
        validator: Joi.string().trim().required().messages({
          'string.trim': 'Bitte vermeiden sie Leerzeichen an Anfang und Ende',
          'string.empty': 'Bitte ergänzen Sie die Angebotsüberschrift',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.TextField,
        key: 'description',
        label: 'Beschreibung',
        multiline: true,
        validator: Joi.string().required().messages({
          'string.empty': 'Bitte ergänzen Sie die Meta Descriptions',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.TextField,
        key: 'briefInfo',
        label: 'Kassenbon Text',
        validator: Joi.string().trim().min(1).required().messages({
          'string.empty': 'Bitte ergänzen Sie die Coupon-Beschreibung',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.FormImage,
        key: 'image',
        label: 'Bild',
        validator: Joi.string().uri().required().messages({
          'string.empty': 'Bitte wählen Sie ein Bild',
          'string.uri': 'Die Angabe muss eine valide URL sein',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
    ],
  },
  {
    display: 'closable',
    title: 'Erweiterte Einstellungen',
    fields: [
      {
        type: FormFieldTypes.Select,
        key: 'processingType',
        label: 'Coupon Verwendung',
        validator: Joi.string().valid(''),
        values: [{value: '', label: ''}],
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.Select,
        key: 'couponGroup',
        label: 'Coupon Gruppe',
        validator: Joi.string().valid(''),
        values: [{value: '', label: ''}],
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.TextField,
        key: 'maxReceiptUsagesCount',
        inputType: 'number',
        label: 'Max Coupons per Einkauf',
        validator: Joi.number()
          .integer()
          .positive()
          .min(1)
          .required()
          .messages({
            'number.integer': 'nur Ziffern >= 1',
            'number.positive': 'nur Ziffern >= 1',
            'number.base':
              'Geben Sie eine Anzahl an wie häufig der Coupon je Bon einlösbar sein soll',
          }),
        useShow: defaultShow,
        initialValue: 1,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'maxUserInstancesCount',
        inputType: 'number',
        label: 'Max Einlösungen pro Person',
        validator: Joi.number().integer().positive().required().messages({
          'number.min': 'nur Ziffern >= 1',
          'number.integer': 'nur Ziffern >= 1',
          'number.positive': 'nur Ziffern >= 1',
          'number.base':
            'Geben Sie an wie häufig ein Nutzer den Coupon einlösen kann',
        }),
        useShow: defaultShow,
        initialValue: 1,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'maxCouponCount',
        inputType: 'number',
        label: 'Maximale Anzahl',
        validator: validatePositiveNumber,
        useShow: defaultShow,
        initialValue: 1,
      },
      {
        type: FormFieldTypes.ContentGroup,
        title: 'Produkt Wert',
        fields: [
          {
            type: FormFieldTypes.TextField,
            key: 'minProductValue',
            inputType: 'number',
            label: 'Minimum',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
          {
            type: FormFieldTypes.TextField,
            key: 'maxProductValue',
            inputType: 'number',
            label: 'Maximum',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
        ],
      },
      {
        type: FormFieldTypes.ContentGroup,
        title: 'Produkt Anzahl',
        fields: [
          {
            type: FormFieldTypes.TextField,
            key: 'minProductAmount',
            inputType: 'number',
            label: 'Minimum',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
          {
            type: FormFieldTypes.TextField,
            key: 'maxProductAmount',
            inputType: 'number',
            label: 'Maximum',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
        ],
      },
      {
        type: FormFieldTypes.ContentGroup,
        title: 'Bon Wert',
        fields: [
          {
            type: FormFieldTypes.TextField,
            key: 'minReceiptValue',
            inputType: 'number',
            label: 'Minimum',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
          {
            type: FormFieldTypes.TextField,
            key: 'maxReceiptValue',
            inputType: 'number',
            label: 'Maximum',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
        ],
      },
    ],
  },
  {
    display: 'closable',
    title: 'Märkte',
    defaultIsOpen: true,
    fields: [
      {
        type: FormFieldTypes.MarketSelector,
        validator: Joi.array().unique().min(1).required().messages({
          'array.min': 'Bitte wählen Sie mind. einen Markt aus',
          'any.required': 'Bitte wählen Sie mind. einen Markt aus',
        }),
        useShow: defaultShow,
      },
    ],
  },
  {
    display: 'closable',
    title: 'POS Verarbeitung',
    fields: [
      {
        type: FormFieldTypes.ContentGroup,
        fields: [
          {
            type: FormFieldTypes.TextField,
            key: 'posProcessingResolution',
            inputType: 'number',
            label: 'Resolution',
            validator: Joi.number().min(99).max(99).required(),
            useShow: defaultShow,
            initialValue: 99,
          },
          {
            type: FormFieldTypes.TextField,
            key: 'posProcessingSequence',
            inputType: 'number',
            label: 'Sequenz',
            validator: Joi.number().min(99).max(99).required(),
            useShow: defaultShow,
            initialValue: 99,
          },
        ],
      },
      {
        type: FormFieldTypes.TextField,
        key: 'interval',
        inputType: 'number',
        label: 'Interval',
        validator: Joi.number(),
        useShow: defaultShow,
        initialValue: 1,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'loyaltyProviderName',
        label: 'Loyalty Providername',
        validator: Joi.string().required().messages({
          'string.empty': 'Bitte wählen Sie eine Kostenstelle aus',
        }),
        useShow: defaultShow,
        initialValue: 'Test Genusskonto',
      },
      {
        type: FormFieldTypes.Select,
        key: 'processUsage',
        label: 'Prozess-Verwendung',
        validator: Joi.string().valid(
          ...[...processUsageItems.map(v => v.value), '']
        ),
        values: processUsageItems,
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.Select,
        key: 'userAssigment',
        label: 'Nutzerzuweisung',
        validator: Joi.string().valid(
          ...[...userAssignmentItems.map(v => v.value), '']
        ),
        values: userAssignmentItems,
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.SelectRegional,
        key: 'administrativeInstance',
        label: 'Administrative Instanz',
        validator: Joi.string().required().messages({
          'string.empty': 'Bitte wählen Sie eine Administrative Instanz aus',
        }),
        values: administrativeInstanceItems,
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.SelectRegional,
        key: 'costCenter',
        label: 'Kostenstelle',
        validator: Joi.string().required().messages({
          'string.empty': 'Bitte wählen Sie eine Kostenstelle aus',
        }),
        values: costCenterItems,
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.Select,
        key: 'retailerSupplier',
        label: 'Lieferant',
        validator: Joi.string().required(),
        values: [
          {
            value: 'Acardo Eigenmarkenaktionen EZ (SW)',
            label: 'Acardo Eigenmarkenaktionen EZ',
          },
          {value: 'EZHG', label: 'EZHG'},
          {value: 'KeineEDIMeldung', label: 'KeineEDIMeldung'},
        ],
        useShow: defaultShow,
        initialValue: 'KeineEDIMeldung',
      },
      {
        type: FormFieldTypes.CheckBox,
        key: 'alwaysConsumable',
        label: 'Immer einlösbar',
        validator: Joi.boolean(),
        useShow: defaultShow,
        initialValue: false,
      },
      {
        type: FormFieldTypes.CheckBox,
        key: 'appActivationRequired',
        label: 'Aktivierung in der App',
        validator: Joi.boolean(),
        useShow: defaultShow,
        initialValue: false,
      },
      {
        type: FormFieldTypes.CheckBox,
        key: 'meansOfPayment',
        label: 'Zahlungsmittel',
        validator: Joi.boolean(),
        useShow: defaultShow,
        initialValue: false,
      },
    ],
  },
  {
    display: 'closable',
    title: 'Meta Tags',
    fields: [
      {
        type: FormFieldTypes.Metatags,
        key: 'metaTags',
        useShow: defaultShow,
        validator: Joi.array()
          .items(
            Joi.object({
              name: Joi.string().required(),
              description: Joi.string().required(),
              visible: Joi.boolean().required(),
            })
          )
          .messages({
            'string.empty':
              'Die Felder dürfen nicht leer sein. Im Zweifel löschen sie die ganze Zeile, wenn Sie einen meta tag nicht verwenden wollen.',
          }),
        initialValue: [
          {name: 'couponPrio', description: 'leer', visible: true},
          {name: 'couponType', description: 'statusCoupon', visible: true},
          {name: 'actionCode', description: 'actionCode', visible: true},
          {name: 'couponDiscount', description: '2 für 1', visible: true},
          {name: 'couponInfo', description: 'Info', visible: true},
        ],
      },
    ],
  },
];
