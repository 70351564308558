import * as React from 'react';
import {Button, useSnackbar} from '@edekadigital/backoffice-ui';
import {globalStyles} from '../styles/global';

type VersionInfoProps = {
  buildId: string;
  version: string;
};

export const VersionInfo: React.FC<VersionInfoProps> = props => {
  const snackbar = useSnackbar();
  const versionInfoText = `CAT-APP ${props.version} build ${props.buildId}. Copyright 2021 by Edeka Digital GmbH. Thundercats Hooo!`;
  const handleSubmit = () => {
    snackbar.push(
      {title: 'VersionInfo', message: versionInfoText},
      {variant: 'info'}
    );
  };

  const globalStyleClasses = globalStyles();
  return (
    <div className={globalStyleClasses.footer}>
      <Button variant="text" color="success" onClick={() => handleSubmit()}>
        CAT-APP created with love by Thundercats
      </Button>
    </div>
  );
};
