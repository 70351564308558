import * as React from 'react';
import {
  DrawerNavigation,
  DrawerNavigationItem,
  FileCopyOutlined,
  Person,
} from '@edekadigital/backoffice-ui';
import {useLocation, useNavigate} from '@reach/router';

export const APP_BASE_PATH = '/cat/app';
export const COUPON_OVERVIEW_PATH = `${APP_BASE_PATH}/`;
export const COPY_COUPON_PATH = `${APP_BASE_PATH}/copy-coupon`;
export const CREATE_COUPON_PATH = `${APP_BASE_PATH}/create-coupon`;
export const UPDATE_COUPON_PATH = `${APP_BASE_PATH}/update-coupon`;
export const CREATE_PERSONALIZED_COUPON_PATH = `${APP_BASE_PATH}/create-personalized-coupon`;

export const Navigation: React.FC = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const navItems: Array<DrawerNavigationItem<string>> = [
    {
      label: 'Coupons',
      items: [
        {
          label: 'Couponübersicht',
          value: COUPON_OVERVIEW_PATH,
          icon: FileCopyOutlined,
        },
        {
          label: 'Personalisierte Coupons',
          value: CREATE_PERSONALIZED_COUPON_PATH,
          icon: Person,
        },
      ],
    },
  ];
  const handleNavigation = (value: string) => {
    switch (value) {
      case CREATE_PERSONALIZED_COUPON_PATH:
        navigate(CREATE_PERSONALIZED_COUPON_PATH);
        break;
      default:
        navigate(COUPON_OVERVIEW_PATH);
        break;
    }
  };
  return (
    <DrawerNavigation
      items={navItems}
      value={location.pathname}
      onChange={(_event, value) => handleNavigation(value)}
    >
      {props.children}
    </DrawerNavigation>
  );
};
