import Joi from 'joi';
import {EdapiProduct, MainCouponCreate, Metatag, Region} from '../model/model';
import moment from 'moment';
import {UseFormReturn, UseFormWatch} from 'react-hook-form';
import {Condition} from '../components/react-hook-form/formConditionsEditor/ConditionEditor';

export type doDisplayFunc = (watch: UseFormWatch<MainCouponCreate>) => boolean;
export enum FormFieldTypes {
  ContentGroup,
  FormImage,
  CategorySelector,
  CheckBox,
  TextField,
  HiddenField,
  NumberField,
  ProductSelector,
  CampaignSelector,
  MarketSelector,
  Select,
  Validity,
  DatePicker,
  ContentGenerator,
  ConditionsEditor,
  Metatags,
  SelectRegional,
}

export type FormFieldDefinition =
  | ContentGroupDef
  | FormImageDef
  | CategorySelectorDef
  | CheckBoxDef
  | TextFieldDef
  | HiddenFieldDef
  | NumberFieldDef
  | ProductSelectorDef
  | CampaignSelectorDef
  | MarketSelectorDef
  | SelectDef
  | ValidityDef
  | DatePickerDef
  | ContentGeneratorDef
  | ConditionsEditorDef
  | MetatagsDef
  | SelectRegionalDef;

export interface ContentGroupDef {
  type: FormFieldTypes.ContentGroup;
  title?: string;
  wrapInRows?: boolean;
  fields: FormFieldDefinition[];
}

export interface FormImageDef {
  type: FormFieldTypes.FormImage;
  key: keyof MainCouponCreate;
  label: string;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue: string;
}

export interface CategorySelectorDef {
  type: FormFieldTypes.CategorySelector;
  key: keyof MainCouponCreate;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue: string[];
}

export interface CheckBoxDef {
  type: FormFieldTypes.CheckBox;
  key: keyof MainCouponCreate;
  label: string;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue: boolean;
}
export interface TextFieldDef {
  type: FormFieldTypes.TextField;
  key: keyof MainCouponCreate;
  label: string;
  multiline?: boolean;
  validator?: Joi.AnySchema;
  inputType?: 'string' | 'number' | undefined;
  useShow: doDisplayFunc;
  initialValue: string | number;
}
export interface HiddenFieldDef {
  type: FormFieldTypes.HiddenField;
  key: keyof MainCouponCreate;
  initialValue: unknown;
}
export interface NumberFieldDef {
  type: FormFieldTypes.NumberField;
  key: keyof MainCouponCreate;
  label: string;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue?: number;
}
export interface ProductSelectorDef {
  type: FormFieldTypes.ProductSelector;
  key: keyof MainCouponCreate;
  label: string;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue: EdapiProduct[];
}
export interface CampaignSelectorDef {
  type: FormFieldTypes.CampaignSelector;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue: string;
}

export interface MarketSelectorDef {
  type: FormFieldTypes.MarketSelector;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
}

export interface SelectDef {
  type: FormFieldTypes.Select;
  key: keyof MainCouponCreate;
  label: string;
  values: SelectFieldOption[];
  onChange?: (
    newValue: string,
    formMethods: UseFormReturn<MainCouponCreate>
  ) => void;
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue?: string;
}

export interface SelectRegionalDef {
  type: FormFieldTypes.SelectRegional;
  key: keyof MainCouponCreate;
  label: string;
  onChange?: (
    newValue: string,
    formMethods: UseFormReturn<MainCouponCreate>
  ) => void;
  values: SelectRegionalFieldOption[];
  validator?: Joi.AnySchema;
  useShow: doDisplayFunc;
  initialValue: string;
}

export interface ValidityDef {
  type: FormFieldTypes.Validity;
  useShow: doDisplayFunc;
  validityLabel: string;
  showFromLabel: string;
  showTillLabel: string;
  validFromLabel: string;
  validTillLabel: string;
  initialValue: string;
  validators: {
    validityValidator: Joi.AnySchema;
    showFromValidator: Joi.AnySchema;
    showTillValidator: Joi.AnySchema;
    validFromValidator: Joi.AnySchema;
    validTillValidator: Joi.AnySchema;
  };
}

export interface DatePickerDef {
  type: FormFieldTypes.DatePicker;
  key: keyof MainCouponCreate;
  label: string;
  validator?: Joi.AnySchema;
  defaultDate?: moment.Moment;
  disablePast: boolean;
  minDate?: Date;
  useShow: doDisplayFunc;
  initialValue: string;
}

export interface ContentGeneratorDef {
  type: FormFieldTypes.ContentGenerator;
  title: string;
  buttonLabel: string;
  useShow: doDisplayFunc;
}

export interface ConditionsEditorDef {
  type: FormFieldTypes.ConditionsEditor;
  key: keyof MainCouponCreate;
  title: string;
  showPeriodConditions: boolean;
  useShow: doDisplayFunc;
  initialValue: Condition[];
}

export interface MetatagsDef {
  type: FormFieldTypes.Metatags;
  key: keyof MainCouponCreate;
  useShow: doDisplayFunc;
  validator?: Joi.AnySchema;
  initialValue: Metatag[];
}

export type FormFieldArea =
  | {
      display: 'always';
      title: string;
      fields: FormFieldDefinition[];
    }
  | {
      display: 'closable';
      title: string;
      fields: FormFieldDefinition[];
      dataTestId?: string;
      defaultIsOpen?: boolean;
    };

export type SelectFieldOption = {
  label: string;
  value: string;
};

export type SelectRegionalFieldOption = {
  region: Region;
  label: string;
  value: string;
};
