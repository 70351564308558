import {CouponTemplate} from './model';

export const discountTypeItems = [
  {value: 'CURRENCY', label: '€'},
  {value: 'PERCENT', label: '%'},
  {value: 'NEW_PRICE', label: 'neuer Preis'},
];

export const couponTemplateItems = [
  {value: CouponTemplate.ARTIKEL, label: 'Artikel'},
  {value: CouponTemplate.WARENGRUPPE, label: 'Warengruppe'},
];

const acardoImageUrlBase =
  'https://acardo-promotions-mediacenter.s3.eu-central-1.amazonaws.com/staging/bon-image-11KB';

export const acardoImageUrls = {
  coupon: `${acardoImageUrlBase}/1625132400982_coupon.bmp`,
  edeka: `${acardoImageUrlBase}/1625132432770_edeka.bmp`,
  oneEuro: `${acardoImageUrlBase}/1625132257157_1_00eur.bmp`,
  oneEuroFifty: `${acardoImageUrlBase}/1625132321738_1_50eur.bmp`,
  twoEuro: `${acardoImageUrlBase}/1625132332971_2_00eur.bmp`,
  fivePercent: `${acardoImageUrlBase}/1625137516938_5_p_klein.bmp`,
  tenPercent: `${acardoImageUrlBase}/1625137528990_10_p_klein.bmp`,
  fifteenPercent: `${acardoImageUrlBase}/1625137545114_15_p_klein.bmp`,
  fleisch: `${acardoImageUrlBase}/1625132462447_fleisch.bmp`,
  wurst: `${acardoImageUrlBase}/1625132640765_wurst.bmp`,
  kaese: `${acardoImageUrlBase}/1625132510155_kaese.bmp`,
  fisch: `${acardoImageUrlBase}/1625132451917_fisch.bmp`,
  obst: `${acardoImageUrlBase}/1625132563417_obst.bmp`,
  gemuese: `${acardoImageUrlBase}/1625132485723_gemuese.bmp`,
  milch: `${acardoImageUrlBase}/1625132553662_milch.bmp`,
  butter: `${acardoImageUrlBase}/1625132386089_butter.bmp`,
  eier: `${acardoImageUrlBase}/1625132442134_eier.bmp`,
  tk: `${acardoImageUrlBase}/1625132682562_tk.bmp`,
  mehl: `${acardoImageUrlBase}/1625132543651_mehl.bmp`,
  sossen: `${acardoImageUrlBase}/1625132626676_sossen.bmp`,
  dose: `${acardoImageUrlBase}/1625132423176_dose.bmp`,
  suesses: `${acardoImageUrlBase}/1625132652943_suesses.bmp`,
  brot: `${acardoImageUrlBase}/1625132375093_brot.bmp`,
  getraenke: `${acardoImageUrlBase}/1625132499976_getraenke.bmp`,
  bier: `${acardoImageUrlBase}/1625132364624_bier.bmp`,
  kaffee: `${acardoImageUrlBase}/1625132521478_kaffee.bmp`,
  tier: `${acardoImageUrlBase}/1625132663477_tier.bmp`,
  pflanze: `${acardoImageUrlBase}/1625132574392_pflanze.bmp`,
  sekt: `${acardoImageUrlBase}/1625132613985_sekt.bmp`,
  kosmetik: `${acardoImageUrlBase}/1625132533731_kosmetik.bmp`,
  aufstrich: `${acardoImageUrlBase}/1625132354270_aufstrich.bmp`,
  wein: `${acardoImageUrlBase}/1625065414574_wein.bmp`,
  putzen: `${acardoImageUrlBase}/1625132587872_putzen.bmp`,
};

export const acardoImageTypeItems = [
  {value: '', label: 'Kein Bild'},
  {value: acardoImageUrls.coupon, label: 'Header'},
  {value: acardoImageUrls.edeka, label: 'EDEKA Logo'},
  {value: acardoImageUrls.oneEuro, label: '1 € Rabatt'},
  {value: acardoImageUrls.oneEuroFifty, label: '1,50 € Rabatt'},
  {value: acardoImageUrls.twoEuro, label: '2 € Rabatt'},
  {value: acardoImageUrls.fivePercent, label: '5% Rabatt'},
  {value: acardoImageUrls.tenPercent, label: '10% Rabatt'},
  {value: acardoImageUrls.fifteenPercent, label: '15% Rabatt'},
  {value: acardoImageUrls.fleisch, label: 'Fleisch'},
  {value: acardoImageUrls.wurst, label: 'Wurst'},
  {value: acardoImageUrls.kaese, label: 'Käse'},
  {value: acardoImageUrls.fisch, label: 'Fisch'},
  {value: acardoImageUrls.obst, label: 'Obst'},
  {value: acardoImageUrls.gemuese, label: 'Gemüse'},
  {value: acardoImageUrls.milch, label: 'Milch'},
  {value: acardoImageUrls.butter, label: 'Butter'},
  {value: acardoImageUrls.eier, label: 'Eier'},
  {value: acardoImageUrls.tk, label: 'Tiefkühl'},
  {value: acardoImageUrls.mehl, label: 'Mehl'},
  {value: acardoImageUrls.sossen, label: 'Soßen'},
  {value: acardoImageUrls.dose, label: 'Dose'},
  {value: acardoImageUrls.suesses, label: 'Süßes'},
  {value: acardoImageUrls.brot, label: 'Brot'},
  {value: acardoImageUrls.getraenke, label: 'Getränke'},
  {value: acardoImageUrls.bier, label: 'Bier'},
  {value: acardoImageUrls.kaffee, label: 'Kaffee'},
  {value: acardoImageUrls.tier, label: 'Tier'},
  {value: acardoImageUrls.pflanze, label: 'Pflanzen'},
  {value: acardoImageUrls.sekt, label: 'Sekt'},
  {value: acardoImageUrls.kosmetik, label: 'Kosmetik'},
  {value: acardoImageUrls.aufstrich, label: 'Aufstrich'},
];

export const acardoAdditionalText =
  'Pro Einkauf ist nur ein Coupon einlösbar. Nicht mit anderen Rabatten kombinierbar. Teil- oder Barauszahlung ausgeschlossen.';
