import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {HiddenFieldDef} from '../../forms/models';
import {MainCouponCreate} from '../../model/model';

export interface FormHiddenFieldProps {
  definition: HiddenFieldDef;
}

export const FormHiddenField = ({definition}: FormHiddenFieldProps) => {
  const {register} = useFormContext<MainCouponCreate>();
  return (
    <input
      type="hidden"
      {...register(definition.key)}
      data-testid={`hiddenField-input-${definition.key}`}
    />
  );
};
