import * as React from 'react';
import {TextFieldProps, TextField} from '@edekadigital/backoffice-ui';
import {TextFieldDef} from '../../forms/models';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import {MainCouponCreate} from '../../model/model';
import {WrapFormRow} from '../form/formRenderUtils';

export interface FormTextFieldProps extends Partial<TextFieldProps> {
  definition: TextFieldDef;
  doNotWrap?: boolean;
}

export const FormTextField = ({
  definition,
  doNotWrap = false,
  ...otherProps
}: FormTextFieldProps) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const show = definition.useShow(watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        control={control}
        name={definition.key}
        render={({field: {onChange, value, name, ref}, formState}) => (
          <TextField
            name={name}
            onChange={onChange}
            value={(value as string) ?? ''}
            inputTestId={`textField-input-${definition.key}`}
            inputRef={ref}
            error={!!formState.errors[definition.key]}
            label={definition.label}
            multiline={definition.multiline}
            helperText={
              (formState.errors[definition.key] as FieldError | undefined)
                ?.message
            }
            {...otherProps}
          />
        )}
      />
    </WrapFormRow>
  );
};
