import * as React from 'react';
import {useEffect} from 'react';
import {FormRow} from '@edekadigital/backoffice-ui';
import moment from 'moment';
import {dateFormat} from '../../utils/utils';
import {useFormContext, UseFormSetValue} from 'react-hook-form';
import {MainCouponCreate} from '../../model/model';
import {FormSelectField} from '../react-hook-form/FormSelectField';
import {FormDatePicker} from '../react-hook-form/FormDatePicker';
import {FormFieldTypes, ValidityDef} from '../../forms/models';
import {calculateValidity} from './calculateValidity';

const CUSTOM = 'custom';

const updateValidity = (
  setValue: UseFormSetValue<MainCouponCreate>,
  v: string
) => {
  if (v !== CUSTOM) {
    const {showFrom, showTill, validFrom, validTill} = calculateValidity(
      Number.parseInt(v)
    );
    setValue('validFrom', validFrom.format(dateFormat));
    setValue('validTill', validTill.format(dateFormat));
    setValue('showFrom', showFrom.format(dateFormat));
    setValue('showTill', showTill.format(dateFormat));
  }
};

const kw = moment().isoWeek();
const validityItems = [
  {value: `${kw + 1}`, label: `KW ${kw + 1}`},
  {value: `${kw + 2}`, label: `KW ${kw + 2}`},
  {value: `${kw + 3}`, label: `KW ${kw + 3}`},
  {value: CUSTOM, label: 'Benutzerdefiniert'},
];

export interface CouponValidityProps {
  definition: ValidityDef;
}

export const CouponValidity = ({
  definition,
}: CouponValidityProps): JSX.Element => {
  const {watch, setValue} = useFormContext<MainCouponCreate>();
  const show = definition.useShow(watch);

  const validity = watch('validity');

  useEffect(() => {
    updateValidity(setValue, validity);
  }, [setValue, validity]);

  return (
    <div data-testid="validity-container" hidden={!show}>
      <FormSelectField
        definition={{
          type: FormFieldTypes.Select,
          key: 'validity',
          label: definition.validityLabel,
          values: validityItems,
          useShow: () => true,
          initialValue: definition.initialValue,
        }}
        defaultValue={definition.initialValue}
      />
      <div hidden={validity !== CUSTOM}>
        <FormRow gutterBottom={true}>
          <FormDatePicker
            definition={{
              type: FormFieldTypes.DatePicker,
              key: 'showFrom',
              disablePast: false,
              label: definition.showFromLabel,
              useShow: () => true,
              initialValue: '',
            }}
            doNotWrap
          />
          <FormDatePicker
            definition={{
              type: FormFieldTypes.DatePicker,
              key: 'showTill',
              disablePast: false,
              label: definition.showTillLabel,
              useShow: () => true,
              initialValue: '',
            }}
            doNotWrap
          />
        </FormRow>
        <FormRow gutterBottom={true}>
          <FormDatePicker
            definition={{
              type: FormFieldTypes.DatePicker,
              key: 'validFrom',
              disablePast: true,
              minDate: moment().toDate(),
              label: definition.validFromLabel,
              useShow: () => true,
              initialValue: '',
            }}
            doNotWrap
          />
          <FormDatePicker
            definition={{
              type: FormFieldTypes.DatePicker,
              key: 'validTill',
              disablePast: true,
              minDate: moment().toDate(),
              label: definition.validTillLabel,
              useShow: () => true,
              initialValue: '',
            }}
            doNotWrap
          />
        </FormRow>
      </div>
    </div>
  );
};
