import {TotalsumEditor, TotalsumEditorAction} from './TotalsumEditor';
import {GPCContainmentVariant, GPCEditor, GPCEditorAction} from './GPCEditor';
import {PeriodEditor, PeriodEditorAction, Weekdays} from './PeriodEditor';
import * as React from 'react';
import {Condition, TriggerType} from './ConditionEditor';
import {GtinEditor, GtinEditorAction} from './GtinEditor';

export function renderEditor(
  condition: Condition,
  dispatch: (
    action:
      | TotalsumEditorAction
      | GPCEditorAction
      | PeriodEditorAction
      | GtinEditorAction
  ) => void
) {
  switch (condition.triggerType) {
    case TriggerType.NONE: {
      return null;
    }
    case TriggerType.PERIOD: {
      return (
        <PeriodEditor
          day={condition.period?.day ?? Weekdays.MO}
          startTime={condition.period?.startTime ?? '00:00'}
          endTime={condition.period?.endTime ?? '23:59'}
          dispatch={dispatch}
        />
      );
    }
    case TriggerType.GPC: {
      return (
        <GPCEditor
          gpcContainment={
            condition.gpcContainment ?? GPCContainmentVariant.contains
          }
          productCategories={condition.productCategories ?? []}
          minAmount={condition.minAmount ?? 0.01}
          dispatch={dispatch}
        />
      );
    }
    case TriggerType.GTIN: {
      return (
        <GtinEditor
          products={condition.products ?? []}
          dispatch={dispatch}
          minAmount={condition.minAmount ?? 0.01}
        />
      );
    }
    case TriggerType.TOTALSUM: {
      return (
        <TotalsumEditor
          maxAmount={condition.maxAmount}
          minAmount={condition.minAmount}
          dispatch={dispatch}
        />
      );
    }
  }
}
