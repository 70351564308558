import {makeStyles} from '@material-ui/core';

export const globalStyles = makeStyles({
  couponPreview: {
    border: '1px solid black',
  },
  couponContent: {
    padding: ' 25px  30px 0',
    backgroundColor: '#ecece9',
  },
  couponProviderImage: {
    marginRight: '10px',
  },
  couponProviderLink: {
    display: 'flex',
    alignItems: 'center',
  },
  couponText: {
    color: '#8e8e89',
    fontSize: '12px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  boldLargeText: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  validTill: {
    paddingTop: '10px',
    color: '#8e8e89',
    fontSize: '12px',
  },
  description: {
    padding: '20px 0',
    fontSize: '14px',
  },
  previewImage: {
    width: '50%',
    height: 'auto',
    margin: '20px auto',
  },
  couponPreviewCoc: {
    border: '1px solid black',
    width: '317px',
    height: 'auto',
  },
  couponContentCoC: {
    backgroundColor: '#ffffff',
    'text-align': 'center',
  },
  titleImageCoc: {
    width: '75%',
    height: 'auto',
    margin: '20px auto',
  },
  boldLargeTextCoc: {
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '0 10px',
  },
  descriptionCoc: {
    padding: '10px 20px',
    fontSize: '12px',
  },
  disclaimerCoc: {
    padding: '10px 20px',
    fontSize: '12px',
  },
  additionalTextCoc: {
    padding: '10px 20px',
    fontSize: '12px',
  },
  validityCoc: {
    paddingTop: '5px',
    fontSize: '12px',
  },
  previewImageCoc: {
    width: '50%',
    height: 'auto',
    margin: '10px auto',
  },
  barcodeImageCoc: {
    width: '50%',
    height: 'auto',
    margin: '15px auto',
  },
  footer: {
    position: 'fixed',
    bottom: '0px',
    right: '0px',
  },
  defaultLine: {
    position: 'absolute',
    left: '397px',
    top: '184px',
    width: '1463px',
    height: '1px',
    display: 'flex',
    transform: 'rotate(-0.0320305deg)',
    borderWidth: '0px',
  },
  defaultLabel: {
    borderWidth: '0px',
    width: '796px',
    height: '46px',
    display: 'flex',
    fontStyle: 'normal',
    fontSize: '34px',
  },
});
