import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Add,
  EnhancedDataTable,
  EnhancedDataTableColumn,
  EnhancedDataTableFetchData,
  Filter,
  Loader,
  Switch,
} from '@edekadigital/backoffice-ui';
import {
  CouponProvider,
  MainCouponCreate,
  Service,
  StoreVisualItem,
} from '../model/model';
import {useMarketService} from '../hooks/useMarketService';
import {
  ToolbarActionItem,
  ToolbarActionListItem,
} from '@edekadigital/backoffice-ui/lib/types/components/EnhancedDataTable/EnhancedDataTableToolbar';
import {useFormContext} from 'react-hook-form';
import {filterStores} from '../utils/storeFilter';

export type StoreSelectorProps = {
  storeIds: string[];
  onSetStores: (storeIds: string[]) => void;
};

const providerServiceMap = new Map<CouponProvider, string>([
  [CouponProvider.MCA, Service.GENUSS_PLUS],
  [CouponProvider.ACARDO, Service.COC_ACARDO],
]);

export const eqStoreVisualItems = (a1: string[], a2: string[]): boolean => {
  if (a1.length !== a2.length) return false;
  for (const a of a1) if (!a2.includes(a)) return false;
  return true;
};

export const StoreSelector: React.FC<StoreSelectorProps> = props => {
  // API STUFF
  const {retrieveAllStores} = useMarketService();

  const StoreSelectedComponent: React.FC<{
    row: StoreVisualItem;
  }> = componentProps => {
    return (
      <Switch
        label=""
        checked={
          componentProps.row && props.storeIds.includes(componentProps.row.id)
        }
        onChange={event => {
          const stores = props.storeIds;

          if (event.target.checked) {
            stores.push(componentProps.row.id);
          } else {
            const idx = stores.indexOf(componentProps.row.id);
            if (idx > -1) {
              stores.splice(idx, 1);
            }
          }

          props.onSetStores(stores);
        }}
      />
    );
  };

  const columns: Array<EnhancedDataTableColumn<StoreVisualItem>> = [
    {
      accessor: 'isSelected',
      label: 'Gewählt',
      component: StoreSelectedComponent,
    },
    {accessor: 'name', label: 'Name'},
    {accessor: 'gln', label: 'GLN'},
    {accessor: 'distributionChannel', label: 'Vertriebskanal'},
    {accessor: 'zipCode', label: 'PLZ'},
    {accessor: 'merchant', label: 'Kaufmann/Kauffrau'},
  ];

  const {watch} = useFormContext<MainCouponCreate>();
  const filters: Array<Filter<StoreVisualItem>> = [
    {
      accessor: 'name',
      label: 'Marktname',
      multiple: true,
    },
    {
      accessor: 'gln',
      label: 'GLN',
      multiple: true,
    },
    {
      accessor: 'distributionChannel',
      label: 'Vertriebskanal',
      multiple: true,
    },
    {
      accessor: 'zipCode',
      label: 'PLZ',
      multiple: true,
    },
    {
      accessor: 'merchant',
      label: 'Kaufmann/Kauffrau',
      multiple: true,
    },
    {
      accessor: 'selectedStores',
      label: 'Selektierte Märkte',
      selectorValues: ['Selektierte', 'Nicht Selektierte'],
      multiple: false,
    },
  ];

  const [filteredStores, setFilteredStores] = useState<string[]>([]);
  const [retrievedStores, setRetrievedStores] = useState<StoreVisualItem[]>([]);
  const [filtersActive, setFiltersActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const selectedStoreCount = props.storeIds.length;
  const region = watch('region');
  const providers: CouponProvider[] = watch('providers');
  const service: string | undefined = providerServiceMap.get(providers[0]);

  useEffect(() => {
    if (region && service) {
      retrieveAllStores(region, service).then(stores => {
        setRetrievedStores(
          stores.filter(store => store.services.includes(service as string))
        );
        setLoading(false);
      });
    }
  }, [region, retrieveAllStores, service]);

  const filteredActiveStores = filtersActive
    ? filteredStores.filter(storeId => props.storeIds.includes(storeId))
    : [];
  const filteredInactiveStores = filtersActive
    ? filteredStores.filter(storeId => !props.storeIds.includes(storeId))
    : [];
  const toolbarActions: (ToolbarActionItem | ToolbarActionListItem)[] = [
    {
      label: 'Alle Märkte wählen',
      handler: () => {
        props.onSetStores(retrievedStores.map(store => store.id));
      },
      disabled: selectedStoreCount === retrievedStores.length,
    },
    {
      type: 'list',
      label: 'Mehr',
      items: [
        {
          label: 'Alle gefilterten Märkte wählen',
          handler: () => {
            const storeIds = props.storeIds;
            storeIds.push(...filteredInactiveStores);
            props.onSetStores(storeIds);
          },
          disabled: filteredInactiveStores.length === 0,
        },
        {
          label: 'Alle gefilterten Märkte abwählen',
          handler: () => {
            const storeIds = props.storeIds.filter(storeId => {
              return !filteredActiveStores.includes(storeId);
            });
            props.onSetStores(storeIds);
          },
          disabled: filteredActiveStores.length === 0,
        },
        {
          label: 'Alle Märkte abwählen',
          handler: () => {
            props.onSetStores([]);
          },
          disabled: selectedStoreCount === 0,
        },
      ],
    },
  ];

  const fetchData: EnhancedDataTableFetchData<StoreVisualItem> = ({
    size,
    page,
    filters,
    order,
    orderBy,
  }) => {
    const currentSize = size ? size : 25;
    const currentPage = page ? page : 0;

    return new Promise(resolve => {
      const stores = filterStores(retrievedStores, filters, props.storeIds);
      const sortOrderKey = orderBy
        ? (orderBy as keyof StoreVisualItem)
        : ('name' as keyof StoreVisualItem);
      const storeOrder = (s0: StoreVisualItem, s1: StoreVisualItem) => {
        const v0 = `${s0[sortOrderKey]}`;
        const v1 = `${s1[sortOrderKey]}`;

        return order === 'asc'
          ? v0.localeCompare(v1, 'de')
          : v1.localeCompare(v0, 'de');
      };

      if (
        !eqStoreVisualItems(
          stores.map(store => store.id),
          filteredStores
        )
      ) {
        setFilteredStores(stores.map(store => store.id));
      }

      const areFiltersActive = !!(filters && filters.length > 0);
      setFiltersActive(areFiltersActive);

      const totalCount = stores.length;
      resolve({
        data: stores
          .sort(storeOrder)
          .splice(currentPage * currentSize, currentSize),
        totalCount: totalCount,
        page: currentPage,
      });
    });
  };

  if (loading) {
    return (
      <div data-testid="loader-markets">
        <Loader message="Lade Märkte..." />
      </div>
    );
  }

  const headline =
    props.storeIds.length === 1
      ? '1 Markt gewählt'
      : `${props.storeIds.length} Märkte gewählt`;

  return (
    <EnhancedDataTable
      fetchData={fetchData}
      headline={headline}
      columns={columns}
      filters={filters}
      rowClickIcon={Add}
      defaultPageSize={10}
      rowsPerPageOptions={[10, 25, 50]}
      showResetToolbarFilters={true}
      toolbarActions={toolbarActions}
      toolbarBackgroundColor="primary"
      toolbarButtonVariant="text"
    />
  );
};
