import * as React from 'react';
import {RouteComponentProps, useNavigate} from '@reach/router';
// eslint-disable-next-line node/no-extraneous-import
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Add,
  Edit,
  EnhancedDataTable,
  EnhancedDataTableColumn,
  EnhancedDataTableFetchData,
  Filter,
  GridRow,
  Page,
  Paper,
  RowActionItem,
  Search,
  TitleBar,
} from '@edekadigital/backoffice-ui';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  CouponOverviewItem,
  CouponTemplateProvider,
  DateRange,
  Discount,
} from '../../model/couponOverviewModel';
import {datadogRum} from '@datadog/browser-rum';
import {useAuth} from '@edekadigital/backoffice-commons';
import {
  CouponProvider,
  DiscountUnit,
  Region,
  targetGroupItems,
} from '../../model/model';
import {
  COPY_COUPON_PATH,
  CREATE_COUPON_PATH,
  UPDATE_COUPON_PATH,
} from '../common/Navigation';
import {ProviderSnapshots, useCatApp} from '../../hooks/useCatApp';
import tracer from '../../utils/tracing';
import {NewCouponDialog, NewCouponDialogForm} from '../coupon/NewCouponDialog';
import {CouponSnapshotDialog} from '../coupon/CouponSnapshotDialog';

const CouponOverview: React.FC<RouteComponentProps> = () => {
  const dateFormat = 'DD.MM.YY';

  const span = tracer.startSpan('CouponOverview');
  const {listCoupons, getProviderSnapshot} = useCatApp({span: span});

  const {edekaRegions} = useAuth();
  const authRegion = (edekaRegions[0] as unknown) as Region;
  const [isNewCouponDlgOpen, setIsNewCouponDlgOpen] = React.useState<boolean>(
    false
  );
  const [providerSnapshots, setProviderSnapshots] = React.useState<
    ProviderSnapshots | undefined
  >(undefined);

  const filters: Array<Filter<CouponOverviewItem>> = [
    {
      accessor: 'title',
      label: 'Titel',
    },
    {
      accessor: 'validFilter',
      label: 'Ausspielung',
    },
    {
      accessor: 'showFilter',
      label: 'Einlösung',
    },
    {
      accessor: 'couponProvider',
      label: 'Coupon-Provider',
      selectorValues: [
        CouponProvider.ACARDO,
        CouponProvider.DC,
        CouponProvider.MCA,
      ],
    },
    {
      accessor: 'targetGroup',
      label: 'Zielgruppe',
      selectorValues: targetGroupItems.map(it => it.value),
    },
    {
      accessor: 'discountTypeFilter',
      label: 'Rabatt-Type',
      selectorValues: [DiscountUnit.CURRENCY, DiscountUnit.PERCENT],
    },
    {
      accessor: 'couponId',
      label: 'Coupon ID',
    },
    {
      accessor: 'gtins',
      label: 'Artikelnummer/n',
    },
  ];

  const fetchData: EnhancedDataTableFetchData<CouponOverviewItem> = ({
    page,
    size,
    filters,
    order,
    orderBy,
  }) => {
    return new Promise(resolve => {
      listCoupons(page, size, authRegion, order, orderBy, filters).then(
        response => {
          const couponOverviewItems = response.content.map(coupon => ({
            title: coupon.title,
            gtins: coupon.gtins,
            couponId: coupon.couponId,
            targetGroup: coupon.targetGroup,
            discount: {
              discountValue: coupon.value,
              discountUnit:
                DiscountUnit[coupon.discountType as keyof typeof DiscountUnit],
            } as Discount,
            showDateRange: {
              from: moment(coupon.showFrom).toDate(),
              till: moment(coupon.showTill).toDate(),
            } as DateRange,
            validDateRange: {
              from: moment(coupon.validFrom).toDate(),
              till: moment(coupon.validTill).toDate(),
            } as DateRange,
            couponTemplate: {
              providers: coupon.providers,
              couponTemplate: coupon.couponTemplate,
            } as CouponTemplateProvider,
          })) as CouponOverviewItem[];
          resolve({
            totalCount: response.totalSize,
            data: couponOverviewItems,
            page: response.page,
          });
        }
      );
    });
  };

  const DiscountComponent: React.FC<{children: Discount}> = ({children}) => {
    return children.discountUnit === DiscountUnit.CURRENCY ? (
      <NumberFormat
        decimalScale={2}
        displayType={'text'}
        decimalSeparator={','}
        value={children.discountValue}
        fixedDecimalScale={true}
        suffix={` ${children.discountUnit}`}
      />
    ) : (
      <NumberFormat
        decimalScale={0}
        displayType={'text'}
        value={children.discountValue}
        suffix={` ${children.discountUnit}`}
      />
    );
  };

  const DateRangeComponent: React.FC<{children: DateRange}> = ({children}) => (
    <span>
      {`${moment(children.from).format(dateFormat)} - ${moment(
        children.till
      ).format(dateFormat)}`}
    </span>
  );

  const CouponTemplateProviderComponent: React.FC<{
    children: CouponTemplateProvider;
  }> = ({children}) => (
    <span>
      {children.providers.join(',')}, {children.couponTemplate}
    </span>
  );

  const TargetGroupComponent: React.FC<{
    children: string;
  }> = ({children}) => {
    const targetGroup = targetGroupItems.find(it => it.value === children);
    return <span>{targetGroup ? targetGroup.label : children}</span>;
  };

  const GtinsComponent: React.FC<{
    children: Array<string>;
  }> = ({children}) => <span>{children.join(', ')}</span>;

  const columns: Array<EnhancedDataTableColumn<CouponOverviewItem>> = [
    {accessor: 'title', label: 'Titel'},
    {
      accessor: 'showDateRange',
      label: 'Ausspielung',
      component: DateRangeComponent,
    },
    {
      accessor: 'validDateRange',
      label: 'Einlösung',
      component: DateRangeComponent,
    },
    {
      accessor: 'couponTemplate',
      label: 'Coupon-Template',
      component: CouponTemplateProviderComponent,
    },
    {
      accessor: 'targetGroup',
      label: 'Zielgruppe',
      component: TargetGroupComponent,
    },
    {
      accessor: 'discount',
      label: 'Rabatt',
      component: DiscountComponent,
    },
    {accessor: 'couponId', label: 'Coupon ID'},
    {
      accessor: 'gtins',
      label: 'Artikelnummer/n',
      component: GtinsComponent,
    },
  ];

  const navigate = useNavigate();

  const rowActions: Array<RowActionItem<CouponOverviewItem>> = [
    {
      icon: Edit,
      handler: row => navigate(`${UPDATE_COUPON_PATH}/${row.couponId}`),
    },
    {
      icon: FileCopyIcon,
      handler: row => navigate(`${COPY_COUPON_PATH}/${row.couponId}`),
    },
    {
      icon: Search,
      handler: row =>
        getProviderSnapshot(row.couponId).then(setProviderSnapshots),
    },
  ];

  if (!authRegion || authRegion.length === 0) {
    datadogRum.addError(new Error('User without any assigned tenant'));
    return (
      <Page>
        <GridRow gridVariant={'6-6'}>
          <Paper headline={'Berechtigung'}>
            <div>Es ist für keine Region eine Berechtigung vorhanden</div>
          </Paper>
        </GridRow>
      </Page>
    );
  }

  const toolbarActions = [
    {
      icon: Add,
      label: 'Coupon anlegen',
      handler: () => setIsNewCouponDlgOpen(true),
    },
  ];

  const onCreateNewCoupon = (data: NewCouponDialogForm) => {
    setIsNewCouponDlgOpen(false);

    const url = `${CREATE_COUPON_PATH}/${data.couponProvider}?couponTemplate=${data.couponTemplate}&region=${data.region}&couponTitle=${data.couponTitle}`;
    navigate(url);
  };

  return (
    <Page variant={'default'} paddingBottom={true}>
      <TitleBar>Couponübersicht</TitleBar>
      <EnhancedDataTable
        columns={columns}
        filters={filters}
        defaultPageSize={25}
        fetchData={fetchData}
        rowActions={rowActions}
        rowsPerPageOptions={[25, 50, 100]}
        toolbarActions={toolbarActions}
      />
      <NewCouponDialog
        open={isNewCouponDlgOpen}
        onClose={() => setIsNewCouponDlgOpen(false)}
        onCreate={onCreateNewCoupon}
      />
      <CouponSnapshotDialog
        providerSnapshots={providerSnapshots}
        onClose={() => {
          setProviderSnapshots(undefined);
        }}
      />
    </Page>
  );
};

export default CouponOverview;
