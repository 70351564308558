import {FormFieldArea, FormFieldTypes} from './models';
import * as Joi from 'joi';
import {CouponTemplate} from '../model/model';
import {
  acardoAdditionalText,
  acardoImageTypeItems,
  discountTypeItems,
} from '../model/CouponDefaultValues';
import {
  validatePositiveNumber,
  validateStringAllowEmpty,
} from '../validation/ValidationDefinitions';
import {TriggerType} from '../components/react-hook-form/formConditionsEditor/ConditionEditor';
import moment from 'moment';

const defaultShow = () => true;

export const AcardoCouponForm: FormFieldArea[] = [
  {
    display: 'always',
    title: 'Basisdaten',
    fields: [
      {
        type: FormFieldTypes.HiddenField,
        key: 'couponTemplate',
        initialValue: CouponTemplate.WARENGRUPPE,
      },
      {
        type: FormFieldTypes.ContentGroup,
        title: 'Rabattierung',
        wrapInRows: true,
        fields: [
          {
            type: FormFieldTypes.NumberField,
            key: 'value',
            label: 'Wert',
            validator: Joi.number().positive().required().messages({
              'any.required': 'Bitte geben Sie einen Wert ein',
              'number.positive': 'nur Zahlen > 0',
            }),
            useShow: defaultShow,
          },
          {
            type: FormFieldTypes.Select,
            key: 'discountType',
            label: 'Einheit',
            validator: Joi.string()
              .valid(...discountTypeItems.map(v => v.value))
              .required()
              .messages({
                'any.required': 'Bitte wählen Sie eine Einheit aus',
              }),
            values: discountTypeItems,
            useShow: defaultShow,
            initialValue: discountTypeItems[0].value,
          },
        ],
      },
      {
        type: FormFieldTypes.ContentGroup,
        title: 'Druckzeitraum & Einlösezeitraum',
        wrapInRows: false,
        fields: [
          {
            type: FormFieldTypes.Validity,
            useShow: defaultShow,
            validityLabel: 'Druckzeitraum & Einlösezeitraum',
            showFromLabel: 'Start-Druckzeitraum',
            showTillLabel: 'Ende Druckzeitraum',
            validFromLabel: 'Start-Einlösedatum',
            validTillLabel: 'Finales Einlösedatum',
            initialValue: 'custom',
            validators: {
              validityValidator: validateStringAllowEmpty,
              showFromValidator: Joi.date()
                .max(Joi.ref('validFrom'))
                .messages({
                  'date.max':
                    'Der Druckzeitraum startet spätestens zum Start des Einlösedatums',
                  'any.required':
                    'Bitte geben Sie das Startdatum des Druckzeitraums ein',
                })
                .required(),
              showTillValidator: Joi.date()
                .max(Joi.ref('validTill'))
                .greater(Joi.ref('showFrom'))
                .messages({
                  'date.max':
                    'Der Druckzeitraum endet spätestens zum Ende der Einlösedatums',
                  'date.greater': 'Enddatum muss nach dem Startdatum liegen',
                  'any.required':
                    'Bitte geben Sie das Enddatum des Druckzeitraums ein',
                })
                .required(),
              validFromValidator: Joi.date()
                .min(moment().startOf('day').toDate())
                .messages({
                  'date.min':
                    'Bitte wählen sie ein Einlösedatum aus, welches nicht in der Vergangenheit liegt',
                  'any.required':
                    'Bitte geben Sie das Startdatum des Einlösezeitraums ein',
                })
                .required(),
              validTillValidator: Joi.date()
                .greater(Joi.ref('validFrom'))
                .messages({
                  'date.greater': 'Enddatum muss nach dem Startdatum liegen',
                  'any.required':
                    'Bitte geben Sie das Enddatum des Einlösezeitraums ein',
                })
                .required(),
            },
          },
          {
            type: FormFieldTypes.TextField,
            key: 'validAfterPrintForDays',
            inputType: 'number',
            label: 'Gültigkeit in Tagen ab Druck',
            validator: validatePositiveNumber,
            useShow: defaultShow,
            initialValue: 1,
          },
        ],
      },
      {
        type: FormFieldTypes.HiddenField,
        key: 'region',
        initialValue: '',
      },
    ],
  },
  {
    display: 'always',
    title: 'Ausgabebedingungen',
    fields: [
      {
        type: FormFieldTypes.ConditionsEditor,
        key: 'outputConditions',
        title:
          'Definieren Sie, welche Bedingungen erfüllt sein müssen, damit Ihr Kunde einen Coupon erhält.',
        showPeriodConditions: true,
        useShow: defaultShow,
        initialValue: [],
      },
    ],
  },
  {
    display: 'always',
    title: 'Einlösebedingungen',
    fields: [
      {
        type: FormFieldTypes.ConditionsEditor,
        key: 'redeemConditions',
        title:
          'Folgende Bedingungen (mindestens eine) müssen für die Einlösung des Coupons erfüllt sein:',
        showPeriodConditions: false,
        useShow: defaultShow,
        initialValue: [
          {
            triggerType: TriggerType.TOTALSUM,
            minAmount: 0.01,
          },
        ],
      },
    ],
  },
  {
    display: 'always',
    title: 'Erscheinungsbild',
    fields: [
      {
        type: FormFieldTypes.ContentGenerator,
        title: 'Coupontexte automatisch generieren?',
        buttonLabel: 'Generieren',
        useShow: defaultShow,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'shortTitle',
        label: 'Überschrift',
        validator: Joi.string().trim().min(1).trim().required().messages({
          'string.empty': 'Bitte geben Sie einen Titel ein',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.Select,
        key: 'image',
        label: 'Bild',
        validator: Joi.string()
          .valid(...acardoImageTypeItems.map(v => v.value))
          .required(),
        values: acardoImageTypeItems,
        useShow: defaultShow,
        initialValue: acardoImageTypeItems[0].value,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'description',
        label: 'Beschreibung',
        multiline: true,
        validator: Joi.string().required().messages({
          'string.empty': 'Bitte ergänzen Sie die Meta Descriptions',
        }),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.TextField,
        key: 'disclaimer',
        label: 'Disclaimer',
        validator: Joi.string().allow('').allow(null),
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.TextField,
        key: 'additionalText',
        label: 'Zusatztext',
        validator: Joi.string().allow('').allow(null),
        useShow: defaultShow,
        initialValue: acardoAdditionalText,
      },
    ],
  },
  {
    display: 'closable',
    title: 'Erweiterte Einstellungen',
    fields: [
      {
        type: FormFieldTypes.CheckBox,
        key: 'priority',
        label: 'Priorität',
        validator: Joi.boolean(),
        useShow: defaultShow,
        initialValue: false,
      },
      {
        type: FormFieldTypes.TextField,
        key: 'maxCouponCount',
        inputType: 'number',
        label: 'Maximale Anzahl',
        validator: validatePositiveNumber,
        useShow: defaultShow,
        initialValue: '',
      },
      {
        type: FormFieldTypes.Select,
        key: 'bill',
        label: 'Abrechnung',
        validator: Joi.string().required(),
        useShow: defaultShow,
        initialValue: '',
        values: [
          {value: 'Eigenaktion', label: 'Eigenaktion'},
          {value: 'EHG', label: 'EHG'},
        ],
      },
    ],
  },
  {
    display: 'closable',
    title: 'Märkte',
    dataTestId: 'market-selector-openclose',
    defaultIsOpen: true,
    fields: [
      {
        type: FormFieldTypes.MarketSelector,
        validator: Joi.array().unique().min(1).required().messages({
          'array.min': 'Bitte wählen Sie mind. einen Markt aus',
          'any.required': 'Bitte wählen Sie mind. einen Markt aus',
        }),
        useShow: defaultShow,
      },
    ],
  },
];
