import * as React from 'react';
import {CouponProvider, MainCouponCreate} from '../../model/model';
import {Body, Image, Paper} from '@edekadigital/backoffice-ui';
import moment from 'moment';
import {dateFormat} from '../../utils/utils';
import {globalStyles} from '../../styles/global';
import {useFormContext} from 'react-hook-form';
import {
  acardoAdditionalText,
  acardoImageUrls,
} from '../../model/CouponDefaultValues';
import ImgSampleBarcode from '../../assets/barcode.png';

function haveEnoughDataForPreview(
  shortTitle: string,
  description: string,
  validTill: string
): boolean {
  return (
    shortTitle.length > 0 &&
    description.length > 0 &&
    moment(validTill, dateFormat).isValid()
  );
}

export const EmptyCouponPreview: React.FC = () => (
  <Body>
    Sobald Sie die notwendigen Eingaben im Formular gemacht haben, sehen Sie
    hier eine Vorschau des Coupons.
  </Body>
);

export const McaCouponPreview: React.FC = () => {
  const globalStyleClasses = globalStyles();
  const {watch} = useFormContext<MainCouponCreate>();
  const [image, shortTitle, validTill, description, specialOfferHeadline] =
    watch([
      'image',
      'shortTitle',
      'validTill',
      'description',
      'specialOfferHeadline',
    ]);

  return (
    <div className={globalStyleClasses.couponPreview}>
      <div className={globalStyleClasses.previewImage}>
        <Image src={image} alt={shortTitle} />
      </div>
      <div className={globalStyleClasses.couponContent}>
        <div className={globalStyleClasses.couponText}>
          Coupon wird automatisch eingelöst, wenn Sie das Produkt kaufen und bei
          Bezahlung Genuss+ Punkte sammeln oder mobiles Bezahlen nutzen.
        </div>
        <div className={globalStyleClasses.boldLargeText}>{shortTitle}</div>
        <div className={globalStyleClasses.boldLargeText}>
          {specialOfferHeadline}
        </div>
        <div className={globalStyleClasses.validTill}>
          gültig bis {moment(validTill, dateFormat).format('DD.MM.YYYY')}
        </div>
        <div className={globalStyleClasses.description}>
          {description.split('\n').map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const AcardoCouponPreview: React.FC = () => {
  const globalStyleClasses = globalStyles();
  const {watch} = useFormContext<MainCouponCreate>();
  const [
    image,
    shortTitle,
    validFrom,
    validTill,
    description,
    specialOfferHeadline,
    disclaimer,
  ] = watch([
    'image',
    'shortTitle',
    'validFrom',
    'validTill',
    'description',
    'specialOfferHeadline',
    'disclaimer',
  ]);

  return (
    <div className={globalStyleClasses.couponPreviewCoc}>
      <div className={globalStyleClasses.titleImageCoc}>
        <Image src={acardoImageUrls.coupon} alt="Coupon" />
      </div>
      <div className={globalStyleClasses.couponContentCoC}>
        <div className={globalStyleClasses.boldLargeTextCoc}>{shortTitle}</div>
        <div className={globalStyleClasses.previewImageCoc} hidden={!image}>
          <Image src={image} alt={shortTitle} />
        </div>
        <div className={globalStyleClasses.boldLargeTextCoc}>
          {specialOfferHeadline}
        </div>
        <div className={globalStyleClasses.descriptionCoc}>
          {description.split('\n').map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}
        </div>
        <div className={globalStyleClasses.validityCoc}>
          Einlösbar von {moment(validFrom, dateFormat).format('DD.MM.')} -{' '}
          {moment(validTill, dateFormat).format('DD.MM.YYYY')}
        </div>
        {disclaimer && (
          <div className={globalStyleClasses.disclaimerCoc}>{disclaimer}</div>
        )}
        <div className={globalStyleClasses.additionalTextCoc}>
          {acardoAdditionalText}
        </div>
        <div className={globalStyleClasses.barcodeImageCoc}>
          <Image src={ImgSampleBarcode} alt="Barcode" />
        </div>
        <div>{'{Marktadresse}'}</div>
      </div>
    </div>
  );
};

export const CouponPreview: React.FC = () => {
  const {watch} = useFormContext<MainCouponCreate>();
  const [shortTitle, validTill, description, providers] = watch([
    'shortTitle',
    'validTill',
    'description',
    'providers',
  ]);

  let Result: React.FC = EmptyCouponPreview;

  if (haveEnoughDataForPreview(shortTitle, description, validTill)) {
    if (providers.includes(CouponProvider.MCA)) {
      Result = McaCouponPreview;
    } else if (providers.includes(CouponProvider.ACARDO)) {
      Result = AcardoCouponPreview;
    }
  }

  return (
    <Paper headline="Vorschau">
      <Result />
    </Paper>
  );
};
