import * as React from 'react';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import {TextField} from '@edekadigital/backoffice-ui';

const withValueLimit = ({floatValue}: NumberFormatValues) => {
  return (floatValue ?? 0) >= 0.01;
};

interface IntegerEuroFieldProps {
  onChange: (value: number) => void;
  value: number;
  className?: string;
  testId?: string;
}

export const IntegerEuroField = React.memo(function IntegerEuroField({
  onChange,
  value,
  testId,
  className,
}: IntegerEuroFieldProps) {
  return (
    <NumberFormat
      customInput={TextField}
      value={value}
      allowNegative={false}
      decimalSeparator={','}
      decimalScale={2}
      isAllowed={withValueLimit}
      fixedDecimalScale
      suffix=" €"
      onValueChange={target => {
        onChange(target.floatValue ?? 0.01);
      }}
      className={className}
      data-testid={testId}
    />
  );
});
