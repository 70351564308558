import * as React from 'react';
import {useEpim} from '../../../hooks/useEpim';
import {productDescription} from '../../../utils/products';
import {EdapiProduct} from '../../../model/model';
import {Autocomplete} from '@edekadigital/backoffice-ui';
import {throttle} from 'lodash';
import {makeStyles} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  autocompleteOverrides: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: 'white',
    },
  },
}));

export interface ProductSelectorProps {
  testIdKey: string;
  label?: string;
  items: EdapiProduct[];
  onChange: (items: EdapiProduct[]) => void;
  className?: string;
}

export const ProductSelector: React.FC<ProductSelectorProps> = ({
  testIdKey,
  label,
  items,
  onChange,
  className,
}) => {
  const {getProductByGtin, getProducts} = useEpim();
  const throttledCall = throttle(getProducts, 100);
  const classes = useStyles();

  const fetchOptions = async (inputValue: string): Promise<EdapiProduct[]> => {
    if (inputValue.length >= 3) {
      const result = (await throttledCall(inputValue)) || [];
      return result.filter(productDescription);
    } else {
      return [];
    }
  };

  const findItems = async (
    ...inputValues: string[]
  ): Promise<EdapiProduct[]> => {
    return await Promise.all(
      inputValues
        .map(input => input.trim())
        .filter(input => input)
        .map(async input => {
          if (isNaN(input as unknown as number)) {
            return {
              gtin: '00000000000000',
              names: {
                description: 'Artikel EAN Fehlerhaft',
                name: '',
                foodSpecification: '',
                longDescription: '',
                offer: {name: '', group: ''},
                shortDescription: '',
              },
              images: [],
              additionalGtin: [],
              volume: {volume: 0, volumePerUnit: ''},
            };
          }

          return (
            (await getProductByGtin(input)) || {
              gtin: input,
              names: {
                description: `Artikel EAN ${input}`,
                name: '',
                foodSpecification: '',
                longDescription: '',
                offer: {name: '', group: ''},
                shortDescription: '',
              },
              images: [],
              additionalGtin: [],
              volume: {volume: 0, volumePerUnit: ''},
            }
          );
        })
    );
  };

  const getOptionLabel = (p: EdapiProduct) =>
    `(${p?.gtin}) ${productDescription(p)}`;

  return (
    <Autocomplete
      label={label}
      inputPlaceholder="Artikel EAN eingeben"
      value={items}
      onChange={onChange}
      fetchOptions={fetchOptions}
      getOptionLabel={getOptionLabel}
      findItems={findItems}
      className={classNames(classes.autocompleteOverrides, className)}
      data-testid={`selectField-input-${testIdKey}`}
      inputTestId={`selectField-input-${testIdKey}-input`}
    />
  );
};
