import React, {ReactNode} from 'react';
import {FormRow} from '@edekadigital/backoffice-ui';
import {FormFieldDefinition, FormFieldTypes} from '../../forms/models';
import {CouponValidity} from '../coupon/CouponValidity';
import {FormContentGroup} from '../react-hook-form/FormContentGroup';
import {FormCategorySelector} from '../react-hook-form/formCategorySelector/FormCategorySelector';
import {FormProductSelector} from '../react-hook-form/formProductSelector/FormProductSelector';
import {FormImage} from '../react-hook-form/FormImage';
import {FormMarketSelector} from '../react-hook-form/FormMarketSelector';
import {FormTextField} from '../react-hook-form/FormTextField';
import {FormCheckbox} from '../react-hook-form/FormCheckbox';
import {FormSelectField} from '../react-hook-form/FormSelectField';
import {FormDatePicker} from '../react-hook-form/FormDatePicker';
import {FormNumberField} from '../react-hook-form/FormNumberField';
import {FormHiddenField} from '../react-hook-form/FormHiddenField';
import {FormContentGenerator} from '../react-hook-form/FormContentGenerator';
import {FormConditionsEditor} from '../react-hook-form/formConditionsEditor/FormConditionsEditor';
import {FormMetatags} from '../react-hook-form/FormMetatags';
import {FormSelectRegionalField} from '../react-hook-form/FormSelectRegionalField';

export const WrapFormRow = ({
  doNotWrap = false,
  hide = false,
  children,
}: {
  doNotWrap?: boolean;
  hide?: boolean;
  children: ReactNode;
}): JSX.Element =>
  doNotWrap ? (
    <div style={{width: '100%'}} hidden={hide}>
      {children}
    </div>
  ) : (
    <div style={{width: '100%'}} hidden={hide}>
      <FormRow gutterBottom={true}>{children}</FormRow>
    </div>
  );

export const renderFormField = (
  definition: FormFieldDefinition,
  key: number,
  doNotWrapInRows = false
): JSX.Element => {
  let result: JSX.Element | null;
  switch (definition.type) {
    case FormFieldTypes.Validity: {
      result = <CouponValidity definition={definition} key={key} />;
      break;
    }
    case FormFieldTypes.ContentGroup: {
      result = (
        <FormContentGroup definition={definition} key={`contentGroup-${key}`} />
      );
      break;
    }
    case FormFieldTypes.CategorySelector: {
      result = (
        <FormCategorySelector
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.ProductSelector: {
      result = (
        <FormProductSelector
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.FormImage: {
      result = (
        <FormImage
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.MarketSelector: {
      result = <FormMarketSelector doNotWrap={doNotWrapInRows} key={key} />;
      break;
    }
    case FormFieldTypes.TextField: {
      result = (
        <FormTextField
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.HiddenField: {
      result = (
        <FormHiddenField
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.NumberField: {
      result = (
        <FormNumberField
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.CheckBox: {
      result = (
        <FormCheckbox
          definition={definition}
          doNotWrap={doNotWrapInRows}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.Select: {
      result = (
        <FormSelectField
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.SelectRegional: {
      result = (
        <FormSelectRegionalField
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.DatePicker: {
      result = (
        <FormDatePicker
          doNotWrap={doNotWrapInRows}
          definition={definition}
          key={`${definition.key}-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.ContentGenerator: {
      result = (
        <FormContentGenerator
          definition={definition}
          key={`contentgenerator-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.ConditionsEditor: {
      result = (
        <FormConditionsEditor
          definition={definition}
          key={`conditionseditor-${key}`}
        />
      );
      break;
    }
    case FormFieldTypes.Metatags: {
      result = (
        <FormMetatags definition={definition} key={`conditionseditor-${key}`} />
      );
      break;
    }
    default: {
      result = (
        <WrapFormRow doNotWrap={doNotWrapInRows} key={`unknown-${key}`}>
          Do not know how to render field type: {definition.type}
        </WrapFormRow>
      );
    }
  }

  return result;
};
