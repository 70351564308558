import * as React from 'react';
import {
  Backup,
  CloudDownload,
  Dialog,
  IconButton,
  StatusChip,
} from '@edekadigital/backoffice-ui';
import {ProviderSnapshot, Status} from '../../model/model';
import moment from 'moment/moment';
import {ProviderSnapshots} from '../../hooks/useCatApp';
import {makeStyles} from '@material-ui/core';

export interface CouponSnapshotDialogProps {
  providerSnapshots?: ProviderSnapshots;
  onClose: () => void;
}

const dateFormat = 'DD.MM.YY HH:mm:ss';

const useTableStyles = makeStyles(() => ({
  table: {
    width: '100%',
  },
  th: {
    'text-align': 'start',
  },
}));

const statusToColor = (s: Status) =>
  (s === Status.SUCCESS && 'success') || 'error';

const prettyPayload = (s: string) => {
  try {
    return JSON.stringify(JSON.parse(s), null, 2);
  } catch (e) {
    return s;
  }
};

const showPayloadRow = (title: string, payload: string) => {
  return (
    <tr>
      <td>{title}</td>
      <td colSpan={4}>
        <pre>{prettyPayload(payload)}</pre>
      </td>
    </tr>
  );
};

const CouponSnapshotRow: React.FC<ProviderSnapshot> = s => {
  const [showRequest, setShowRequest] = React.useState<boolean>(false);
  const [showResponse, setShowResponse] = React.useState<boolean>(false);
  const hastRequest = s.requestPayload && s.requestPayload !== '';
  const hasResponse =
    s.responsePayload &&
    s.responsePayload !== '' &&
    s.responsePayload !== '""' &&
    s.responsePayload !== 'null';

  return (
    <>
      <tr>
        <td>{moment(s.creationTime).format(dateFormat)}</td>
        <td>{s.id}</td>
        <td>
          <StatusChip
            label={s.status}
            color={statusToColor(s.status)}
            data-testid={`chip-status-${s.id}`}
          />
        </td>
        <td>
          <IconButton
            icon={Backup}
            disabled={!hastRequest}
            onClick={() => setShowRequest(!showRequest)}
            data-testid={`button-request-${s.id}`}
          />
        </td>
        <td>
          <IconButton
            icon={CloudDownload}
            disabled={!hasResponse}
            onClick={() => setShowResponse(!showResponse)}
            data-testid={`button-response-${s.id}`}
          />
        </td>
      </tr>
      {showRequest && showPayloadRow('Request', s.requestPayload)}
      {showResponse && showPayloadRow('Response', s.responsePayload)}
    </>
  );
};

const SnapshotTable: React.FC<{snapshots: ProviderSnapshot[]}> = props => {
  const classes = useTableStyles();
  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes.th}>Erstellungsdatum</th>
          <th className={classes.th}>Snapshot ID</th>
          <th className={classes.th}>Status</th>
          <th className={classes.th}>Request</th>
          <th className={classes.th}>Response</th>
        </tr>
      </thead>
      <tbody>
        {props.snapshots.map(s => (
          <CouponSnapshotRow key={s.id} {...s} />
        ))}
      </tbody>
    </table>
  );
};

export const CouponSnapshotDialog: React.FC<CouponSnapshotDialogProps> =
  props => {
    const couponId = props.providerSnapshots?.couponId;
    const isOpen = couponId !== undefined;
    const hasError = props.providerSnapshots?.error;
    const snapshots = props.providerSnapshots?.snapshots || [];
    return (
      <Dialog
        open={isOpen}
        onClose={props.onClose}
        title={`Provider Snapshots für Coupon ${couponId}`}
        size="md"
      >
        {(hasError &&
          `Fehler beim abfragen des Snapshots für den Coupon ${couponId}`) ||
          (snapshots.length === 0 &&
            `Es existieren keine Snapshots für den Coupon ${couponId}`) ||
          (isOpen && SnapshotTable({snapshots}))}
      </Dialog>
    );
  };
