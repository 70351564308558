import * as React from 'react';
import {MainCouponCreate, Metatag} from '../../model/model';
import {useController, useFormContext} from 'react-hook-form';
import {MetatagsDef} from '../../forms/models';
import {WrapFormRow} from '../form/formRenderUtils';
import {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {
  Body,
  Spacer,
  TextField,
  ConnectedBoxesList,
} from '@edekadigital/backoffice-ui';

export interface FormMetatagsProps {
  definition: MetatagsDef;
}

const useStyles = makeStyles(theme => ({
  wrapperBoxSmall: {
    paddingTop: theme.spacing(1),
  },
  wrapperBoxBig: {
    paddingTop: theme.spacing(1),
    flexGrow: 1,
  },
}));

export const FormMetatags: React.FC<FormMetatagsProps> = ({
  definition: {useShow, key},
}) => {
  const {control, watch} = useFormContext<MainCouponCreate>();
  const {
    field: {onChange, value},
    formState: {errors},
  } = useController({control, name: key});
  const show = useShow(watch);
  const classes = useStyles();

  const metatags = value as Metatag[];

  const metatagError =
    errors.metaTags?.find(metaTag => metaTag !== undefined) ?? null;

  const onMetatagChanged = useCallback(
    (newMetatag: Metatag, index: number) => {
      const newMetatags = [...metatags];
      newMetatags[index] = newMetatag;
      onChange([...newMetatags]);
    },
    [metatags, onChange]
  );

  const metatagRows = useMemo(() => {
    if (!metatags) {
      return [];
    }

    return metatags.map((metatag, index) => (
      <React.Fragment key={`metatag-${index}`}>
        <div className={classes.wrapperBoxSmall}>
          <TextField
            label="Bezeichnung"
            value={metatag.name}
            onChange={evt => {
              console.log('evt.target.value');
              onMetatagChanged({...metatag, name: evt.target.value}, index);
            }}
            inputTestId={`metatag-${index}-name`}
          />
        </div>
        <Spacer horizontal={2} />
        <div className={classes.wrapperBoxBig}>
          <TextField
            label="Wert"
            value={metatag.description}
            onChange={evt =>
              onMetatagChanged(
                {...metatag, description: evt.target.value},
                index
              )
            }
            inputTestId={`metatag-${index}-description`}
          />
        </div>
      </React.Fragment>
    ));
  }, [
    classes.wrapperBoxSmall,
    classes.wrapperBoxBig,
    metatags,
    onMetatagChanged,
  ]);

  return (
    <WrapFormRow hide={!show} doNotWrap={true}>
      <ConnectedBoxesList
        boxesContents={metatagRows}
        addButtonLabel="Metatag hinzufügen"
        connectionLabel=""
        connectBoxes={false}
        onAdd={() => {
          const newMetatags = [...metatags];
          newMetatags.push({
            name: '',
            description: '',
            visible: true,
          });
          onChange([...newMetatags]);
        }}
        onRemove={index => {
          const newMetatags = [...metatags];
          newMetatags.splice(index, 1);
          onChange([...newMetatags]);
        }}
        testId="formmetatags"
      />
      {metatagError !== null ? (
        <span data-testid="formmetatags-error">
          <Spacer vertical={2} />
          <Body color="error" variant="body2">
            {metatagError[Object.keys(metatagError)[0] as keyof Metatag]
              ?.message ?? ''}
          </Body>
        </span>
      ) : null}
    </WrapFormRow>
  );
};
