import {graphql, useStaticQuery} from 'gatsby';

const ddClientToken = 'pub05034f75b386c1980b92208c0d91e1c6';
const ddApplicationId = '79178b03-1719-4fc5-8740-320a1a03e0e5';

export interface datadogConfig {
  isActive: boolean;
  sampleRate: number;
  clientToken: string;
  applicationId: string;
}

export interface useStageConfig {
  cougarApiUrl: string;
  epimApiUrl: string;
  marketApiUrl: string;
  datadogRum: datadogConfig;
}

const stageConfigs = {
  development: {
    cougarApiUrl: 'https://api.cat.dev.cougar.rosa.farb.regal.digital',
    epimApiUrl: 'https://epim-import.test.api.edeka',
    marketApiUrl: 'https://b2c-gw.test.api.edeka',
    datadogRum: {
      isActive: false,
      sampleRate: 100,
      clientToken: ddClientToken,
      applicationId: ddApplicationId,
    },
  } as useStageConfig,
  dev: {
    cougarApiUrl: 'https://api.cat.dev.cougar.rosa.farb.regal.digital',
    epimApiUrl: 'https://epim-import.test.api.edeka',
    marketApiUrl: 'https://b2c-gw.test.api.edeka',
    datadogRum: {
      isActive: true,
      sampleRate: 100,
      clientToken: ddClientToken,
      applicationId: ddApplicationId,
    },
  } as useStageConfig,
  test: {
    cougarApiUrl: 'https://api.cat.test.cougar.rosa.farb.regal.digital',
    epimApiUrl: 'https://epim-import.test.api.edeka',
    marketApiUrl: 'https://b2c-gw.test.api.edeka',
    datadogRum: {
      isActive: false,
      sampleRate: 100,
      clientToken: ddClientToken,
      applicationId: ddApplicationId,
    },
  } as useStageConfig,
  prod: {
    cougarApiUrl: 'https://api.cat.prod.cougar.rosa.farb.regal.digital',
    epimApiUrl: 'https://epim-import.api.edeka',
    marketApiUrl: 'https://b2c-gw.api.edeka',
    datadogRum: {
      isActive: false,
      sampleRate: 100,
      clientToken: ddClientToken,
      applicationId: ddApplicationId,
    },
  } as useStageConfig,
};

export const useStageConfig = () => {
  const stage = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            stage
          }
        }
      }
    `
  ).site.siteMetadata.stage as 'development' | 'dev' | 'test' | 'prod';
  const config = stageConfigs[stage] || stageConfigs.development;

  return {config, stage};
};
