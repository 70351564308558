import * as React from 'react';
import {Spacer} from '@edekadigital/backoffice-ui';
import {EdapiProduct} from '../../../model/model';
import {ProductSelector} from '../formProductSelector/ProductSelector';
import {makeStyles} from '@material-ui/core';
import {IntegerEuroField} from './IntegerEuroField';

export type GtinEditorAction = {
  type: 'gtin';
  products: EdapiProduct[];
  minAmount: number;
};

const useStyles = makeStyles(theme => ({
  gtinSelect: {
    marginTop: theme.spacing(1),
  },
  minAmountInput: {
    width: 160,
    marginTop: theme.spacing(1),
  },
  textWithMargin: {
    marginTop: theme.spacing(1),
  },
  minAmountHelp: {
    marginTop: theme.spacing(1),
  },
}));

export interface GtinEditorProps {
  products: EdapiProduct[];
  minAmount: number;
  dispatch: (action: GtinEditorAction) => void;
}

export const GtinEditor = React.memo(function GPCEditor({
  products,
  minAmount,
  dispatch,
}: GtinEditorProps) {
  const classes = useStyles();

  return (
    <>
      enthalten diese EAN
      <Spacer horizontal={1} vertical={2} />
      <ProductSelector
        testIdKey="gtin-editor"
        items={products}
        onChange={products => dispatch({type: 'gtin', products, minAmount})}
        className={classes.gtinSelect}
      />
      <div className={classes.textWithMargin}>mit Mindestumsatz</div>
      <Spacer horizontal={1} vertical={2} />
      <IntegerEuroField
        onChange={value => {
          dispatch({
            type: 'gtin',
            products,
            minAmount: value,
          });
        }}
        value={minAmount}
        className={classes.minAmountInput}
        testId="gtin-editor-amount-input"
      />
      <Spacer horizontal={1} vertical={2} />
      <div className={classes.minAmountHelp}>(0,01 € ist Pflichtminimum)</div>
    </>
  );
});
