import React, {useCallback, useRef} from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {CouponProvider, MainCouponCreate} from '../../model/model';
import {FormFieldArea} from '../../forms/models';
import {
  Button,
  FormWrapper,
  Paper,
  Spacer,
  TitleBar,
  useSnackbar,
} from '@edekadigital/backoffice-ui';
import {joiResolver} from '@hookform/resolvers/joi';
import {schemaBuilder} from '../../forms/schemaBuilder';
import {renderFormField} from './formRenderUtils';
import {FormButtonArea} from './FormButtonArea';
import {CouponPreview} from '../coupon/CouponPreview';
import tracer from '../../utils/tracing';
import {useCatApp} from '../../hooks/useCatApp';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {submitCoupon} from './submitCoupon';
import {Grid} from '@material-ui/core';
import * as formRendererStyles from './FormRenderer.module.css';

export interface FormRendererProps {
  defaultValues: MainCouponCreate;
  definition: FormFieldArea[];
}

export const FormRenderer: React.FC<FormRendererProps> = props => {
  const snackbar = useSnackbar();
  const validationSchema = schemaBuilder(props.definition);
  const methods = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: props.defaultValues,
  });

  const span = useRef(tracer.startSpan('createMainCoupon'));

  // API STUFF
  const {createMainCoupon} = useCatApp({
    span: span.current,
  });

  const handleFormSubmitError = useCallback(
    (errors: FieldErrors<MainCouponCreate>) => {
      console.log('FieldErrors:', errors);
      snackbar.push(
        {
          title: 'Fehler beim senden der Daten',
          message: `Fehler ${Object.keys(errors).join(
            ', '
          )} - Bitte prüfen Sie alle Pflichteingaben!`,
        },
        {variant: 'error'}
      );
    },
    [snackbar]
  );

  const submitFormCallback = useCallback(
    async (data: MainCouponCreate) => {
      const result = await submitCoupon(
        data,
        createMainCoupon,
        span.current.context().traceId
      );
      if (result.success) {
        snackbar.push(
          {
            title: 'Coupon wurde erfolgreich angelegt',
            message: result.messages[0],
          },
          {variant: 'info', autoHideDuration: 6000}
        );
      } else {
        let autoHideDuration = 0;
        for (const message of result.messages) {
          autoHideDuration += 6000;
          snackbar.push(
            {
              title: 'Couponanlage fehlgeschlagen',
              message: message,
            },
            {variant: 'error', autoHideDuration: autoHideDuration}
          );
        }
      }
    },
    [createMainCoupon, snackbar, span]
  );

  const onSubmit = methods.handleSubmit(
    submitFormCallback,
    handleFormSubmitError
  );

  return (
    <FormProvider {...methods}>
      <FormTitleBar onSubmit={onSubmit} />
      <FormBody onSubmit={onSubmit} definition={props.definition} />
    </FormProvider>
  );
};

interface FormBodyProps {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  definition: FormFieldArea[];
}

const FormBody: React.FC<FormBodyProps> = React.memo(function FormBody({
  onSubmit,
  definition,
}: FormBodyProps) {
  return (
    <>
      <Spacer vertical={2} />
      <Grid container spacing={3} data-testid="gridRow" alignItems="flex-start">
        <Grid
          item
          xs={12}
          md={8}
          data-testid={'gridRow-item-0'}
          classes={{root: formRendererStyles.marginBottom}}
        >
          <FormWrapper
            alignButtons={'right'}
            submitLabel="Publizieren"
            onSubmit={onSubmit}
          >
            {definition.map(renderArea)}
          </FormWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          data-testid={'gridRow-item-1'}
          classes={{root: formRendererStyles.sticky}}
        >
          <CouponPreview />
        </Grid>
      </Grid>
    </>
  );
});

interface FormTitleBarProps {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

const FormTitleBar = ({onSubmit}: FormTitleBarProps) => {
  const {watch} = useFormContext<MainCouponCreate>();
  const couponId = watch('id');
  const couponTitle = watch('shortTitle');
  const providers = watch('providers');
  const region = watch('region');
  const couponProvider =
    providers.length > 0 ? providers[0] : CouponProvider.MCA;

  return (
    <TitleBar
      actions={
        <Button color="primary" onClick={onSubmit}>
          Publizieren
        </Button>
      }
      additionalContent={
        <span>
          Coupon Provider: {couponProvider} | Region: {region}
        </span>
      }
    >
      {couponId === undefined ? 'Neuer Coupon:' : ''} {couponTitle}
    </TitleBar>
  );
};

const renderArea = (area: FormFieldArea, index: number): JSX.Element => {
  switch (area.display) {
    case 'closable': {
      return (
        <React.Fragment key={`area-${index}`}>
          <FormButtonArea area={area} dataTestId={area.dataTestId} />
          <Spacer vertical={5} />
        </React.Fragment>
      );
    }
    default: {
      return (
        <React.Fragment key={`area-${index}`}>
          <Paper headline={area.title}>
            {area.fields.map((e, i) => renderFormField(e, i, false))}
          </Paper>
          <Spacer vertical={5} />
        </React.Fragment>
      );
    }
  }
};
