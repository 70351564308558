import {SelectFieldOption, SelectRegionalFieldOption} from '../forms/models';
import {Condition} from '../components/react-hook-form/formConditionsEditor/ConditionEditor';

export enum CouponTemplate {
  ARTIKEL = 'ARTIKEL',
  WARENGRUPPE = 'WARENGRUPPE',
}

export enum CouponProvider {
  ACARDO = 'ACARDO',
  DBM = 'DBM',
  DC = 'DC',
  MCA = 'MCA',
}

export enum DiscountUnit {
  CURRENCY = '€',
  PERCENT = '%',
}

export const couponTemplateItems: SelectFieldOption[] = [
  {value: CouponTemplate.ARTIKEL, label: 'Artikel'},
  {value: CouponTemplate.WARENGRUPPE, label: 'Warengruppe'},
];

export interface MainCouponCreate {
  id?: string;
  value: number;
  couponId: string;
  discountType: string;
  briefInfo: string;
  description: string;
  shortTitle: string;
  specialOfferHeadline: string;
  image: string;
  includedProducts?: Array<EdapiProduct>;
  hiddenIncludedProducts?: Array<EdapiProduct>;
  includedItemGroups?: ProductCategory[];
  stores: string[];
  alwaysConsumable: boolean;
  appActivationRequired: boolean;
  meansOfPayment: boolean;
  maxReceiptUsagesCount: number;
  maxUserInstancesCount: number;
  maxCouponCount: number;
  minProductValue: number;
  maxProductValue: number;
  minProductAmount: number;
  maxProductAmount: number;
  minReceiptValue: number;
  maxReceiptValue: number;
  posProcessingResolution: number;
  posProcessingSequence: number;
  retailerSupplier: string;
  interval: number;
  loyaltyProviderName: string;
  targetGroup: string;
  processingType: string;
  couponGroup: string;
  processUsage: string;
  userAssigment: string;
  administrativeInstance: string;
  costCenter: string;
  supplier: string;
  metaInformation: string;
  couponTemplate: CouponTemplate;
  providers: CouponProvider[];
  validFrom: string;
  validTill: string;
  showFrom: string;
  showTill: string;
  validity: string;
  metaTags: Metatag[];

  // new for Acardo
  disclaimer: string;
  additionalText: string;
  validAfterPrintForDays: number;
  bill: string;
  priority: boolean;
  outputConditions: Condition[];
  redeemConditions: Condition[];

  region?: string;
}

export type Address = {
  city: string;
  street: string;
  zipCode: string;
};

export enum Region {
  EZ = 'EZ',
  MH = 'MH',
  NB = 'NB',
  NO = 'NO',
  RR = 'RR',
  SB = 'SB',
  SW = 'SW',
}

export type Store = {
  id: string;
  gln: string;
  name: string;
  region: string;
  address: Address;
  customerNumber: string;
  distributionChannel: string;
  merchant: string;
  services: string[];
};

export type StoreListResponse = {
  size: number;
  totalElements: number;
  number: number;
  content: Store[];
};

export type StoreVisualItem = Pick<
  Store,
  | 'id'
  | 'gln'
  | 'region'
  | 'merchant'
  | 'name'
  | 'distributionChannel'
  | 'services'
> &
  Pick<Address, 'zipCode'> & {
    isSelected: boolean;
    selectedStores: 'Selektierte' | 'Nicht Selektierte';
  };

export enum Service {
  GENUSS_PLUS = 'GENUSS_PLUS',
  COC_ACARDO = 'COC_ACARDO',
}

export type EdapiImage = {
  classic: string;
  documentIdentifier: string;
  itemId: string;
  meName: string;
  mediatype: string;
  mimeSourceHttp: string;
  quality: string;
  size: string;
  type: string;
  url: string;
};

export type EdapiNames = {
  description: string;
  foodSpecification: string;
  longDescription: string;
  name: string;
  shortDescription: string;
  offer: {
    name: string;
    group: string;
  };
};

export type EdapiVolume = {
  volume: number;
  volumePerUnit: string;
};

export type EdapiProduct = {
  additionalGtin: string[];
  gtin: string;
  images: EdapiImage[];
  names: EdapiNames;
  volume: EdapiVolume;
};

export const processUsageItems: SelectFieldOption[] = [
  {
    value: 'POS_REQUEST',
    label: 'During loyalty transaction initiation',
  },
  {
    value: 'EXTERNAL_SERVICE_REQUEST',
    label: 'By external service request',
  },
  {value: 'LIST_VISIT', label: 'By visiting the APP overview page'},
  {
    value: 'DIRECT_INSTANCE_CREATION_ON_EXTERNAL_REQUEST',
    label: 'Instance creation by external request',
  },
];

export type ProductsResourceResponse = {
  products: EdapiProduct[];
  currentSize: number;
  currentPage: number;
  hasNext: boolean;
};

export type ProductCategory = {
  identifier: string;
  name: string;
  sort?: number;
  level?: number;
};

export const targetGroupItems: SelectFieldOption[] = [
  {
    value: 'Neu_Genuss+_alle Status_regionsübergreifend',
    label: 'alle Kunden',
  },
  {
    value: 'Neu_Genuss+_Gold_regionsübergreifend',
    label: 'Gold',
  },
  {
    value: 'Neu_Genuss+_Silber_regionsübergreifend',
    label: 'Silber',
  },
  {
    value: 'Neu_Genuss+_Bronze_regionsübergreifend',
    label: 'Bronze',
  },
  {
    value: 'Neu_Genuss+_Silber+Gold_regionsübergreifend',
    label: 'Silber & Gold',
  },
  {
    value: 'Neu_Genuss+-Bronze+Silber_regionsübergreifend',
    label: 'Bronze & Silber',
  },
];

export const userAssignmentItems: SelectFieldOption[] = [
  {value: 'ALL_BP_CUSTOMERS', label: 'Standard Coupon'},
  {value: 'POS_CHECKOUT', label: 'Checkout-Coupon'},
  {value: 'QR_CODE_TRIGGER', label: 'QR-Code-Coupon'},
  {
    value: 'ACTIVATION_CODE_GENERIC',
    label: 'Static marketing code',
  },
  {
    value: 'ACTIVATION_CODE_SERIALIZED',
    label: 'Generated marketing codes',
  },
];

export const administrativeInstanceItems: SelectRegionalFieldOption[] = [
  {
    region: Region.MH,
    value: 'EDEKA Minden-Hannover',
    label:
      'Instance: EDEKA Minden-Hannover – Level: EDEKA Region – Retailer: EDEKA',
  },
  {
    region: Region.NB,
    value: 'EDEKA Nordbayern',
    label: 'Instance: EDEKA Nordbayern – Level: EDEKA Region – Retailer: EDEKA',
  },
  {
    region: Region.NO,
    value: 'EDEKA Nord',
    label: 'Instance: EDEKA Nord – Level: EDEKA Region – Retailer: EDEKA',
  },
  {
    region: Region.RR,
    value: 'EDEKA Rhein-Ruhr',
    label: 'Instance: EDEKA Rhein-Ruhr – Level: EDEKA Region – Retailer: EDEKA',
  },
  {
    region: Region.SB,
    value: 'EDEKA Südbayern',
    label: 'Instance: EDEKA Südbayern – Level: EDEKA Region – Retailer: EDEKA',
  },
  {
    region: Region.SW,
    value: 'EDEKA Südwest',
    label: 'Instance: EDEKA Südwest – Level: EDEKA Region – Retailer: EDEKA',
  },
];

export const costCenterItems: SelectRegionalFieldOption[] = [
  {
    region: Region.EZ,
    value: 'Kostenstelle 1',
    label:
      'Kostenstellen: EDEKA Zentrale Kostenstelle 1 (Admin.Instance: EDEKA Zentrale / Admin.Level: EDEKA Zentrale)',
  },
  {
    region: Region.EZ,
    value: 'Kostenstelle 2',
    label:
      'Kostenstellen: EDEKA Zentrale Kostenstelle 2 (Admin.Instance: EDEKA Zentrale / Admin.Level: EDEKA Zentrale)',
  },
  {
    region: Region.MH,
    value: 'EDEKA Minden-Hannover Kostenstelle',
    label:
      'Kostenstellen: EDEKA Minden-Hannover Standard-Kostenstelle (Admin.Instance: EDEKA Minden-Hannover / Admin.Level: EDEKA Region)',
  },
  {
    region: Region.NB,
    value: 'EDEKA NBST Kostenstelle',
    label:
      'Kostenstellen: EDEKA Nordbayern Standard-Kostenstelle (Admin.Instance: EDEKA Nordbayern / Admin.Level: EDEKA Region)',
  },
  {
    region: Region.NO,
    value: 'EDEKA Nord Kostenstelle',
    label:
      'Kostenstellen: EDEKA Nord Standard-Kostenstelle (Admin.Instance: EDEKA Nord / Admin.Level: EDEKA Region)',
  },
  {
    region: Region.RR,
    value: 'EDEKA Rhein-Ruhr Kostenstelle',
    label:
      'Kostenstellen: EDEKA Rhein-Ruhr Standard-Kostenstelle (Admin.Instance: EDEKA Rhein-Ruhr / Admin.Level: EDEKA Region)',
  },
  {
    region: Region.SB,
    value: 'EDEKA Südbayern Kostenstelle',
    label:
      'Kostenstellen: EDEKA Südbayern Standard-Kostenstelle (Admin.Instance: EDEKA Südbayern / Admin.Level: EDEKA Region)',
  },
  {
    region: Region.SW,
    value: 'EDEKA Südwest Kostenstelle',
    label:
      'Kostenstellen: EDEKA Südwest Standard-Kostenstelle (Admin.Instance: EDEKA Südwest / Admin.Level: EDEKA Region)',
  },
];

export interface Metatag {
  name: string;
  description: string;
  visible: boolean;
}

export enum Status {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface ProviderSnapshot {
  id: string;
  provider: CouponProvider;
  couponId: string;
  providerCouponId?: string;
  requestPayload: string;
  responsePayload: string;
  status: Status;
  statusCode: number;
  creationTime: string;
  providerStep: string;
  targetSystem?: string;
  error?: string;
}
