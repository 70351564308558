import * as React from 'react';
import {SelectField, SelectFieldProps} from '@edekadigital/backoffice-ui';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import {MainCouponCreate} from '../../model/model';
import {SelectDef} from '../../forms/models';
import {WrapFormRow} from '../form/formRenderUtils';

export interface FormSelectFieldProps extends Partial<SelectFieldProps> {
  definition: SelectDef;
  doNotWrap?: boolean;
}

export const FormSelectField = ({
  definition,
  doNotWrap = false,
  ...otherProps
}: FormSelectFieldProps) => {
  const methods = useFormContext<MainCouponCreate>();
  const show = definition.useShow(methods.watch);

  return (
    <WrapFormRow hide={!show} doNotWrap={doNotWrap}>
      <Controller
        control={methods.control}
        name={definition.key as keyof MainCouponCreate}
        render={({field: {onChange, value, name, ref}, formState}) => (
          <SelectField
            inputTestId={`selectField-input-${definition.key}`}
            label={definition.label}
            helperText={
              (formState.errors[definition.key] as FieldError | undefined)
                ?.message
            }
            error={!!formState.errors[name]}
            menuItems={definition.values}
            onChange={e => {
              onChange(e);
              if (definition.onChange) {
                definition.onChange(e.target.value, methods);
              }
            }}
            value={value as string}
            name={name}
            inputRef={ref}
            {...otherProps}
          />
        )}
      />
    </WrapFormRow>
  );
};
