import * as Joi from 'joi';

export const validatePositiveNumber = Joi.number()
  .positive()
  .required()
  .messages({
    'number.positive': 'nur Zahlen > 0',
    'number.base': 'Bitte geben Sie ein Wert > 0 ein',
  });
export const validateStringAllowEmpty = Joi.string().allow('');
export const validateRequiredString = Joi.string()
  .trim()
  .min(1)
  .required()
  .messages({
    'string.empty': 'Bitte geben Sie einen Wert ein',
  });
