import React, {useCallback, useState} from 'react';
import {FormFieldArea} from '../../forms/models';
import {renderFormField} from './formRenderUtils';
import {Heading, Paper, Spacer} from '@edekadigital/backoffice-ui';
import {Divider, IconButton, makeStyles} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

export interface FormButtonAreaProps {
  area: FormFieldArea;
  dataTestId?: string;
}

const useStyles = makeStyles(theme => ({
  dividerRoot: {
    margin: theme.spacing(2, -3),
  },
  headingRoot: {
    marginTop: theme.spacing(-1),
  },
  headingRootCondensed: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
  },
  headingButton: {
    position: 'absolute',
    top: theme.spacing(-1),
    right: theme.spacing(-2),
  },
}));

export function FormButtonArea({
  area,
  dataTestId,
}: FormButtonAreaProps): JSX.Element {
  const [showDetails, setShowDetails] = useState(
    area.display === 'closable' ? !!area.defaultIsOpen : false
  );

  const classes = useStyles();

  const handleClick = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);

  return (
    <Paper>
      <div
        className={
          showDetails ? classes.headingRoot : classes.headingRootCondensed
        }
      >
        <Heading variant={'h6'} data-testid="paper-headline">
          {area.title}
          <div className={classes.headingButton}>
            <IconButton
              onClick={handleClick}
              data-testid={dataTestId ?? 'formbutton-area-openclose'}
            >
              {showDetails ? (
                <ExpandLess style={{color: 'black'}} />
              ) : (
                <ExpandMore style={{color: 'black'}} />
              )}
            </IconButton>
          </div>
        </Heading>
      </div>
      {showDetails && <Divider classes={{root: classes.dividerRoot}} />}
      {showDetails && <Spacer vertical={3} />}
      <div
        data-testid="show-additional-settings-container"
        hidden={!showDetails}
      >
        {area.fields.map((e, i) => renderFormField(e, i, false))}
      </div>
    </Paper>
  );
}
