export const dateFormat = 'YYYY-MM-DD';

export const arraysEqual = (
  a: unknown[] | undefined,
  b: unknown[] | undefined
): boolean => {
  if (a === b) return true;
  if (a === undefined || b === undefined) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const trimWords = (text: string, maxLength: number): string => {
  while (text.length > maxLength) {
    text = text.substr(0, text.lastIndexOf(' '));
  }
  return text.replace(/[,;]$/, '');
};

export const commonStartingWords = (texts: Array<string>): string => {
  if (texts.length === 0) {
    throw new Error('texts must not be empty');
  }

  if (new Set(texts).size === 1) {
    return texts[0];
  }

  let space = texts[0].indexOf(' ');
  let commonStart = '';
  while (space >= 0) {
    const newCommonStart = texts[0].substr(0, space);
    if (
      texts.filter(t => t.startsWith(newCommonStart)).length !== texts.length
    ) {
      return commonStart;
    }
    commonStart = newCommonStart;
    space = texts[0].indexOf(' ', space + 1);
  }
  return commonStart;
};

export const prettyFloats = (f: number): string => {
  return f.toLocaleString('de-DE');
};

export const prettyCurrency = (f: number): string => {
  return f
    .toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})
    .replace(/[^0-9]*€*$/, ' €');
};

export const range = (start: number, end: number): Array<string> =>
  Array(end - start + 1)
    .fill(0)
    .map((_, idx) => (start + idx).toString());
