import * as React from 'react';
import {Router} from '@reach/router';
import {VersionInfo} from '../components/VersionInfo';
import {datadogRum} from '@datadog/browser-rum';
import {
  COPY_COUPON_PATH,
  COUPON_OVERVIEW_PATH,
  CREATE_COUPON_PATH,
  CREATE_PERSONALIZED_COUPON_PATH,
  Navigation,
  UPDATE_COUPON_PATH,
} from '../components/common/Navigation';
import CouponOverview from '../components/views/CouponOverview';
import {SelectCouponProvider} from '../components/views/SelectCouponProvider';
import {CreateCoupon} from '../components/views/CreateCoupon';
import {UpdateCoupon} from '../components/views/UpdateCoupon';
import {useStageConfig} from '../hooks/useStageConfig';

export default function App() {
  const stageConfig = useStageConfig();
  const ddConfig = stageConfig.config.datadogRum;

  if (ddConfig.isActive) {
    datadogRum.init({
      applicationId: ddConfig.applicationId,
      clientToken: ddConfig.clientToken,
      sampleRate: ddConfig.sampleRate,
      version: 'unknown',
      env: stageConfig.stage,
      trackInteractions: true,
      site: 'datadoghq.eu',
      service: 'cat-app',
    });
  }

  return (
    <>
      <Navigation>
        <Router>
          <CouponOverview path={COUPON_OVERVIEW_PATH} />
          <CreateCoupon path={`${CREATE_COUPON_PATH}/:couponProvider`} />
          <UpdateCoupon
            isCopyMode={false}
            path={`${UPDATE_COUPON_PATH}/:couponId`}
          />
          <UpdateCoupon
            isCopyMode={true}
            path={`${COPY_COUPON_PATH}/:couponId`}
          />
          <SelectCouponProvider path={CREATE_PERSONALIZED_COUPON_PATH} />
        </Router>
      </Navigation>
      <VersionInfo buildId="unknown" version="unknown" />
    </>
  );
}
