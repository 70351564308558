import {Button} from '@edekadigital/backoffice-ui';
import {useFormContext} from 'react-hook-form';
import * as React from 'react';
import {
  CouponProvider,
  CouponTemplate,
  MainCouponCreate,
} from '../../model/model';
import {getTexts, isValidProduct} from '../../utils/textGenerator';
import {isValidProductCategory} from '../../utils/productCategoryTexts';
import {
  Condition,
  TriggerType,
} from '../react-hook-form/formConditionsEditor/ConditionEditor';
import {range} from '../../utils/utils';
import {useCallback} from 'react';
import {GPCContainmentVariant} from '../react-hook-form/formConditionsEditor/GPCEditor';

const generateDisclaimer = (nonPeriodConditions: Condition[]) => {
  let disclaimerText = '';

  const gpcPrefixList: string[] = nonPeriodConditions
    .filter(it => it.triggerType === TriggerType.GPC)
    .reduce(
      (list, it) => [
        ...list,
        ...(it.productCategories ?? []).map(ct => ct.identifier.substr(0, 2)),
      ],
      [] as string[]
    )
    .filter(it => !!it) as string[];

  if (
    nonPeriodConditions.map(it => it.triggerType).includes(TriggerType.TOTALSUM)
  ) {
    disclaimerText =
      '*Ausgenommen sind Tabakwaren, Pfand, Telefonkarten, Guthabenkarten (u. a. iTunes, Amazon-Karten), Buch- und Presseerzeugnisse, Briefmarken, Druckerzeugnisse, Pre- und Anfangsmilchnahrung, Toto/Lotto, Fotodruckdienstleistungen sowie Tchibo-Artikel.';
  } else if (
    gpcPrefixList.some(it => ['47', '51'].concat(range(53, 76)).includes(it))
  ) {
    disclaimerText =
      '*Ausgenommen sind Briefmarken, Druckerzeugnisse, Telefonkarten, Guthabenkarten (u.a. iTunes, Amazon-Karten), Pfand, Tabakwaren, Tchibo-Artikel';
  } else if (
    gpcPrefixList.some(it =>
      ['11', '36', '37', '39', '40', '41', '42'].includes(it)
    )
  ) {
    disclaimerText = '*Pfand ausgenommen';
  }
  return disclaimerText;
};

export const CouponTextGenerator: React.FC<{label?: string}> = props => {
  const {getValues, setValue, watch} = useFormContext<MainCouponCreate>();
  const couponTemplate = watch('couponTemplate');
  const couponProvider = watch('providers')[0];
  const redeemConditions = watch('redeemConditions');

  const generateCouponTexts = useCallback(() => {
    const [
      includedProducts,
      hiddenIncludedProducts,
      includedItemGroups,
      discountType,
      value,
      maxReceiptUsagesCount,
      maxUserInstancesCount,
      shortTitle,
      description,
      briefInfo,
      specialOfferHeadline,
      appActivationRequired,
      redeemConditions,
      providers,
    ] = getValues([
      'includedProducts',
      'hiddenIncludedProducts',
      'includedItemGroups',
      'discountType',
      'value',
      'maxReceiptUsagesCount',
      'maxUserInstancesCount',
      'shortTitle',
      'description',
      'briefInfo',
      'specialOfferHeadline',
      'appActivationRequired',
      'redeemConditions',
      'providers',
    ]);
    const hiddenGtins = (hiddenIncludedProducts ?? []).map(p => p.gtin);
    const couponProvider = providers[0];

    const productsOrCategories =
      couponTemplate === CouponTemplate.ARTIKEL
        ? (includedProducts ?? []).filter(p => !hiddenGtins.includes(p.gtin))
        : includedItemGroups ?? [];

    const texts = getTexts(
      couponProvider,
      productsOrCategories,
      discountType,
      value || 0,
      maxReceiptUsagesCount,
      maxUserInstancesCount,
      appActivationRequired,
      redeemConditions
    );

    // A form does not necessarily have all the following fields.
    // Only set the values, when they are already known to react-hook-form
    // Otherwise the validation later on might fail because of
    // unspecified fields.
    if (shortTitle !== undefined) {
      setValue('shortTitle', texts.shortTitle);
    }
    if (description !== undefined) {
      setValue('description', texts.description);
    }
    if (briefInfo !== undefined) {
      setValue('briefInfo', texts.briefInfo);
    }
    if (specialOfferHeadline !== undefined) {
      setValue('specialOfferHeadline', texts.specialOfferHeadline);
    }

    if (texts.image) {
      let image = '';
      if (couponProvider === CouponProvider.MCA) {
        image = `${location.protocol}//${location.host}${texts.image}`;
      } else if (couponProvider === CouponProvider.ACARDO) {
        image = texts.image;
      }
      setValue('image', image);
    }
    if (redeemConditions && redeemConditions.length > 0) {
      setValue('disclaimer', generateDisclaimer(redeemConditions));
    }
  }, [couponTemplate, getValues, setValue]);

  let isEnabled = false;

  if (couponProvider === CouponProvider.ACARDO) {
    // Currently for Acardo textgeneration there are only use-cases for exactly
    // one redeem condition
    if (redeemConditions !== undefined && redeemConditions.length === 1) {
      isEnabled =
        redeemConditions[0].triggerType !== TriggerType.GTIN &&
        redeemConditions[0].triggerType !== TriggerType.PERIOD;

      // We currently only have text generation for gpc "enthält" conditions,
      // not "enthält nicht".
      isEnabled =
        redeemConditions[0].triggerType === TriggerType.GPC
          ? redeemConditions[0].gpcContainment ===
            GPCContainmentVariant.contains
          : isEnabled;
    }
  } else if (couponTemplate === CouponTemplate.ARTIKEL) {
    const products = watch('includedProducts') || [];
    const validProductsOnly =
      products.length > 0 &&
      products.filter(isValidProduct).length === products.length;
    if (products.length > 0 && !validProductsOnly) {
      console.error('unsupported products: ', products);
    }

    isEnabled = validProductsOnly;
  } else if (couponTemplate === CouponTemplate.WARENGRUPPE) {
    const products = watch('includedItemGroups') || [];
    console.log('includedItemGroups', products);
    const validProductsOnly =
      products.length > 0 &&
      products.filter(isValidProductCategory).length === products.length;
    if (products.length > 0 && !validProductsOnly) {
      console.error('unsupported products: ', products);
    }

    isEnabled = validProductsOnly;
  } else {
    return null;
  }

  return (
    <Button
      variant="outlined"
      color={'primary'}
      onClick={generateCouponTexts}
      disabled={!isEnabled}
    >
      {props.label ?? 'Coupon Texte generieren'}
    </Button>
  );
};
