import * as React from 'react';
import {SelectField, Spacer, TextField} from '@edekadigital/backoffice-ui';
import {makeStyles} from '@material-ui/core';

export enum Weekdays {
  MO = 'monday',
  TU = 'tuesday',
  WE = 'wednesday',
  TH = 'thursday',
  FR = 'friday',
  SA = 'saturday',
  SU = 'sunday',
}

const WEEKDAYS_LABEL_MAP = [
  {value: Weekdays.MO, label: 'Montag'},
  {value: Weekdays.TU, label: 'Dienstag'},
  {value: Weekdays.WE, label: 'Mittwoch'},
  {value: Weekdays.TH, label: 'Donnerstag'},
  {value: Weekdays.FR, label: 'Freitag'},
  {value: Weekdays.SA, label: 'Samstag'},
  {value: Weekdays.SU, label: 'Sonntag'},
];

export type PeriodEditorAction = {
  type: 'period';
  day: Weekdays;
  startTime: string; // HH:MM
  endTime: string;
};

const useStyles = makeStyles(() => ({
  weekdaySelect: {
    flexBasis: '140px',
    flexShrink: 0,
  },
  startInput: {
    flexBasis: '110px',
    flexShrink: 0,
  },
  endInput: {
    flexBasis: '110px',
    flexShrink: 0,
  },
}));

interface PeriodEditorProps {
  day: Weekdays;
  startTime: string; // HH:MM
  endTime: string; // HH:MM
  dispatch: (action: PeriodEditorAction) => void;
}

export const PeriodEditor = React.memo(function PeriodEditor({
  day,
  startTime,
  endTime,
  dispatch,
}: PeriodEditorProps) {
  const classes = useStyles();

  return (
    <>
      <SelectField
        value={day}
        menuItems={WEEKDAYS_LABEL_MAP}
        inputTestId="selectField-input-period-editor-day-select"
        onChange={evt =>
          dispatch({
            type: 'period',
            startTime: startTime,
            endTime: endTime,
            day: evt.target.value as Weekdays,
          })
        }
        className={classes.weekdaySelect}
      />
      <Spacer horizontal={1} vertical={2} />
      <div>von</div>
      <Spacer horizontal={1} vertical={2} />
      <TextField
        type="time"
        inputTestId="period-editor-starttime"
        value={startTime}
        className={classes.startInput}
        onChange={evt =>
          dispatch({
            type: 'period',
            startTime: evt.target.value as Weekdays,
            endTime: endTime,
            day: day,
          })
        }
      />
      <Spacer horizontal={1} vertical={2} />
      <div>bis</div>
      <Spacer horizontal={1} vertical={2} />
      <TextField
        type="time"
        value={endTime}
        inputTestId="period-editor-endtime"
        className={classes.endInput}
        onChange={evt =>
          dispatch({
            type: 'period',
            startTime: startTime,
            endTime: evt.target.value as Weekdays,
            day: day,
          })
        }
      />
    </>
  );
});
