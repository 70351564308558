import * as React from 'react';
import {Body, Paper} from '@edekadigital/backoffice-ui';
import DeutschlandCardImage from '../../assets/deutschlandcard.png';
import EdekaAppImage from '../../assets/edeka-app.png';
import {globalStyles} from '../../styles/global';
import {RouteComponentProps, Link} from '@reach/router';
import {CREATE_COUPON_PATH} from '../common/Navigation';
import {CouponProvider} from '../../model/model';

export const SelectCouponProvider: React.FC<RouteComponentProps> = () => {
  const classes = globalStyles();
  return (
    <>
      <h1>Für welchen Kanal wollen Sie einen Coupon anlegen?</h1>
      <Paper gutterBottom>
        <Body>
          <Link
            to={`${CREATE_COUPON_PATH}/${CouponProvider.DC}`}
            className={classes.couponProviderLink}
            aria-label="Coupons für Deutschlandcard"
          >
            <img
              className={classes.couponProviderImage}
              src={DeutschlandCardImage}
              alt="Deutschlandcard"
              width={100}
            />
            Coupons für Deutschlandcard
          </Link>
        </Body>
      </Paper>
      <Paper gutterBottom>
        <Body>
          <Link
            to={`${CREATE_COUPON_PATH}/${CouponProvider.ACARDO}`}
            className={classes.couponProviderLink}
            aria-label="create-coupon-edeka-app"
          >
            <img
              className={classes.couponProviderImage}
              src={EdekaAppImage}
              alt="EDEKA App"
              width={100}
            />
            Coupons für die EDEKA App
          </Link>
        </Body>
      </Paper>
    </>
  );
};
